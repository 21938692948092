'use strict';

var resultsObject = null;
var stateStores = [];


function init(results) {
	resultsObject = results;
	searchAddress();
}

/**
 * Prepares and calls resultObject to display all stores from provided state
 * @param {String} state
 */
function searchAddress(state) {
	resultsObject.resetLoadedStores();
	prepareStateStores(state);
	sortStateStores();
	resultsObject.displayStoreResults(stateStores);
}

/**
 * Sorts stateStores so we get ecco stores first
 */
function sortStateStores() {
	stateStores.sort(function (a, b) {
		var firstStoreName = a.name;
		var secondStoreName = b.name;

		if (firstStoreName.toLowerCase().indexOf('ecco') > -1) {
			return -1;
		}

		if (secondStoreName.toLowerCase().indexOf('ecco') > -1) {
			return 1;
		}

		return 0;
	});
}

/**
 * Populates stateStores with stores from provided state.
 * If no state is provided we use all stores
 * @param {String} state
 */
function prepareStateStores(state) {
	stateStores = [];

	$.each(storesJson, function (i, item) {
		if (!state || item.stateCode == state) {
			var store = {
				city: item.city,
				country: item.countryCode,
				storeID: item.storeID,
				name: item.name,
				address1: item.address1,
				address2: item.address2,
				data: item
			};

			stateStores.push(store);
		}
	});
}

/**
 * Returns all currently visible stores
 * @returns {Array}
 */
function getVisibleStores() {
	return stateStores;
}

module.exports = {
	init: init,
	searchAddress: searchAddress,
	getVisibleStores: getVisibleStores
};