'use strict';

var ajax = require('../ajax');
var progress = require('../progress');
var forms = require('../forms');
var flags = require('../flags');

exports.init = function(context) {
	//address auto complete (Our BE implementation)
	$('body').off('click', '.js-search-postal-code').on('click', '.js-search-postal-code', function(e) {
		flags.setFlag('preventShippingAddressUpdate', true);
		var parentWrapper = $(this).closest('fieldset');
		var $zipCodeField = parentWrapper.find('.js-address-postal-code');
		var accountPage = context.toLowerCase() === 'account';
		var url = accountPage ? Urls.yubinAddressComplete : Urls.checkoutSubmitForm;

		if ($zipCodeField.valid()) {
			var zipCode = $zipCodeField.val();
			var data = [{
				name: 'action',
				value: 'addressComplete'
			}, {
				name: 'zipCode',
				value: zipCode
			}, {
				name: 'responseType',
				value: 'json'
			}];

			progress.show();
			ajax.load({
				url: url,
				data: data,
				callback: function(response) {
					updateAddress(response, $zipCodeField);
					forms.checkInputFields();
					progress.hide();
				}
			});
			e.stopImmediatePropagation();
		}
	});
};

/**
 * Updates address with new data from responseData (used for address auto complete)
 * @param {String} responseData
 */
function updateAddress(responseData, zipCodeField) {
	flags.setFlag('preventShippingAddressUpdate', false);
	var parentWrapper = zipCodeField.closest('fieldset');
	if (responseData == 'undefined' || responseData == null || responseData == '') {
		//set error and return if we don't have a response
		parentWrapper.find('#' + zipCodeField.attr('id') + '-error').text(Resources.ZIPCODE_LOOKUP_DOWN);
		return;
	}

	var addressState = parentWrapper.find('.js-state');
	var addressCity = parentWrapper.find('.js-address-city');
	var addressArea = parentWrapper.find('.js-address-area');

	var formattedResult = JSON.parse(responseData);
	var addressData = formattedResult.resultObj;
	if (Object.keys(addressData).length == 0 || !addressData.success) {
		//set error and return if response isn't valid
		parentWrapper.find('#' + zipCodeField.attr('id') + '-error').remove();
		$('<span id="' + zipCodeField.attr('id') + '-error" class="error">' + Resources.ZIPCODE_LOOKUP_INVALID + '</span>').insertAfter(zipCodeField);
		zipCodeField.closest('.input-container').addClass('has-error').removeClass('is-valid');
		zipCodeField.addClass('error');

		fillField(addressState, '');
		fillField(addressCity, '');
		fillField(addressArea, '');

		return;
	}

	var isShippingAddress = parentWrapper.hasClass('js-shipping-form');
	var designatedDeliveryState = $('.js-designateddelivery-detail-container .js-state');
	if (isShippingAddress && designatedDeliveryState.length > 0) {
		//in case we are autocomleting shipping address check if designated delivery is active
		addressState = designatedDeliveryState;
	}

	var formatedPrefecture = '';
	if (addressData.prefectureid) {
		//we need to format prefecture correctly (01, 02, ... 10, 11, ...)
		formatedPrefecture = addressData.prefectureid < 10 ? '0' + addressData.prefectureid : addressData.prefectureid;
	}

	var isStateUpdated = fillField(addressState, formatedPrefecture);
	var isCityUpdated = fillField(addressCity, addressData.city);
	var isAreaUpdated = fillField(addressArea, addressData.area);

	if (isStateUpdated) {
		//only when we are updating state we are triggering change over state because we need to update designated delivery in that case
		triggerChange(addressState);
		return;
	}

	if (!isAreaUpdated && !isCityUpdated) {
		//if we didn't update anything there is no need for triggering change over any field
		return;
	}

	//if we aren't updating the state it is the same if we trigger change over city or area
	triggerChange(addressCity);
}

/**
 * Fills field with given data and returns true if field is updated otherwise returns false
 * @param {Object} field
 * @param {String} data
 * @returns {Boolean} true in case that field is filled
 */
function fillField (field, data) {
	if (field.length <= 0 || data == field.val()) {
		//we update field value only if field exists and field isn't already filled with given data
		return false;
	}

	field.val(data);
	
	if (!field.valid()) {
		field.closest('.input-container').removeClass('is-valid');
	}

	return true;
}

/**
 * Triggers the change event of a field
 * @param {String} field
 */
function triggerChange(field) {
	field.trigger('change');
	field.trigger('blur');
}