'use strict';

var trackingHelper = require('./trackingHelper');
var ometriaTracking = require('./ometriaTracking');

/**
 * Handles the subscribe or unsubscribe events on newsletter form
 * @param {*} event
 */
function handleForm(event) {
	var input = $(event.target).find('input[name$="addtoemaillist"]');
	var email = (User && 'userData' in User && 'visitorEmail' in User.userData) ? User.userData.visitorEmail : $(event.target).find('input[name$="email"]').val();

	if (input.is(':checked')) {
		subscribe(email);
	}
	else {
		unsubscribe(email);
	}
}

/**
 * Handles the subscribe on newsletter form in checkout page
 * @param {*} event 
 */
function handleCheckoutForm(event) {
    var input = $(event.target).find('input[name$="newsletterSignup"]');
    var email = $(event.target).find('input[name$="emailAddress"]').val();

    if (input.is(':checked')) {
        subscribe(email);
    }
}

/**
 * Handles the subscribe on newsletter form in checkout registration page
 * @param {*} event 
 */
function handleCheckoutRegistrationForm(event) {
    var input = $(event.target).find('input[name$="addtoemaillist"]');
    var email = (User && 'userData' in User && 'visitorEmail' in User.userData) ? User.userData.visitorEmail : $(event.target).find('input[name$="email"]').val();

    if (input.is(':checked')) {
        subscribe(email);
    }
}

/**
 * Pushes data to track subscribe
 * @param {*} email
 */
function subscribe(email) {
	var trackingObj = {
		event: 'GA Tracking',
		eventCategory: 'Newsletter Subscription',
		eventAction: 'opt-in',
		visitorEmail: (email.indexOf('@') < 0 ? email : window.md5(email))
	};
	trackingHelper.pushToDataLayer(trackingObj);
	//Ometria identify for newsletter email
	if (typeof ometria !== 'undefined' && User.userData.visitorStatus == 'Guest') {
		ometria.identify(email);
	}
}

/**
 * Pushes data to track unsubscribe
 * @param {*} email
 */
function unsubscribe(email) {
	var trackingObj = {
		event: 'GA Tracking',
		eventCategory: 'Newsletter Subscription',
		eventAction: 'opt-out',
		visitorEmail: (email.indexOf('@') < 0 ? email : window.md5(email))
	};
	trackingHelper.pushToDataLayer(trackingObj);
}

function initializeEvents() {
	// event bindings
	$(document).on('newsletterSubscribe', function(event, data) {
		subscribe(data);
	});

	$('#NewsletterForm').on('submit', handleForm);
	$('#RegistrationForm').on('submit', handleForm);

	$(document).on('newsletterSubscribeCheckout', function(event) {
		handleCheckoutForm(event);
	});

	$(document).on('newsletterSubscribeCheckoutRegistration', function(event) {
		handleCheckoutRegistrationForm(event);
	});
}

/**
 * Initialize all events for pagedesigner GTM tracking
 */
exports.init = function () {
	if (!('md5' in window && typeof window.md5 !== 'undefined')) {
		window.md5 = require('../utils/md5');
	}

	initializeEvents();
};