'use strict';

var util = require('./util'),
	dialog = require('./dialog'),
	regex = require('./constants').regex;

const EMAIL_MAX_LENGTH = 100;

// Input Container selectors
var containerSelectorClasses = '.input-container, .select-container';

// global form validator settings
var settings = {
	errorClass: 'error',
	errorElement: 'span',
	onkeyup: false,
	onfocusout: function(element, event) {
		var didUserClickedOnPopupLink = dialog.isMouseDownOnAnchor();

		if (didUserClickedOnPopupLink) {
			if (event.relatedTarget) {
				$(event.relatedTarget).trigger('click');
			}

			return;
		}

		if (!this.checkable(element)) {
			this.element(element);
		}
	},

	errorPlacement: function(error, element) {
		var $inputContainerEl = $(element).closest(containerSelectorClasses);
		var $captionBlock = $inputContainerEl.find('.js-info-row');
		var hasCaptionBlock = $captionBlock.length > 0;
		var $inputGroup = $inputContainerEl.find('.input-group');

		// always add .has-error class to container element
		$inputContainerEl.addClass('has-error');

		error.attr('aria-live', 'polite');
		error.attr('aria-relevant', 'additions');

		// if there is a caption block,
		// insert the error as first child to inline with caption
		// if input is inside input group,
		// insert error after the group
		// else insert after input field
		if (hasCaptionBlock) {
			error.prependTo($captionBlock);
		}
		else if ($inputGroup.length > 0) {
			error.insertAfter($inputGroup);
		}
		else {
			error.insertAfter(element);
		}
	},

	success: function(element) {
		var $element = $(element);

		$element.closest(containerSelectorClasses).removeClass('has-error');
		$element.closest('.error').remove();
	},

	rules: {
		dwfrm_login_username: {
			maxlength: EMAIL_MAX_LENGTH
		},
		dwfrm_profile_customer_email: {
			maxlength: EMAIL_MAX_LENGTH
		},
		dwfrm_profile_customer_emailconfirm: {
			maxlength: EMAIL_MAX_LENGTH
		},
		dwfrm_checkout_billing_email_emailAddress: {
			maxlength: EMAIL_MAX_LENGTH
		},
		dwfrm_profile_customer_dateofbirth: {
			birthdayValidate: {
				dateOfBirth: 'input[name*=_profile_customer_dateofbirth]'
			}
		},
		dwfrm_newsletter_dateofbirth: {
			birthdayValidate: {
				dateOfBirth: 'input[name*=_newsletter_dateofbirth]'
			}
		},
		dwfrm_profile_password_newpassword: {
			passwordValidate: {
				password: 'input[name*=_profile_password_newpassword]'
			}
		},
		dwfrm_checkout_payment_creditCard_number: {
			CCNumber: {
				cardType: 'select[name$=_creditCard_type]'
			}
		},
		dwfrm_checkout_payment_creditCard_cvn: {
			CCCvn: {
				cardType: 'select[name$=_creditCard_type]'
			}
		},
		dwfrm_checkout_payment_creditCard_expiration_monthAndYear: {
			CCExp: {
				monthAndYear: 'input[name*=_creditCard_expiration_monthAndYear]'
			}
		},
		dwfrm_paymentinstruments_creditcards_newcreditcard_expiration_monthAndYear: {
			CCExpAccount: {
				monthAndYear: 'input[name*=_newcreditcard_expiration_monthAndYear]'
			}
		},
		dwfrm_checkout_billing_email_acceptprivacypolicy: {
			required: '#dwfrm_checkout_billing_email_newsletterSignup:checked'
		},
		holderName: {
			required: false
		},
		dwfrm_profile_customer_phoneprefix: {
			phoneprefix: {
				validcodes: '.js-valid-phone-prefixes'
			}
		},
		dwfrm_checkout_shipping_address_phoneprefix: {
			phoneprefix: {
				validcodes: '.js-valid-phone-prefixes'
			}
		},
		dwfrm_checkout_designateddelivery_designatedDeliveryDate: {
			designatedDelivery: {}
		},
		dwfrm_checkout_designateddelivery_designatedDeliveryTime: {
			designatedDelivery: {}
		}
	},
	messages: {
		dwfrm_checkout_acceptprivacypolicy: {
			required: Resources.ACCEPT_PRIVACY_AND_TERMS
		},
		// override maxlimit message to notify users that limit changed
		dwfrm_profile_customer_email: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_profile_customer_emailconfirm: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_checkout_billing_email_emailAddress: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_profile_customer_firstname: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_profile_customer_lastname: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_profile_customer_zipcode: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_profile_address_firstname: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_profile_address_lastname: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_profile_address_address1: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_profile_address_address2: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_profile_address_city: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_checkout_shipping_address_firstName: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_checkout_shipping_address_lastName: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_checkout_shipping_address_address1: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_checkout_shipping_address_address2: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_checkout_shipping_address_city: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_checkout_billing_address_firstName: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_checkout_billing_address_lastName: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_checkout_billing_address_address1: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_checkout_billing_address_address2: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_checkout_billing_address_city: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_profile_customer_phoneticFirstName: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_profile_customer_phoneticLastName: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_profile_address_phoneticfirstname: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_profile_address_phoneticlastname: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_checkout_shipping_address_phoneticFirstName: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		},
		dwfrm_checkout_shipping_address_phoneticLastName: {
			maxlength: Resources.VALIDATE_CHARACTER_LIMIT
		}
	}
};

/**
 * We set the VPA input field as NOT required in the form definition but it should be mandatory when place order button is clicked.
 * The reason why we set it as NOT required in the for definition is because it is being posted to server with any AJAX call even if it doesn'T have a value.
 * Then its value becomes empty string and that means it is invalid, so the inputfield.js thinks it is empty and assigns 'error' class.
 * So we validate it customly here.
 */
var validateUpiVpa = function(value, el) {
	if (value.trim() === '') {
		return false;
	}

	return true;
}

/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validatePhone = function(value, el) {
	var countryRegex;
	if (el.hasAttribute('regex')) {
		countryRegex = new RegExp(el.getAttribute('regex'), 'i');
	}
	else {
		var countryField = $('.js-country-for-phone');
		if (countryField.length === 0 || countryField.val().length === 0 || !regex.phone[countryField.val().toUpperCase()]) {
			return true;
		}
		countryRegex = regex.phone[countryField.val().toUpperCase()];
	}

	var isOptional = this.optional(el);
	var isValid = countryRegex.test($.trim(value));

	return isOptional || isValid;
};

var validatePhonePrefix = function(value, el, params) {
	var validPhonePrefixValues = $(params.validcodes).val();
	var phonePrefix = $('.input-text.phoneprefix').val();

	if (validPhonePrefixValues === undefined || validPhonePrefixValues === '') {
		return true;
	}

	var phoneInput = $('.input-text.phone');

	if (phonePrefix === '91') {
		phoneInput.attr('minlength', '10');
	}
	else {
		phoneInput.attr('minlength', '6');
	}

	var phonePrefixes = JSON.parse(validPhonePrefixValues);

	return phonePrefixes.indexOf(parseInt(value)) > -1;
};

var validatePostal = function(value, el) {
	var countryRegex;
	if (el.hasAttribute('regex')) {
		countryRegex = new RegExp(el.getAttribute('regex'), 'i');
	}
	else {
		var countryField = $(el)
			.closest('fieldset')
			.find('.country');
		if (countryField.length === 0 || countryField.val().length === 0 || !regex.postal[countryField.val().toUpperCase()]) {
			return true;
		}
		countryRegex = regex.phone[countryField.val().toUpperCase()];
	}

	var isOptional = this.optional(el);
	var isValid = countryRegex.test($.trim(value));

	return isOptional || isValid;
};

var validateRegex = function(value, el) {
	var elementRegex;
	if (el.hasAttribute('regex')) {
		elementRegex = new RegExp(el.getAttribute('regex'), 'i');

		var isOptional = this.optional(el);
		var isValid = elementRegex.test($.trim(value));

		return isOptional || isValid;
	}
	return true;
};

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function(value) {
	var isValid = regex.notCC.test($.trim(value));
	return isValid;
};

var validateExpiration = function(value, element, params) {
	var monthAndYear = $(params.monthAndYear).val();
	var expiration = monthAndYear.split('/');
	var initYear = 2000;
	var month = parseInt(expiration[0]);
	var year = parseInt(expiration[1]) + initYear;
	var minMonth = new Date().getMonth() + 1;
	var maxMonth = 12;
	var minYear = new Date().getFullYear();
	var monthAndYearContainer = $(params.monthAndYear).closest(containerSelectorClasses);

	if (isNaN(month) || isNaN(year)) {
		// in case that either one of the fields is not filled then combination of month and year becomes invalid
		return false;
	}

	// check if both year and month are filled
	if ((year > minYear && month <= maxMonth) || (year === minYear && month >= minMonth && month <= maxMonth)) {
		//checks if year is in the future or it's current year but month is this one or later
		// in this case both fields are filled and date is not in the past so fields are valid
		monthAndYearContainer.removeClass('has-error');
		return true;
	}

	// in this case both fields are filled but date is in the past so fields are invalid
	monthAndYearContainer.addClass('has-error');
	return false;
};

function validateBirthday(value, element) {
	return util.validateDateOfBirth(value, element);
}

function validatePassword(value, el) {
	var passwordRegex;
	var isValid = true;

	if (!el.hasAttribute('regex')) {
		return isValid;
	}

	passwordRegex = new RegExp(el.getAttribute('regex'));

	isValid = passwordRegex.test($.trim(value));

	return isValid;
}

function clearError(element) {
	var $this = $(element);
	$this.removeClass('error');
	$this.siblings('span.error,label.error').remove();
	$this.closest(containerSelectorClasses).removeClass('has-error');
}

var designatedDeliveryValidation = function() {
	var isValid = true;
	var designatedDeliveryIsVisible = $('.js-designated-delivery').length > 0;
	if (!designatedDeliveryIsVisible) {
		return isValid;
	}

	//index 0 is counted 'empty' because it's placeholder for time dropdown and it's value is empty
	var timeIsEmpty = $('.js-designated-delivery-time').prop('selectedIndex') === 0;
	var dateIsEmpty = !$('.js-datepicker').val();
	isValid = !timeIsEmpty || !dateIsEmpty;
	if (isValid) {
		clearError($('.js-designated-delivery-group'));
	}
	else {
		$('.js-designated-delivery-group').addClass('error');
		$('.js-designated-delivery-group')
			.closest(containerSelectorClasses)
			.addClass('has-error');
	}

	return isValid;
};

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod('phone', validatePhone, function(param, el) {
	var regexText = $(el).data('regex-text');
	return regexText || Resources.INVALID_PHONE;
});

$.validator.addMethod('upivpa', validateUpiVpa, function(param, el) {
	return Resources.VIRTUAL_PAYMENT_ADDRESS_MISSING;
});

/**
 * Add phone prefix validation method to jQuery validation plugin.
 * Text fields must have 'phoneprefix' css class to be validated as phone prefix
 */
$.validator.addMethod('phoneprefix', validatePhonePrefix, Resources.INVALID_PHONEPREFIX);


/**
 * Add regex validation  method to jQuery validation plugin.
 * Add a data attribute data-regex-text to the field if you want to have a custom message
 */
$.validator.addMethod('regex', validateRegex,
	function(param, el) {
		var regexText = $(el).data('regex-text');
		return regexText || Resources.INVALID_NAME;
	});

/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod('owner', validateOwner, Resources.INVALID_OWNER);

$.validator.addMethod(
	'CCCvn',
	function(value, element, params) {
		var cardType = $(params.cardType).val();
		// Amex is the only credit card type with 4 cvn digits, all others use 3
		return cardType === 'Amex' ? element.value.length === 4 : element.value.length === 3;
	},
	Resources.CVN_INVALID
);

$.validator.addMethod('CCExp', validateExpiration, Resources.VALIDATE_EXPIRATION);

$.validator.addMethod('CCExpAccount', validateExpiration, Resources.VALIDATE_EXPIRATION);

$.validator.addMethod('designatedDelivery', designatedDeliveryValidation, Resources.VALIDATE_DESIGNATED);

$.validator.addMethod('birthdayValidate', validateBirthday, Resources.VALIDATE_DATEOFBIRTH);

$.validator.addMethod('passwordValidate', validatePassword, Resources.VALIDATE_PASSWORD);
/**
 * Add a custom validation function for the jQuery validation plugin.
 * This function will execute the standard jQuery creditcard validation when the text field
 * - have the class 'CCNumber' css class to be validated
 * - AND NOT the class 'js-noccvalidation'
 */
$.validator.addMethod(
	'CCNumber',
	function(value, element, params) {
		if ($(element).hasClass('js-noccvalidation')) {
			return true;
		}
		var cardType = $(params.cardType).val();
		if (cardType === null) {
			return false;
		}
		return $.validator.methods.creditcard.call(this, value, element);
	},
	Resources.VALIDATE_CREDITCARD
);

/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod(
	'gift-cert-amount',
	function(value, el) {
		var isOptional = this.optional(el);
		var isValid = !isNaN(value) && parseFloat(value) >= 5 && parseFloat(value) <= 5000;
		return isOptional || isValid;
	},
	Resources.GIFT_CERT_AMOUNT_INVALID
);

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod(
	'positivenumber',
	function(value) {
		if ($.trim(value).length === 0) {
			return true;
		}
		return !isNaN(value) && Number(value) >= 0;
	},
	''
); // '' should be replaced with error message if needed

$.extend($.validator.messages, {
	required: Resources.VALIDATE_REQUIRED,
	remote: Resources.VALIDATE_REMOTE,
	email: Resources.VALIDATE_EMAIL,
	url: Resources.VALIDATE_URL,
	date: Resources.VALIDATE_DATE,
	dateISO: Resources.VALIDATE_DATEISO,
	number: Resources.VALIDATE_NUMBER,
	digits: Resources.VALIDATE_DIGITS,
	creditcard: Resources.VALIDATE_CREDITCARD,
	equalTo: Resources.VALIDATE_EQUALTO,
	maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
	minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
	rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
	range: $.validator.format(Resources.VALIDATE_RANGE),
	max: $.validator.format(Resources.VALIDATE_MAX),
	min: $.validator.format(Resources.VALIDATE_MIN)
});

var validator = {
	settings: settings,
	init: function() {
		var self = this;
		$('form:not(.suppress)').each(function() {
			$(this).validate(self.settings);
		});
	},
	initForm: function(f) {
		$(f).validate(this.settings);
	}
};

/**
 * Add email code validation method to jQuery validation plugin.
 * Text fields must have 'email' css class to be validated as email
 */
$.validator.addMethod('email', validateRegex, 	function(param, el) {
	var regexText = $(el).data('regex-text');
	return regexText || Resources.VALIDATE_EMAIL;
});

/**
 * Add postal code validation method to jQuery validation plugin.
 * Text fields must have 'postal' css class to be validated as postal
 */
$.validator.addMethod('postal', validatePostal, function(param, el) {
	var regexText = $(el).data('regex-text');
	return regexText || Resources.INVALID_ZIP;
});

$.validator.messages.required = function ($1, el, $3) {
	var requiredText = $(el).data('required-text');
	return requiredText || Resources.VALIDATE_REQUIRED;
};

module.exports = validator;
