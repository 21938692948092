'use strict';

var util = require('./util');
var dialog = require('./dialog');
var service = require('./pages/service');
var einsteinRecomm = require('./einsteinRecomm');

/**
 * @function
 * @description JS related to the Page Designer
 */

// Scroll page to the component ID
function pageDesignerHashBtn() {
	$('body').off('click', '[data-js="pd-btn"]').on('click', '[data-js="pd-btn"]', function (e) {
		var hash = this.hash;
		// check if href starts with #
		if (hash && $(hash).length) {
			e.preventDefault();

			var headerHeight = $('[data-js="header"]').innerHeight();
			var targetOffset = $(hash).offset().top - headerHeight;

			$('html, body').animate({
				scrollTop: targetOffset
			}, 600);
		}
	});
}

function initBirthDay() {
	var element = '#dwfrm_newsletter_dateofbirth';
	var selector = $(element);
	$('body').off('change keyup', element).on('change keyup', element, function (e) {
		var locale = window.SitePreferences.LOCALE;
		var site = locale ? locale.slice(-2) : '';
		util.formatDateOfBirth(e, site);
		var dateOfBirthValue = selector.val();
		if (dateOfBirthValue && dateOfBirthValue.length > 5) {
			selector.val(dateOfBirthValue.substring(0, 5));
		}
	});

	$('body').off('focusout', element).on('focusout', element, function (e) {
		e.preventDefault();
		e.stopImmediatePropagation();
		var element = e.currentTarget;
		var value = e.currentTarget.value;
		var isValid = util.validateDateOfBirth(value, element);

		if (!isValid) {
			selector.removeClass('valid').addClass('error');
			selector.closest('.input-container').removeClass('is-valid').addClass('has-error');
		}
	});
}

//Prepares the structured data for PD pages
function prepareStructuredData() {
	var imageData = null;
	var heroBanner = null;
	var headline = null;
	var headlineText = null;
	var bannerElems = $('[data-js="pd-banner-wrap"]').find('[data-js="banner-img"]').filter(excludeMobileBannerLayoutComp);
	var headlineElems = $('[data-js="headlinetext"]').filter(excludeMobileBannerLayoutComp);
	var selectorForDeviceType = util.isMobile() ? 'js-only-mobile' : 'js-only-desktop';
	//Mobile banner layout uses the banner component and it's image and headline should be excluded
	//because it can override the main banner component
	function excludeMobileBannerLayoutComp() {
		return !$(this).parents('.mobile-banner-layout').length;
	}

	//there's a posibility of having two banner components
	//when both exist herobanner is used when herobanner is not defined, banner component is used
	if (bannerElems.length > 0) {
		var bannerForDeviceType = bannerElems.filter(function () {
			return $(this).parent().hasClass(selectorForDeviceType);
		});
		heroBanner = bannerForDeviceType && bannerForDeviceType.length > 0 ? bannerForDeviceType[0] : bannerElems[0];
		imageData = $(heroBanner).data('bg');
	}
	else {
		//fallback for imageData when banner image is not found
		imageData = $('#logoURL').val();
	}

	//possibility of having two banners and two text_headline attrs
	if (headlineElems.length > 0) {
		headline = headlineElems[0];
		headlineText = headline.textContent;
	}
	else {
		//fallback for headline text when banner text_headline attr is not found
		headlineText = document.title;
	}

	var structuredDataText = {
		"@context": "https://schema.org",
		"@type": "Article",
		'image': imageData,
		'headline': headlineText
	};
	const script = document.createElement('script');
	script.setAttribute('type', 'application/ld+json');
	script.textContent = JSON.stringify(structuredDataText);
	document.head.appendChild(script);
}

// Scroll page to the component ID
function storeExclusivity() {
	$('body').off('click', '[data-js="btn-exclusivity"]').on('click', '[data-js="btn-exclusivity"]', function (e) {
		var StoreWrapClosest = $(this).closest('[data-js="store-exclusivity-wrap"]');
		var WrappedStoreContent = StoreWrapClosest.children('[data-js="exclusivity-list"]');
		var pdComponentId = StoreWrapClosest.data('pd-component-id');

		WrappedStoreContent.slideToggle(500, function () {
			if (StoreWrapClosest.hasClass('is-open')) {
				StoreWrapClosest.removeClass('is-open');
				$(this).attr('aria-expanded', false);

				$(document).trigger('triggerStoreExclusivity', pdComponentId);
			}
			else {
				StoreWrapClosest.addClass('is-open');
				$(this).attr('aria-expanded', true);

				$(document).trigger('triggerStoreExclusivity', [pdComponentId, WrappedStoreContent]);
			}
		});
	});
}

function imageToggle() {
	var toggleBtn = $('[data-js="btn-image-toggle"]');
	toggleBtn.off('click').on('click', function () {
		$(this).parent().parent().toggleClass('btn-toggle__on');
	});
}

function anchorItemScroll() {
	var anchorLink = $('[data-js="scroll-bottom"]');
	anchorLink.on('click', function () {
		var height = $('.c-header-nav').height() + $(anchorLink.parent()).height();
		$('html, body').animate({ scrollTop: height }, 500);
	});
}

/**
 * To initiate the dialog anchors on page designer components
 * It only supports inline html dialogs for now
 */
function initDialog() {
	var dialogOpenAnchor = $('[data-js="open-pd-dialogbox"]');

	// the content of the dialog should be in an hidden html element which has 'data-js="pd-dialog-content"'
	// that hidden html element should be a child of the anchor element

	if (dialogOpenAnchor.length > 0) {
		dialogOpenAnchor.on('click', function (e) {
			e.preventDefault();

			// get the html from minicart, then trash it
			var dialogContent = $(this).find('[data-js="pd-dialog-content"]').html();

			if (dialogContent) {
				dialog.open({
					html: dialogContent,
					options: {
						height: 600
					}
				});
			}
		});
	}
}

const intersectionObservers = new Map();

/**
 * Init intersection observer that will apply specified
 * animation function to the target elements
 * @param {*} animationFn
 */
function initIntersectionObserver(animationFn) {
	const scrollParent = util.getScrollParent().get(0);
	const root = scrollParent === window ? window.document : scrollParent;

	const options = {
		root,
		rootMargin: '0px',
		threshold: 0,
	};

	const callback = function (entries, observer) {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				observer.unobserve(entry.target);
				setTimeout(() => {
					animationFn(entry.target);
				}, 0);
			}
		});
	};

	intersectionObservers.set(animationFn, new IntersectionObserver(callback, options));
}

/**
 * Apply animation function when element intersects its scrolling parent
 * @param {*} el - element that will be checked for intersection
 * @param {*} animationFn - animation function to apply for intersection target
 */
function applyAnimationOnIntersection(el, animationFn) {
	if (!intersectionObservers.has(animationFn)) {
		initIntersectionObserver(animationFn);
	}

	const observer = intersectionObservers.get(animationFn);
	observer.observe(el);
}

function initStoryBlockAnimation() {
	$('[data-js="story-block"] .js-intersection-target').each(function () {
		applyAnimationOnIntersection(this, applyHomePageComponentAnimation);
	});
}
function initHeroBanner() {
	$('.js-hero-banner-carousel .swiper-slide-active .js-intersection-target').each(function () {
		applyAnimationOnIntersection(this, applyHomePageComponentAnimation);
	});
}

function initImageTextBtnAnimation() {
	$('[data-js="image-text-btn"] .js-intersection-target').each(function () {
		applyAnimationOnIntersection(this, applyHomePageComponentAnimation);
	});
}

/**
 * @param {*} component - HTML element that contains utility animation classes:
 * .js-step-animate-text will be animated with typewriter effect
 * .js-fade-in-element will be animated with fade-in after typewriter effect is finished
 */
async function applyHomePageComponentAnimation(component) {
	const $component = $(component);

	const $stepAnimationEl = $component.find('.js-step-animate-text');
	await applyTypewriterAnimation($stepAnimationEl);

	const $fadeInElement = $component.find('.js-fade-in-element');
	applyFadeInAnimation($fadeInElement);
}

function applyTypewriterAnimation($element) {
	const ANIMATION = 'reveal-sharply';
	const DELAY_IN_MS = 50;
	const text = $element.text().trim();
	let wrappedLetters = '';

	return new Promise((resolve) => {
		for (let i = 0; i < text.length; i++) {
			const delay = i * DELAY_IN_MS;

			wrappedLetters += `<span class="${ANIMATION}"`
				+ `style="-webkit-animation-delay: ${delay}ms; animation-delay: ${delay}ms">`
				+ text.charAt(i)
				+ '</span>';
		}

		setTimeout(resolve, DELAY_IN_MS * text.length);

		$element.html(wrappedLetters);
		$element.css('visibility', 'visible');
	});
}

function applyFadeInAnimation($element) {
	$element
		.css('visibility', 'visible')
		.hide()
		.fadeIn();
}

function marquee() {
	function initMarquee() {
		if (pillSize < wrapSize) {
			// Increase pill size via adding content
			$pill.append(textContent);
			pillSize = $pill.outerWidth();
			return initMarquee();
		}

		if (!(pillSize < wrapSize)) {
			pillContent = $wrap.html();
			$wrap.append(pillContent);

			$('.js-marquee .js-marquee-pill').attr('data-move-size', -pillSize);
			$('.js-marquee .js-marquee-pill:first-child').css('left', '0');
			$('.js-marquee .js-marquee-pill:nth-child(2)').css('left', pillSize + 'px');

			$wrap.data('move', true);
		}
	}

	function updateMarquee() {
		$('.js-marquee .js-marquee-pill:first-child').remove();
		$wrap.append(pillContent);
		$('.js-marquee .js-marquee-pill').attr('data-move-size', -pillSize);
		$('.js-marquee .js-marquee-pill:nth-child(2)').css('left', pillSize + 'px');
	}

	function moveMarquee() {
		var $firstPill = $('.js-marquee .js-marquee-pill:first-child');
		var $secondPill = $('.js-marquee .js-marquee-pill:nth-child(2)');

		var currentPos = parseInt($firstPill.css('left').replace(/[^-\d\.]/g, ''));
		var moveLimit = parseInt($firstPill.attr('data-move-size').replace(/[^-\d\.]/g, ''));

		if (currentPos > moveLimit) {
			$firstPill.css('left', (currentPos - step + 'px'));
			$secondPill.css('left', ((currentPos - (moveLimit)) - step + 'px'));
		} else {
			updateMarquee();
		}
	}

	function playMarquee() {
		if ($wrap.data('move') === false) {
			$wrap.data('move', true);
			trigger = setInterval(moveMarquee, 100);
		}
	}

	function pauseMarquee() {
		if ($wrap.data('move') === true) {
			$wrap.data('move', false);
			clearInterval(trigger);
		}
	}

	function manageMarqueeVisibility() {
		var isMarqueeVisible = util.elementInViewport($('.full-width-message').get(0), 0);
		if (isMarqueeVisible) {
			playMarquee();
		}
		if (!(isMarqueeVisible)) {
			pauseMarquee();
		}
	}

	if ($('.js-marquee').length > 0) {
		var $wrap = $('.js-marquee');
		var $pill = $('.js-marquee .js-marquee-pill');

		var wrapSize = $wrap.outerWidth();
		var pillSize = $pill.outerWidth();

		var pillContent = $wrap.html();
		var textContent = $pill.html();

		var step = 6; // step holder

		var trigger; // Trigger move action

		if (util.isSmallScreenSize()) {
			// Control click action on mobile size
			$wrap.on('click', function (event) {
				event.preventDefault();
				if ($wrap.data('move') === true) {
					$wrap.data('move', false);
					clearInterval(trigger);
				}
				else {
					$wrap.data('move', true);
					trigger = setInterval(moveMarquee, 100);
				}
			});
		}
		else {
			// Control mouse actions
			$wrap.on('mouseenter', function () {
				pauseMarquee();
			}).on('mouseleave', function () {
				playMarquee();
			});

			// Control key actions
			$(document).on('keydown', function (event) {
				var code = event.keyCode || window.event.keyCode; // get keyCode (window.event is for IE)

				if (code === 32 && $wrap.hasClass('focus-visible')) { // spacebar(32)
					event.preventDefault();
					if ($wrap.data('move') === true) {
						$wrap.data('move', false);
						clearInterval(trigger);
					}
					else {
						$wrap.data('move', true);
						trigger = setInterval(moveMarquee, 100);
					}
				}
			});
		}


		initMarquee();
		trigger = setInterval(moveMarquee, 100);
		$(document).on('scroll', manageMarqueeVisibility);
	}
}
function initHotspotComponent() {
	var hotspotButton = $('[data-js="product-hotspot-button"]');
	hotspotButton.on('mouseenter mouseleave', function (e) {
		var contentWrapper = $(this).next();
		var isActive = contentWrapper.hasClass('active');
		var contentWrapperWidth = contentWrapper.outerWidth();
		var windowWidth = $(window).width();
		var leftPos = parseInt(contentWrapper.css('left'), 10);
		var spaceLeft = windowWidth - leftPos;
		var rightPos = parseInt((spaceLeft / windowWidth) * 100);

		//stick the content wrapper to right if no enough space
		if (spaceLeft < contentWrapperWidth) {
			contentWrapper.css({'left': 'auto' , 'right': rightPos+'%'});	
		}

		$('.hotspot-button').removeClass('active');
		$('.hotspot-content').removeClass('active');

		if (!isActive) {
			$(this).addClass('active');
			contentWrapper.addClass('active');
		}
	});
}


exports.init = function () {
	storeExclusivity();
	pageDesignerHashBtn();
	initBirthDay();
	imageToggle();
	anchorItemScroll();
	marquee();
	initDialog();
	initHeroBanner();
	initStoryBlockAnimation();
	initImageTextBtnAnimation();
	service.initAccordionEvents();
	initHotspotComponent();

	//init structured data only for the PD pages that use eccodecorator (not the ones remotely included)
	if (!!$('body').children('.pt_storefront.page-designer').length) {
		prepareStructuredData();
	}
	$(function () {
		einsteinRecomm.load();
	});
};
