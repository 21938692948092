var validator = require('./validator'),
	checkoutUtils = require('./checkoututils'),
	submitForm = require('./submitForm');

function initializeEvents() {
	$('body').off('click', '[data-js="save-contact-details"]').on('click', '[data-js="save-contact-details"]', function(e) {
		e.preventDefault();

		var isSectionValid = validator.validateContactDetailsSection();
		if (!isSectionValid) {
			return;
		}

		var options = {
			action: 'saveContactDetails',
			callback: checkoutUtils.scrollToNextSection
		};

		//Fire a GA Tracking event if subscribed in newsletter
		$(document).trigger('newsletterSubscribeCheckout');

		submitForm.submitAjaxForm(options);
	});

	$('body').off('click', '[data-js="edit-contact-details"]').on('click', '[data-js="edit-contact-details"]', function(e) {
		e.preventDefault();
		submitForm.submitAjaxForm(
			{
				action: 'editContactDetails',
				callback: checkoutUtils.scrollToNextSection
			}
		);
	});
}

exports.init = function () {
	initializeEvents();
};