'use strict';

var util = require('./util');
var bonusProductsView = require('./bonus-products-view');
var accessibility = (util.isMobile()) ? require('./accessibilityForMobile') : require('./accessibility');
var keyboard = require('./constants').keyboard;

var timer = {
	id: null,
	clear: function () {
		if (this.id) {
			window.clearTimeout(this.id);
			delete this.id;
		}
	},
	start: function (duration, callback) {
		this.id = setTimeout(callback, duration);
	}
};

var minicart = {
	init: function () {
		this.$el = $('#mini-cart');
		this.$content = this.$el.find('.js-c-mini-cart');
		this.$close = this.$el.find('.js-close-mini-cart');
		this.$notifications = this.$el.find('[data-js="mini-cart-notifications"]');
		this.$overlay = $('.js-overlay');

		// Open the mini cart on hover
		this.$el.find('.js-mini-cart').on('mouseenter mouseleave', function(e) {
			this.$notifications.empty();
			if ($('a.js-mini-cart').hasClass('mini-cart-empty')) {
				return;
			}
			if (!util.isSmallScreenSize()) {
				if (this.$content.not(':visible')) {
					this.slide();
					$('.js-c-mini-cart').addClass('opened');
					this.$overlay.fadeIn();
					$('.header .nav-user').addClass('active');
				}
				e.preventDefault();
			}
		}.bind(this));

		this.$content.on('mouseenter', function (e) {
			timer.clear();
		}.bind(this));

		this.$content.on('keydown', function (e) {
			// tabbing keypress
			if (e.keyCode === keyboard.TAB_KEY) {
				timer.clear();
			}
		}.bind(this));

		this.$close.on('keydown click', function (e) {
			// enter keypress
			if (e.keyCode === keyboard.ENTER_KEY || e.type === 'click') {
				e.stopPropagation();
				this.close();
				$('.header .nav-user').removeClass('active');
			}
		}.bind(this));

		this.$content.on('keydown', function (e) {
			// enter keypress
			if (e.keyCode === keyboard.ENTER_KEY) {
				timer.clear();
			}
		}.bind(this));

		this.$overlay.on('click', function () {
			this.close();
			$('.header .nav-user').removeClass('active');
		}.bind(this));
	},
	/**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     */
	show: function (html) {
		this.$el.html(html);
		this.init();
		this.slide();
		bonusProductsView.loadBonusOption();
	},
	/**
     * @function
     * @description Updates the mini cart with the given content
     * @param {String} A HTML string with the content which will be shown
     */
	update: function (html) {
		this.$el.html(html);
		this.init();
		bonusProductsView.loadBonusOption();
	},
	/**
     * @function
     * @description Slides down and show the contents of the mini cart
     */
	slide: function () {
		this.$content.addClass('opened');
		accessibility.focusTrap($('.js-c-mini-cart'));

		//Set aria expanded to true
		$('.js-mini-cart').attr('aria-expanded', true);
	},
	/**
     * @function
     * @description Adds a shopping cart notification
     * @param {String} text the text to display
     */
	addNotification: function(text) {
		this.$notifications.empty();
		this.$notifications.append('<span class="mini-cart-message">' + text + '</span>');
		this.$notifications.attr('aria-hidden', 'false');
	},
	/**
     * @function
     * @description Closes the mini cart with given delay
     * @param {Number} delay The delay in milliseconds
     */
	close: function (delay) {
		// when closing the minicart, the notification of added product is not relevant anymore
		this.$notifications.attr('aria-hidden', 'true');
		this.$content.removeClass('opened');
		this.$overlay.fadeOut();
		$('.js-mini-cart').attr('aria-expanded', false);
	},

	/**
     * @function
     * @description Starts timer for closing after product addition
     */
	startTimer: function(delayTime) {
		var delay = delayTime || 4000;
		$('a.js-mini-cart').removeClass('mini-cart-empty');
		timer.clear();
		timer.start(delay, this.close.bind(this));
	}
};

module.exports = minicart;
