'use strict';

var wishlistEvents = require('./wishlist'),
	pageDesignerEvents = require('./pagedesigner'),
	impressionEvents = require('./impressions'),
	customEvents = require('./customEvents'),
	checkoutEvents = require('./checkout'),
	productEvents = require('./product'),
	newsletterEvents = require('./newsletter'),
	storeAvailabilityEvents = require('./storeAvailability'),
	//storeLocatorEvents = require('./storelocator'),
	trackingHelper = require('./trackingHelper'),
	ometriaTracker = require('./ometriaTracking');
/**
 * Initializes each page and feature specific tracking events.
 */
function initTrackingEvents() {
	try {
		impressionEvents.init();
		productEvents.init();
		checkoutEvents.init();
		customEvents.init();
		//storeLocatorEvents.init();
		newsletterEvents.init();
		storeAvailabilityEvents.init();
		wishlistEvents.init();
		pageDesignerEvents.init();
		trackingHelper.pushEcommerceObject();
		if(typeof ometria !== 'undefined'){
			ometriaTracker.initOmetriaTracking();
		}
	}
	catch (err) {
		if (!(typeof console === 'undefined')) {
			// don't break page javascript, when there is an error in the tracking code
			console.error('Error in tracking script: ' + err.message + ', ' + err.stack);
		}
	}
}

/**
 * Initializes tracking if GTM is ready.
 */
function initTracking() {
	if (googleAnalyticInitialized) {
		if (!!window.google_tag_manager) {
			initTrackingEvents();
			return;
		}

		// if google_tag_manager is not initialized, attach custom event to triger when loading is finished (gtm_loaded is custom event added in gtmtaghead.isml)
		window.addEventListener('gtm_loaded', function() {
			initTrackingEvents();
		});
	}
}

/**
 * Init tracking.
 */
exports.init = function () {
	initTracking();
};