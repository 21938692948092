'use strict';

var forms = require('../forms'),
	detailsmodaldialog = require('../detailsmodaldialog'),
	clickAndReserve = require('../clickandreserve'),
	tooltip = require('../tooltip'),
	fbconversion = require('../fbconversion');

exports.init = function () {
	forms.init();
	detailsmodaldialog.init();
	clickAndReserve.init();
	tooltip.initializeCustomTooltip();
	fbconversion.init();
};
