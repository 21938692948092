'use strict';

exports.init = function() {
	var btnTabs = $('.js-ccp-tab');

	btnTabs.on('click', function (e) {
		e.preventDefault();
		var id = $(e.target).attr('id');

		var ccpContent = $('.js-products-ccp-content');
		var targetContent = ccpContent.filter('[data-js="' + id + '"]');

		btnTabs.removeClass('active');
		$(this).addClass('active');
		ccpContent.removeClass('active');
		targetContent.addClass('active');

		// trigger resize event which will fix the positioning of swipers
		var resizeEvent = window.document.createEvent('UIEvents');
		resizeEvent.initUIEvent('resize', true, false, window, 0);
		window.dispatchEvent(resizeEvent);
	});
};