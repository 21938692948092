'use strict';

var trackingHelper = require('./trackingHelper'),
	customEvents = require('./customEvents'),
	ometriaTracker = require('./ometriaTracking');

/**
 * Pushes data to track product detail page
 */
function productDetail() {
	if ($('.js-pdp-product-info').length == 0) {
		sessionStorage.removeItem(trackingHelper.sessionStorageListName);
		return;
	}

	var actionField = {};
	if (window.sessionStorage && sessionStorage.getItem(trackingHelper.sessionStorageListName)) {
		actionField = { 'list': sessionStorage.getItem(trackingHelper.sessionStorageListName) };
	}

	trackingHelper.ecommerceMerge({
		'event': 'productDetail',
		'ecommerce': {
			'detail': {
				'actionField': actionField,
				'products': [trackingHelper.getTrackingProduct($('.js-pdp-product-info'), true)]
			}
		}
	});
}

/**
 * Pushes data to track VG change event
 * @param {*} event
 */
function colorChangedEvent(event) {
	trackingHelper.ecommerceObject = null;
	productDetail();
	trackingHelper.pushEcommerceObject();
	ometriaTracker.initOmetriaTracking();
}

/**
 * Pushes data to track quickview
 * @param {*} event
 */
function showQuickviewEvent(event) {
	trackingHelper.ecommerceObject = null;
	productDetail();
	trackingHelper.pushEcommerceObject();
	customEvents.showQuickView();
}

/**
 * Pushes data to track add to cart event
 * @param {*} event
 * @param {*} eventId
 * @param {*} productSelector
 * @param {*} oldQuantity
 */
function addToCartEvent(event, eventId, productSelector, oldQuantity) {
	var trackingProduct = null;

	if (productSelector) { //Used on quntity scroller
		var cartRow = $(productSelector);
		trackingProduct = trackingHelper.getTrackingProduct(cartRow);
		$.extend(trackingProduct, { 'quantity': oldQuantity || cartRow.find('input[type=hidden][name$=_quantity]').val() });
	}
	else {
		trackingProduct = trackingHelper.getTrackingProduct($('.js-pdp-product-info'));
		$.extend(trackingProduct, { 'quantity': 1 });
	}
	if (typeof ometria !== 'undefined') {
		ometria.trackAddToBasket(trackingProduct.variantgroupId, trackingProduct.quantity);
	}
	var addToCartData = {
		'event': 'addToCart',
		'ecommerce': {
			'eventId': eventId,
			'currencyCode': $('#js-currency').val(),
			'add': {
				'products': [trackingProduct]
			}
		}
	};

	if (pageContext.gtmPageType === 'MyWishlist') {
		addToCartData.ecommerce.add.actionField = {
			list: 'MyWishlist'
		};
	}

	trackingHelper.pushToDataLayer(addToCartData);
}

function initializeEvents() {
	productDetail();

	$(document).on('colorchanged', colorChangedEvent);
	$(document).on('showQuickview', showQuickviewEvent);
	$(document).on('addtocart', addToCartEvent);
}

/**
 * Initialize all events for product GTM tracking
 */
exports.init = function () {
	initializeEvents();
};