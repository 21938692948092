'use strict';

var _ = require('lodash'),
	Promise = require('promise'),
	constants = require('./constants');

var util = {
	/**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
	appendParamToURL: function (url, name, value) {
		// quit if the param already exists
		if (url.indexOf(name + '=') !== -1) {
			return url;
		}
		var separator = url.indexOf('?') !== -1 ? '&' : '?';
		return url + separator + name + '=' + encodeURIComponent(value);
	},

	removeHashFromURL: function (url) {
		var processedUrl = url;
		if (url.indexOf('#') > 0) {
			var urlParts = url.split('#');
			processedUrl = urlParts[0] + urlParts[1].substr(urlParts[1].indexOf('&'));
		}
		return processedUrl;
	},

	/**
     * @function
     * @description remove the parameter and its value from the given url and returns the changed url
     * @param {String} url the url from which the parameter will be removed
     * @param {String} name the name of parameter that will be removed from url
     */
	removeParamFromURL: function (url, name) {
		if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
			return url;
		}
		var hash;
		var params;
		var domain = url.split('?')[0];
		var paramUrl = url.split('?')[1];
		var newParams = [];
		// if there is a hash at the end, store the hash
		if (paramUrl.indexOf('#') > -1) {
			hash = paramUrl.split('#')[1] || '';
			paramUrl = paramUrl.split('#')[0];
		}
		params = paramUrl.split('&');
		for (var i = 0; i < params.length; i++) {
			// put back param to newParams array if it is not the one to be removed
			if (params[i].split('=')[0] !== name) {
				newParams.push(params[i]);
			}
		}
		return domain + (newParams.length > 0 ? ('?' + newParams.join('&')) : '') + (hash ? '#' + hash : '');
	},

	/**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object} params
     */
	appendParamsToUrl: function (url, params) {
		var _url = url;
		_.each(params, function (value, name) {
			_url = this.appendParamToURL(_url, name, value);
		}.bind(this));
		return _url;
	},
	/**
	 * @description update url without reloading the page.
	 * When for example color changed on PDP with AJAX then we call this method to update the url of the browser address bar.
	 * This method is pushing a new state to browser history which means it becomes a new visited page in the history
	 * so clicking on back/forward buttons will load those pages also.
     * @param {String} href - url
     **/
	updateBrowserURL: function(url) {
		if (url) {
			var parser = document.createElement('a');
			parser.href = url;

			var newURL = null;

			if (parser.origin) {
				newURL = url.replace(parser.origin, '');
			}
			else if (window.location.origin) {
				newURL = url.replace(window.location.origin, '');
			}

			if (newURL) {
				history.pushState('', document.title, newURL);
			}
		}
	},
	/**
     * @description update url without reloading the page
	 * When for example color changed on PDP with AJAX then we call this method to update the url of the browser address bar.
	 * This method is replacing the current state in the browser history which means it doesn't become
	 * a new visited page in the history but it just updates the current page in the history.
     * @param {String} href - url
     **/
	updateBrowserURLByReplacingTheCurrentState: function(url) {
		if (url) {
			var parser = document.createElement('a');
			parser.href = url;

			var newURL = null;

			if (parser.origin) {
				newURL = url.replace(parser.origin, '');
			}
			else if (window.location.origin) {
				newURL = url.replace(window.location.origin, '');
			}

			if (newURL) {
				history.replaceState('', document.title, newURL);
			}
		}
	},
	/**
     * @function
     * @description extract the query string from URL
     * @param {String} url the url to extra query string from
     **/
	getQueryString: function (url) {
		var qs;
		if (!_.isString(url)) { return; }
		var a = document.createElement('a');
		a.href = url;
		if (a.search) {
			qs = a.search.substr(1); // remove the leading ?
		}
		return qs;
	},
	isDescendant: function (parent, child) {
		let node = child.parentNode;
		while (node) {
			if (node === parent) {
				return true;
			}

			// Traverse up to the parent
			node = node.parentNode;
		}

		// Go up until the root but couldn't find the `parent`
		return false;
	},
	/**
     * @function
     * @description
     * @param {String}
     * @param {String}
     */
	elementInViewport: function (el, offsetToTop) {
		var top = el.offsetTop,
			left = el.offsetLeft,
			width = el.offsetWidth,
			height = el.offsetHeight;

		while (el.offsetParent) {
			el = el.offsetParent;
			top += el.offsetTop;
			left += el.offsetLeft;
		}

		if (typeof (offsetToTop) !== 'undefined') {
			top -= offsetToTop;
		}

		if (window.pageXOffset !== null) {
			return (
				top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
			);
		}

		if (document.compatMode === 'CSS1Compat') {
			return (
				top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight)
                && left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth)
                && (top + height) > window.document.documentElement.scrollTop
                && (left + width) > window.document.documentElement.scrollLeft
			);
		}
	},

	/**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {String} path the relative path
     */
	ajaxUrl: function (path) {
		return this.appendParamToURL(path, 'format', 'ajax');
	},

	/**
     * @function
     * @description
     * @param {String} url
     */
	toAbsoluteUrl: function (url) {
		var processedUrl = url;
		if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
			processedUrl = '/' + url;
		}
		return processedUrl;
	},
	/**
     * @function
     * @description Loads css dynamically from given urls
     * @param {Array} urls Array of urls from which css will be dynamically loaded.
     */
	loadDynamicCss: function (urls) {
		var i, len = urls.length;
		for (i = 0; i < len; i++) {
			this.loadedCssFiles.push(this.loadCssFile(urls[i]));
		}
	},

	/**
     * @function
     * @description Loads css file dynamically from given url
     * @param {String} url The url from which css file will be dynamically loaded.
     */
	loadCssFile: function (url) {
		return $('<link/>').appendTo($('head')).attr({
			type: 'text/css',
			rel: 'stylesheet'
		}).attr('href', url); // for i.e. <9, href must be added after link has been appended to head
	},
	// array to keep track of the dynamically loaded CSS files
	loadedCssFiles: [],

	/**
     * @function
     * @description Removes all css files which were dynamically loaded
     */
	clearDynamicCss: function () {
		var i = this.loadedCssFiles.length;
		while (0 > i--) {
			$(this.loadedCssFiles[i]).remove();
		}
		this.loadedCssFiles = [];
	},
	/**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {String} qs The query string from which the parameters will be extracted
     */
	getQueryStringParams: function (qs) {
		if (!qs || qs.length === 0) { return {}; }
		var params = {},
			unescapedQS = decodeURIComponent(qs);
		// Use the String::replace method to iterate over each
		// name-value pair in the string.
		unescapedQS.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
			function ($0, $1, $2, $3) {
				params[$1] = $3;
			});
		return params;
	},

	fillAddressFields: function (address, $form) {
		for (var field in address) {
            if (field === 'ID' || field === 'UUID' || field === 'key') {
                continue;
            }
            // if the key in address object ends with 'Code', remove that suffix
            // keys that ends with 'Code' are postalCode, stateCode and countryCode
            var $foundField = $form.find('[name$="' + field.replace('Code', '') + '"]');
            $foundField.val(address[field]);
            // update the state fields
            if (field === 'countryCode') {
                $form.find('[name$="country"]').trigger('change');
                // retrigger state selection after country has changed
                // this results in duplication of the state code, but is a necessary evil
                // for now because sometimes countryCode comes after stateCode
                $form.find('[name$="state"]').val(address.stateCode);
            }

            $foundField.trigger('focus');
            $foundField.trigger('blur');
        }
	},
	/**
     * @function
     * @description Updates the number of the remaining character
     * based on the character limit in a text area
     */
	limitCharacters: function () {
		$('form').find('textarea[data-character-limit]').each(function () {
			var characterLimit = $(this).data('character-limit');
			var charCountHtml = String.format(Resources.CHAR_LIMIT_MSG,
				'<span class="char-remain-count">' + characterLimit + '</span>',
				'<span class="char-allowed-count">' + characterLimit + '</span>');
			var charCountContainer = $(this).next('div.char-count');
			if (charCountContainer.length === 0) {
				charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
			}
			charCountContainer.html(charCountHtml);
			// trigger the keydown event so that any existing character data is calculated
			$(this).change();
		});
	},
	/**
	* Apply general extensions
	*/
	generalExtensions: function () {
		// Extend String.format function
		String.format = function() {
			var str = arguments[0];
			var i, len = arguments.length - 1;
			for (i = 0; i < len; i++) {
				var reg = new RegExp(`\\{${i}\\}`, 'gm');
				str = str.replace(reg, arguments[i + 1]);
			}
			return str;
		};
	},
	/**
     * @function
     * @description Binds the onclick-event to a delete button on a given container,
     * which opens a confirmation box with a given message
     * @param {String} container The name of element to which the function will be bind
     * @param {String} message The message the will be shown upon a click
     */
	setDeleteConfirmation: function (container, message) {
		$(container).on('click', '.delete', function () {
			return window.confirm(message);
		});
	},
	/**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {String} The x coordinate
     */
	scrollBrowser: function (xLocation) {
		$('html, body').animate({ scrollTop: xLocation }, 500);
	},

	/**
     * @function
     * @description Check the agent of mobile devices
     */

	isMobile: function () {
		var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
		var idx = 0;
		var isMobile = false;
		var userAgent = (navigator.userAgent).toLowerCase();

		while (mobileAgentHash[idx] && !isMobile) {
			isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
			idx++;
		}
		return isMobile;
	},

	/**
	 * @function
	 * @description Check the screensize of window
	 */

	isSmallScreenSize: function () {
		return window.matchMedia('(max-width: 1000.5px)').matches;
	},

	isTabletAndAbove: function() {
		return window.matchMedia('(min-width: 481px)').matches;
	},

	isDesktopAndAbove: function() {
		return window.matchMedia('(min-width: 1001px)').matches;
	},

	isTabletAndBelow: function() {
		return window.matchMedia('(max-width: 1000.5px)').matches;
	},

	isMobileAndBelow: function() {
		return window.matchMedia('(max-width: 480.5px)').matches;
	},

	/**
	 * Sticky Sidebar calculation for the checkout
	 * (IE 11 Only, the other browsers we use the native css: position:sticky )
	 * @param {position of the checkout bag from top} top
	 */

	topPositionForCheckoutBag: 0,
	topToSet: null,

	stickyOrderTotalsCalculation: function (top) {
		if (!top) {
			top = $('.js-checkout-bag').offset().top - parseFloat($('.js-checkout-bag').css('marginTop').replace(/auto/, 0));
		}

		if (SitePreferences.IS_RESKIN) {
			this.topToSet = 0;
		}
		else {
			var stepIndicator = $('.js-step-indicator');
			if (stepIndicator.length === 0) {
				stepIndicator = $('[data-js="step-indicator"]');
			}

			if (stepIndicator.length > 0) {
				top = stepIndicator.outerHeight();
				this.topToSet = this.topToSet == null || (this.topToSet != null && top > this.topToSet) ? top : this.topToSet;
			}
			else {
				this.topToSet = 0;
			}
		}

		var checkoutBagHeight = $('.js-checkout-bag').outerHeight();
		var maxY = top + checkoutBagHeight;
		var windowHeight = $(window).height();
		var y = $(window).scrollTop();

		// if the page is scrolled until the top pixel of the checkout bag
		if (y > top) {
			// if the most bottom pixel of the checkout bag is within the screen which means it has a smaller height then the screen
			// so we can set the checkout bag sticky to the top of the screen
			if (maxY < windowHeight) {
				$('.js-checkout-bag').addClass('is-sticky').removeAttr('style');
				this.topPositionForCheckoutBag = this.topToSet;
				$('.js-checkout-bag').css('top', this.topToSet + 'px');
			}
			else if ((maxY - y) <= windowHeight) {
				// if here then most bottom pixel of the checkout bag is not within the screen, it is bigger than the screen
				// so we can't make its top most pixel sticky to the top of the screen, it should be scrolled until its most bottom pixel to be able to display all of its contents

				// if the window scrolled until the most bottom pixel of the checkout bag
				// then make it sticky to the current position, so the bottom is sticky to bottom of the page

				$('.js-checkout-bag').addClass('is-sticky');

				var topPositionToSet = top - y;
				$('.js-checkout-bag').css('top', topPositionToSet + 'px');

				var currentTopPositionOfCheckoutBag = $('.js-checkout-bag').position().top;

				if (this.topPositionForCheckoutBag === this.topToSet) {
					this.topPositionForCheckoutBag = currentTopPositionOfCheckoutBag;
				}

				// if user started to scroll to top of the page then we should not make it sticky anymore after the top position of the checkout bag is within screen
				if (topPositionToSet > this.topPositionForCheckoutBag) {
					$('.js-checkout-bag').removeClass('is-sticky').removeAttr('style');
					this.topPositionForCheckoutBag = this.topToSet;
				}
				else {
					if (topPositionToSet < this.topPositionForCheckoutBag) {
						topPositionToSet = this.topPositionForCheckoutBag;
					}

					$('.js-checkout-bag').css('top', topPositionToSet + 'px');
				}
			}
		}
		else {
			// top most pixel of the checkout bag is still in the screen
			$('.js-checkout-bag').removeClass('is-sticky').removeAttr('style');
			this.topPositionForCheckoutBag = this.topToSet;
		}
	},

	createCookie: function (name, value, days) {
		var expires;

		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = '; expires=' + date.toGMTString();
		}
		else {
			expires = '';
		}
		document.cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value) + expires + '; path=/';
	},

	readCookie: function (name) {
		var nameEQ = encodeURIComponent(name) + '=';
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ')
				c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) === 0)
				return decodeURIComponent(c.substring(nameEQ.length, c.length));
		}
		return null;
	},

	eraseCookie: function (name) {
		this.createCookie(name, '', -1);
	},

	initializeDatePickers: function (el, dateSelectcallback) {
		if (!el) {
			el = $('.js-datepicker:not(.js-datepicker-initialized)');
		}
		$.each(el, function () {
			let $this = $(this),
				designatedDeliveryWrap = $('[data-js="designated-delivery-wrap"]'),
				container = $('<div class="calendar-container"> </div>'),
				dateOptions = $('.js-datepicker-options'),
				// expected to be in YYYY-MM-DD format always
				// label will be JP format, value will be long date - as it will be sent to OMS
				selectedDateStr = dateOptions.val(),
				allowedDates = getDateInformation(dateOptions),
				minDate = (allowedDates.length > 0) ? allowedDates[0] : null,
				maxDate = (allowedDates.length > 0) ? allowedDates[allowedDates.length - 1] : null,
				selectedDate;

			// Different container on mobile to improve the position of the calendar
			if (util.isSmallScreenSize()) {
				$this.after(container);
			} else {
				designatedDeliveryWrap.after(container);
			}

			//this is used for validation as jquery validate is not validating readonly fields
			//https://stackoverflow.com/a/26839379
			$this.on('focusin',  function() { $(this).attr('readonly', true); });
			$this.on('focusout', function() { $(this).attr('readonly', false); });

			//if field is populated on serverside parse date and set selected option
			var dateLabel =  selectedDateStr;
			if (selectedDateStr) {
				var $selectedDateOption = dateOptions.find('[value="'+selectedDateStr+'"]');
				var dateVal = $selectedDateOption.val();
				dateLabel = $selectedDateOption.text();

				dateOptions.val(dateVal);
				selectedDate = parseDate(dateVal);
			}

			let disableDate = function (date) {
				let isAvailable = false;
				for (let i = 0; i < allowedDates.length; i++) {
					if (allowedDates[i].getTime() == date.getTime()) {
						isAvailable = true;
						break;
					}
				}
				return !isAvailable;
			};

			var timeOpened = new Date();

			let selectFn = function (date) {
				let dateStr = date.getFullYear() + '-' + pad((date.getMonth() + 1)) + '-' + pad(date.getDate());

				//deselect date if same value is selected
				if (dateOptions.val() == dateStr) {
					this.setDate(null);
					dateOptions.val('');
				}
				//set new values
				else {
					dateOptions.val(dateStr);
					$this.val(dateOptions.find(':selected').text());
				}

				// callback to get the current (newly selected) date picker value (dateOptions.val) after it is being changed
				// neccessary because the on change event on date picker happens BEFORE the value is changed, causing retrieval of the previously selected date
				if (dateSelectcallback) {
					dateSelectcallback($this);
				}
			};
			new Pikaday({
				field: $this[0],
				container: container[0],
				minDate: minDate,
				maxDate: maxDate,
				defaultDate: selectedDate,
				setDefaultDate: true,
				disableDayFn: disableDate,
				onSelect: selectFn,
				format: 'YYYY-MM-DD',
				i18n: getI18nObject(),
				onOpen: function() {
					timeOpened = new Date();
				},
				onClose: function() {
					// calendar was gone and cannot open back even if user clicks on input field, it gots blocked sometimes
					// so we applied this fix, if it closes just after opening then we trigger click to reopen it
					var endTime = new Date();
					var timeDiff = endTime - timeOpened; //in ms

					if (timeDiff < 200) {
						setTimeout(function() {
							$('.js-dd-datepicker').trigger('click');
						}, 200);
					}
				}
			});

			//set init values for field
			$this.val(dateLabel);
			$this.addClass('js-datepicker-initialized');
		});
	},

	getAction: function (data) {
		if (!data) {
			return null;
		}
		// we need to check if data is array because action is sent in form of array of object
		if (!Array.isArray(data)) {
			return null;
		}

		for(var index in data) {
			var dataElement = data[index];
			if(dataElement.name == 'action') {
				return dataElement.value;
			}
		}

		return null;
	},

	formatDateOfBirth: function(e, site) {
		var inputChar = String.fromCharCode(event.keyCode);
		var code = event.keyCode;
		var allowedKeys = [8];
		if (allowedKeys.indexOf(code) !== -1) {
			return;
		}

		if (site === 'US') {
			event.target.value = event.target.value.replace(
				/^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
			).replace(
				/^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
			).replace(
				/^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
			).replace(
				/^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
			).replace(
				/^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
			).replace(
				/[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
			).replace(
				/\/\//g, '/' // Prevent entering more than 1 `/`
			);
		}
		else {
			event.target.value = event.target.value.replace(
				/^(0[0-9]|1[0-9]|2[0-9]|3[0-1])$/g, '$1/'
			).replace(
				/^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
			).replace(
				/[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
			).replace(
				/\/\//g, '/' // Prevent entering more than 1 `/`
			);
		}
	},

	// Based on month we passed, we get max number of days in the month
	daysInMonth: function(m) { // m is 1-indexed (january = 1, december = 12)
		var intMonth = parseInt(m);
		switch (intMonth) {
			case 2 :
				return 29;
			case 9 : case 4 : case 6 : case 11 :
				return 30;
			default :
				return 31
		}
	},

	// Validate birthday field
	validateDateOfBirth: function(value, element, params) {
		var format = SitePreferences.SITE_BIRTHDAY_FORMAT.split('/');
		var day;
		var month;
		var isValid = true;

		// if value is empty we just return true since its not mandatory field
		if (!value || value == '') { return isValid; }

		var date = value.split('/');

		// if value is splitted on more than 2 pieces, value of field is not valid
		if (date.length != 2) {
			return isValid = false;
		}

		// check if date format is dd/MM or MM/dd
		day = format[0] == 'dd' ? date[0] : date[1];
		month = format[0] == 'dd' ? date[1] : date[0];

		// if its empty, greater then 12 or 0 we set field to not valid
		if (month == '' || parseInt(month) > 12 || (month == '0' || month == '00')) {
			return isValid = false;
		}
		var maxDaysInMonth = util.daysInMonth(month);

		// if its empty, greater then max days in month or 0 we set field to not valid
		if (day == '' || parseInt(day) > maxDaysInMonth || (day == '0' || day == '00')) {
			return isValid = false;
		}

		// prepare date format
		if (value.length <= 4) {
			day = day.length == 1 ? '0' + day : day;
			month = month.length == 1 ? '0' + month : month;
		}
		else if (value.length > 4) {
			day = day.length > 2 && (day.charAt(0) == '0') ? day.substring(1) : day;
			month = month.length > 2 && (month.charAt(0) == '0') ? month.substring(1) : month;
		}

		// format date to match dd/MM or MM/dd based on market format
		element.value = format[0] === 'dd' ? day + '/' + month : month + '/' + day;

		return isValid;
	},

	/**
	 * Validate all inputs (input, select, textarea, button...) of a fieldset, return true only if all are valid
	 * @param {jQuery} fieldset
	 */
	validateFieldset: function (fieldset) {
		var validity = true;
		var form = fieldset.closest('form');
		if (!form || form.length === 0) {
			return;
		}
		var validator = form.validate();
		validator.prepareForm();
		fieldset.find(':input:visible').each(function(i, input) {
			if (validator.check(input) === false) {
				validity = false;
			}
		});
		return validity;
	},

	/**
	 * @function
	 * @description Calculate size of select element based on first color (cart and wishlist)
	*/
	calculateSize: function (selector) {
		var spaceToArrow = 20; // Space in Pixels between the word and arrow
		if ($(selector).length) {
			$(selector).each(function () {
				var $this = $(this);
				var text = $this.find('option:selected').text();
				// create placeholder element to calculate the size
				var $placeHolder = $('<span>').html(text).css({
					'font-size': $this.css('font-size'), // ensures same size text
					'visibility': 'hidden'// prevents default browser style
				});
				// add to body, get width, and get removed
				$placeHolder.appendTo($this.parent());
				var width = $placeHolder.width();
				$placeHolder.remove();
				// set select width
				$this.width(width + spaceToArrow);
			});
		}
	},

	/**
	* @function addHiddenInputFieldToForm
	* @description Adds the hidden input field with given value and name to the form
	*/
	addHiddenInputFieldToForm: function (formId, fieldName, fieldValue) {
		var input = $('<input>').attr('type', 'hidden').attr('name', fieldName).val(fieldValue);
		$('#' + formId).append(input);
	},

	/**
	 * @function removeHiddenInputFieldFromForm
	 * @description Removes the hidden input field with the given name from the form
	 */
	removeHiddenInputFieldFromForm: function (formId, fieldName) {
		$('#' + formId).find('[name="' + fieldName + '"]').remove();
	},


	/**
	 * This method is created to solve the problem that is being caused by filter() and find() methods.
	 * So filter() method can only get the element if it is a child of the root and find only can get the element if it is deeper in the tree.
	 * Now we always put a wrapper around the HTML to ensure the element is not one of the first level children of the root so find() will always work.
	 * @param {string} data HTML returned with an AJAX call
	 * @param {string} elementClass Class name of the element we want to find. Example: '.some-class-name'
	 */
	find: function(data, elementClass) {
		return $('<div>' + data + '</div>').find(elementClass);
	},

	/**
	 * @function
	 * @description Remember / restore scroll position
	 * * @param {string} bodyClass
	 */
	rememberScrollPosition: function(bodyClass) {
		var bodyScrollClass = '';
		if (bodyClass) {
			bodyScrollClass = bodyClass;
		}
		const scrollY = window.scrollY + 'px';
		const body = document.body;
		$('body').addClass('no-scroll ' + bodyScrollClass);
		body.style.top = `-${scrollY}`;
	},

	restoreScrollPosition: function(bodyClass) {
		var bodyScrollClass = '';
		if (bodyClass) {
			bodyScrollClass = bodyClass;
		}
		const body = document.body;
		const scrollY = body.style.top;
		$('body').removeClass('no-scroll ' + bodyScrollClass);
		body.style.top = '';
		window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
	},

	/**
	 * This method we use to generate UUID.
	 */
	generateUUID: function () {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	},

	/**
	 * Returns promise where in resolve we pass object containing latitude and longitude
	 * For latitude and longitude we check eithe browser geolocation or BE request geolocation
	 * @returns {Promise}
	 */
	getLocationCoordinates() {
		var coords = {};
		return new Promise(function (resolve, reject) {
			navigator.geolocation.getCurrentPosition(
				function (position) {
					coords.lat = position.coords.latitude;
					coords.lng = position.coords.longitude;
					resolve(coords);
				},
				function() {
					getLocationCoordinatesFromIP()
						.then(resolve, reject);
				}
			).catch(function() {
				reject();
			});
		});
	},

	/**
	 * Checks if the search query entered by customer is a valid postal code
	 * @param {String} searchString
	 * @param {String} currentCountry
	 */
	modifyPostalCodeSearch(searchString, currentCountry) {
		var zipcodeRegex = constants.regex.postal[currentCountry.toLowerCase()];
		var isValidZipcode = false;

		// if the regex is not defined for the current country
		// or the search query doesn't match the regex we return unmodified search query
		if (zipcodeRegex) {
			isValidZipcode = zipcodeRegex.test(searchString);
		}
		return isValidZipcode ? `PostalCode+${searchString}` : searchString;
	},

	closeSearch() {
		const $searchForm = $('.js-header-search .js-header-search-form');
		const $searchInput = $('.js-header-search .js-search-field');
		const $searchWrap = $('[data-js="search-wrap"]');
		const hideInstantly = !$searchForm.hasClass('u-transition');
		const TRANSITION_DURATION = 200;

		$('body').removeClass('search-is-open no-scroll');

		if (hideInstantly) {
			$searchWrap.hide();
		}
		else {
			$searchWrap.stop().slideUp();
		}

		if (!$searchInput.val() || $searchInput.val() === $searchInput.attr('placeholder')) {
			$searchForm.removeClass('with-phrase');
		}

		$('[data-js="nav-container"]').removeClass('is-search-expanded');
		$searchForm.removeClass('is-search-expanded open');

		setTimeout(() => {
			$searchForm
				.removeClass('u-transition')
				.removeAttr('style');
		}, hideInstantly ? 0 : TRANSITION_DURATION + 50);

		// Don't remove overlay if main menu was opened on mobile
		if (!$('[data-js="nav-container"]').hasClass('is-open')) {
			$('.js-overlay').fadeOut();
		}
	},

	getScrollParent() {
		if ($(document.documentElement).hasClass('has-swiper')) {
			return $('body > div[class^="pt_"]').first();
		}

		return $(window);
	},

	getNavDropdownMaxHeight() {
		const $header = $('[data-js="nav-container"]');
		const $uspBanner = $('[data-js="usp-banner"]');
		const uspBannerHeight = $uspBanner.length ? $uspBanner.css('height') : '0px';

		return `calc(var(--window-height, 100vh) - 2 * ${$header.css('marginTop')} - ${$header.css('height')} - ${uspBannerHeight})`;
	}
};

function getLocationCoordinatesFromIP() {
	var coords = {};
	return new Promise(function (resolve, reject) {
		$.ajax({
			url: window.Urls.storesGetPosition,
			type: 'POST',
			cache: true
		}).done(function (resp) {
			if (!('coords' in resp)) {
				reject();
			}
			coords.lat = resp.coords.latitude;
			coords.lng = resp.coords.longitude;
			resolve(coords);
		}).fail(function() {
			reject();
		});
	});
}

function pad(n){return n<10 ? '0'+n : n}

function getDateInformation(el) {
	let dates = [];
	$.each(el.find('option'), function () {
		let dateStr = $(this).val();
		if (dateStr) {
			var parsedDate = parseDate(dateStr);
			if (parsedDate != null) {
				dates.push(parsedDate);
			}
		}
	});
	dates.sort(function (date1, date2) {
		if (date1 > date2) return 1;
		if (date1 < date2) return -1;
		return 0;
	});
	return dates;
}

function parseDate(dateStr) {
	if (dateStr == undefined || dateStr.indexOf('-') == -1) {
		return null;
	}
	const parts = dateStr.split('-');
	const day = parseInt(parts[2], 10);
	const month = parseInt(parts[1], 10) - 1;
	const year = parseInt(parts[0], 10);
	return new Date(year, month, day);
}

function getI18nObject() {
	return {
		previousMonth : window.Resources.PREVIOUS_MONTH,
		nextMonth     : window.Resources.NEXT_MONTH,
		months        : [window.Resources.MONTH_JANUARY,window.Resources.MONTH_FEBRUARY,window.Resources.MONTH_MARCH,window.Resources.MONTH_APRIL,window.Resources.MONTH_MAY,window.Resources.MONTH_JUNE,window.Resources.MONTH_JULY,window.Resources.MONTH_AUGUST,window.Resources.MONTH_SEPTEMBER,window.Resources.MONTH_OCTOBER,window.Resources.MONTH_NOVEMBER,window.Resources.MONTH_DECEMBER],
		weekdays      : [window.Resources.WEEKDAY_SUNDAY,window.Resources.WEEKDAY_MONDAY,window.Resources.WEEKDAY_TUESDAY,window.Resources.WEEKDAY_WEDNESDAY,window.Resources.WEEKDAY_THURSDAY,window.Resources.WEEKDAY_FRIDAY,window.Resources.WEEKDAY_SATURDAY],
		weekdaysShort : [window.Resources.WEEKDAY_SUNDAY_SHORT,window.Resources.WEEKDAY_MONDAY_SHORT,window.Resources.WEEKDAY_TUESDAY_SHORT,window.Resources.WEEKDAY_WEDNESDAY_SHORT,window.Resources.WEEKDAY_THURSDAY_SHORT,window.Resources.WEEKDAY_FRIDAY_SHORT,window.Resources.WEEKDAY_SATURDAY_SHORT]
	};
}

module.exports = util;
