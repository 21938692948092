'use strict';

var _ = require('lodash'),
	imagesLoaded = require('imagesloaded'),
	ajax = require('./ajax'),
	util = require('./util'),
	keyboard = require('./constants').keyboard;

var dialog = {
	/**
	 * @function
	 * @description Appends a dialog to a given container (target)
	 * @param {Object} params  params.target can be an id selector or an jquery object
	 */
	create: function(params) {
		var $target, id;

		if (_.isString(params.target)) {
			if (params.target.charAt(0) === '#') {
				$target = $(params.target);
				// if no element found, create one
				if ($target.length === 0) {
					id = params.target.substr(1);
					$target = $('<div>').attr('id', id).addClass('dialog-content').appendTo('body');
				}
			}
			else {
				$target = $('#' + params.target);
				// if no element found, create one
				if ($target.length === 0) {
					$target = $('<div>').attr('id', params.target).addClass('dialog-content').appendTo('body');
				}
			}
		}
		else if (params.target instanceof jQuery) {
			$target = params.target;
			// if no element found, create one
			if ($target.length === 0) {
				$target = $('<div>').attr('id', $target.attr('id')).addClass('dialog-content').appendTo('body');
			}
		}
		else {
			$target = $('#dialog-container');
			// if no element found, create one
			if ($target.length === 0) {
				$target = $('<div>').attr('id', 'dialog-container').addClass('dialog-content').appendTo('body');
			}
		}

		// create the dialog
		this.$container = $target;
		this.$container.dialog(_.merge({}, this.settings, params.options || {}));
	},
	/**
	 * @function
	 * @description Opens a dialog using the given url (params.url) or html (params.html)
	 * @param {Object} params
	 * @param {Object} params.url should contain the url
	 * @param {String} params.html contains the html of the dialog content
	 * @param {Boolean} params.closeAll can have true or false values
	 */
	open: function(params) {
		// Close all opened dialogs by default or if closeAll param is explictly set as true
		if ((typeof params.closeAll === 'undefined' || params.closeAll)
			&& this.$container && this.$container.hasClass('ui-dialog-content') && this.$container.dialog('isOpen')
		) {
			this.close();
		}
		this.create(params);
		this.replace(params);

		if (!this.$container.closest('.dialog-wrap').length > 0) {
			this.$container.closest('.ui-dialog').wrapInner('<div class="dialog-wrap"></div>');
		}

		var closeButton = this.$container.closest('.dialog-wrap').find('.ui-dialog-titlebar-close');

		closeButton.off('mousedown').on('mousedown', function(e) {
			dialog.setMouseDownOnAnchor();
		});

		closeButton.off('mouseup').on('mouseup', function(e) {
			dialog.setMouseUpOnAnchor();
		});
	},
	/**
	 * @description populate the dialog with html content, then open it
	 */
	openWithContent: function(params) {
		var content, position, callback, beforeLoad;

		if (!this.$container) {
			return;
		}
		content = params.content || params.html;
		if (!content) {
			return;
		}

		beforeLoad = (typeof params.beforeLoad === 'function') ? params.beforeLoad : function() {return true;};
		if (!beforeLoad(content)) {
			return;
		}

		this.$container.empty().html(content);
		if (!this.$container.dialog('isOpen')) {
			this.$container.dialog('open');
		}

		if (params.options) {
			position = params.options.position;
		}
		if (!position) {
			position = this.settings.position;
		}
		imagesLoaded(this.$container).on('done', function() {
			this.$container.dialog('option', 'position', position);
		}.bind(this));

		callback = (typeof params.callback === 'function') ? params.callback : function() {};
		callback();
	},
	/**
	 * @description Replace the content of current dialog
	 * @param {object} params
	 * @param {string} params.url - If the url property is provided, an ajax call is performed to get the content to replace
	 * @param {string} params.html - If no url property is provided, use html provided to replace
	 */
	replace: function(params) {
		if (!this.$container) {
			return;
		}
		if (params.url) {
			params.url = util.appendParamToURL(params.url, 'format', 'ajax');

			ajax.load({
				url: params.url,
				data: params.data,
				callback: function(response) {
					params.content = response;
					this.openWithContent(params);
				}.bind(this)
			});
		}
		else if (params.html) {
			this.openWithContent(params);
		}
	},
	/**
	 * @function
	 * @description Closes the dialog
	 */
	close: function() {
		if (!this.$container) {
			return;
		}
		this.$container.dialog('close');
		dialog.setMouseUpOnAnchor();
	},
	exists: function() {
		return this.$container && (this.$container.length > 0);
	},
	isActive: function() {
		return this.exists() && (this.$container.children.length > 0);
	},
	bindOverlayClose: function(openDialog) {
		$('.ui-widget-overlay').off('mouseup').on('mouseup', function(e) {
			dialog.setMouseUpOnAnchor();
		});

		$('.ui-widget-overlay').on('click', function() { // To close the dialog if you click on the opacity layer
			if (dialog.closeFunction) {
				dialog.closeFunction();
				return;
			}
			openDialog.dialog('close');
		});
	},
	isMouseDown: false,
	setMouseDownOnAnchor: function() {
		dialog.isMouseDown = true;
	},
	setMouseUpOnAnchor: function() {
		dialog.isMouseDown = false;
	},
	isMouseDownOnAnchor: function() {
		return dialog.isMouseDown;
	},
	closeFunction: null,
	addCloseFunction: function(callback) {
		dialog.closeFunction = callback;
	},
	removeCloseFunction: function() {
		dialog.closeFunction = null;
	},
	settings: {
		autoOpen: false,
		height: 'auto',
		modal: true,
		resizable: false,
		draggable: false,
		title: '',
		beforeClose: function () {
			if (dialog.closeFunction) {
				dialog.closeFunction();
				return false;
			}
			return true;
		},
		close: function() {
			$(this).dialog('close');
			$(this).empty();
			$(this).dialog('destroy').remove();
			// we remove the class only when all dialogs are closed
			if (!$('.ui-dialog').is(':visible')) {
				$('html').removeClass('dialog-is-open');
			}
			dialog.setMouseUpOnAnchor();
		},
		open: function() {
			var openDialog = $(this);
			var closeButton = $(this).closest('.dialog-wrap').find('.ui-dialog-titlebar-close');

			closeButton.off('mousedown').on('mousedown', function(e) {
				dialog.setMouseDownOnAnchor();
			});
			closeButton.off('mouseup').on('mouseup', function(e) {
				dialog.setMouseUpOnAnchor();
			});

			closeButton.attr("aria-label",Resources.CLOSE);
			dialog.bindOverlayClose(openDialog);

			$('html').addClass('dialog-is-open');
			dialog.setMouseUpOnAnchor();
			openDialog.on('keydown', function(e) {
				if (e.which === keyboard.TAB_KEY) {
					$(this).trigger('focus');
				}
			});
		},
		position: {
			my: 'center',
			at: 'center',
			of: window,
			collision: 'flipfit'
		}
	}
};

if (SitePreferences.IS_RESKIN) {
	dialog.settings.show = {
		effect: 'blind',
		duration: 400
	};
}

module.exports = dialog;
