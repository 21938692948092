'use strict';

var forms = require('../../forms'),
	account = require('../account'),
	promotions = require('./promotions'),
	shipping = require('./shipping'),
	address = require('./address'),
	payment = require('./payment'),
	submitForm = require('./submitForm'),
	giftWrapping = require('./giftWrapping'),
	giftCard = require('./giftCard'),
	addressComplete = require('../../addresscomplete'),
	inputValidation = require('./inputvalidation'),
	detailsModalDialog = require('../../detailsmodaldialog'),
	stepIndicator = require('./stepIndicator'),
	util = require('../../util'),
	tooltip = require('../../tooltip'),
	uspBanner = require('../../uspBanner'),
	page = require('../../page'),
	contactDetails = require('./contactDetails'),
	shippingDetails = require('./shippingDetails'),
	paymentOptions = require('./paymentOptions'),
	ometriaTracker = require('../../tracking/ometriaTracking'),
	shippingdetailsoverlay = require('./shippingdetailsoverlay'),
	validator = require('./validator');

if (SitePreferences.AMAZON_PAY_ENABLED) {
	var amazonPayments = require('../../amazonPayments');
}

$(window).on('load', scrollToContainer);

function init() {
	forms.init();
	account.initCartLogin();
	stepIndicator.validation();
	detailsModalDialog.init();
	addressComplete.init();
	submitForm.init(initializeEvents);
	initStickyOrderTotals();
	initializeEvents();
	uspBanner.init();

	if (SitePreferences.AMAZON_PAY_ENABLED) {
		amazonPayments.init();
	}
}

/**
 * Initialize events for checkout page
 */
function initializeEvents() {
	//initialize checkout element events
	validator.init();
	contactDetails.init();
	shippingDetails.init();
	paymentOptions.init();
	shippingdetailsoverlay.init();

	tooltip.initializeCustomTooltip();

	inputValidation.validatePostalAndStateAfterPayPalExpress();

	pageEvents();
	promotions.init();
	shipping.init();
	address.init();
	payment.init();
	giftWrapping.init();
	giftCard.init();
}

function pageEvents() {
	$('body').off('change', '#dwfrm_checkout_billing_email_newsletterSignup')
		.on('change', '#dwfrm_checkout_billing_email_newsletterSignup', function() {
			var $privacyPolicyCheckbox = $('input[name$=_checkout_billing_email_acceptprivacypolicy]');
			if ($privacyPolicyCheckbox.length) {
				$privacyPolicyCheckbox.valid();
			}
		});

	$('body').off('click', '[data-js="header-section"]').on('click', '[data-js="header-section"]', function() {
		var sectionToToggle = $('.js-checkout-order-block');

		var isExpanded = sectionToToggle.is(':visible');

		if (isExpanded) {
			sectionToToggle.slideUp();
			$(this).removeClass('expanded');
		}
		else {
			sectionToToggle.slideDown();
			$(this).addClass('expanded');
		}
	});

	// Attaching events for show all item links in mini cart
	$('body').off('click', '[data-js="show-all-items"]').on('click', '[data-js="show-all-items"]', function(e) {
		e.preventDefault();
		var element = $('.mini-cart-products');
		var isCollapsed = element.hasClass('is-collapsed');
		var shoppingBag = $('[data-js="checkout-bag-block-inner"]');
		var shoppingBagOffset = shoppingBag.offset();

		if (isCollapsed) {
			element.removeClass('is-collapsed');
			element.addClass('is-expanded');
		}
		else {
			element.removeClass('is-expanded');
			element.addClass('is-collapsed');
		}

		// For mobile devices we need to use js scrollTo instead of jQuery
		if (!util.isTabletAndAbove() && shoppingBagOffset) {
			window.scrollTo({
				top: shoppingBagOffset.top,
				left: shoppingBagOffset.left,
				behavior: 'smooth'
			});
		}

		// For desktop we need to use jQuery
		if (util.isTabletAndAbove()) {
			util.scrollBrowser(shoppingBag.position().top);
		}
	});

	//to prevent multiple submissions of the form when removing a product from the cart
	var removeItemEvent = false;
	$(document).off('click', 'button[name$="deleteProduct"]').on('click', 'button[name$="deleteProduct"]', function (e) {
		e.preventDefault();

		if (removeItemEvent) {
			return;
		}

		removeItemEvent = true;

		var $btn = $(this);
		var paramName = $btn.attr('name');

		var options = {
			responseType: 'json',
			action: 'deleteProduct',
			extraParameters: [{ name: paramName, value: paramName }],
			keepLoaderOpen: true,
			callback: function(response) {
				if (response) {
					var responseData = JSON.parse(response);
					if (responseData && responseData.type == 'RedirectToCart') {
						location.href = Urls.cartShow;
						return;
					}
					// update basket when product is removed
					ometriaTracker.ometriaTrackBasket();
				}

				// we return json response and we just refresh the page on callback
				// if the cart is empty, the back end validation will then trigger redirect to cart page
				page.refresh();
			}
		};

		submitForm.submitAjaxForm(options);
	});
}

/**
 * Function  related with header that move the browser for a specific point
 */
function scrollToContainer() {
	var container = location.search.split('container=')[1] ? location.search.split('container=')[1].split('&')[0] : null;
	if (container) {
		var headerHeight = $('.header').height();
		$('html, body').animate({
			scrollTop: ($('.' + container).offset().top - headerHeight)
		}, 500);
	}
}

/**
 * Function to make the sidebar in the checkout sticky
 *(IE 11 Only, the other browsers we use the native css: position:sticky )
 *
 */
function initStickyOrderTotals() {
	var isIE11 = '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style;

	function adjustWidth() {
		var parentwidth = $('.checkout-order-block').width();
		$('.checkout-bag').width(parentwidth);
	}

	if (!util.isMobile() && $(window).width() > 1024 && isIE11) {
		$(function() {
			var top = $('.js-checkout-bag').offset().top - parseFloat($('.js-checkout-bag').css('marginTop').replace(/auto/, 0));

			$(window).on('scroll', function() {
				util.stickyOrderTotalsCalculation(top);
				adjustWidth();
			}).on('resize', function () {
				util.stickyOrderTotalsCalculation(top);
				adjustWidth();
			});
		});
	}
}

exports.init = init;