'use strict';

var util = require('../../util');
var accessibility = (util.isMobile()) ? require('../../accessibilityForMobile') : require('../../accessibility');
var keyboard = require('../../constants').keyboard;

const DATA_ATTR_CLASS = 'class';

var shippingdetailsoverlay = {
	init: function (dialogClass) {
		this.$el = $('[data-js="shipping-details-overlay"]');
		if (dialogClass) {
			this.$el.addClass(dialogClass);
			this.$el.data(DATA_ATTR_CLASS, dialogClass);
		}
		this.$content = this.$el.find('.js-checkout-delivery-details');
		this.$close = this.$el.find('[data-js="close-shipping-details"]');
		this.$overlay = $('[data-js="checkout-overlay"]');
		this.$close.off('keydown click').on('keydown click', function (e) {
			// enter keypress
			if (e.keyCode === keyboard.ENTER_KEY || e.type === 'click') {
				e.stopPropagation();
				this.close();
			}
		}.bind(this));

		this.$overlay.off('click').on('click', function () {
			if ($('[data-js="map"]').html()) {
				$('[data-js="map"]').html('');
			}
			this.close();
		}.bind(this));
	},

	/**
     * @function
     * @description Shows the given content in the shipping details overlay
     */
	show: function (dialogClass) {
		this.init(dialogClass);
		this.slide();
	},

	/**
     * @function
     * @description Slides down and show the contents of the shipping details
     */
	slide: function () {
		if (!this.$el.hasClass('opened')) {
			if (this.$el.not(':visible')) {
				this.$el.addClass('opened');
				this.$overlay.fadeIn();
			}
			util.rememberScrollPosition('checkout-flyout-open');
			accessibility.focusTrap(this.$el, false);
		}
	},

	/**
     * @function
     * @description Closes the shipping details overlay
     */
	close: function () {
		if ($('[data-js="map"]').html()) {
			$('[data-js="map"]').html('');
		}
		this.$el.removeClass('opened');
		this.$overlay.fadeOut({
			complete: () => {
				if (this.$el.data(DATA_ATTR_CLASS)) {
					this.$el.removeClass(this.$el.data(DATA_ATTR_CLASS));
					this.$el.removeData(DATA_ATTR_CLASS);
				}
			}
		});
		util.restoreScrollPosition('checkout-flyout-open');
	},
};

module.exports = shippingdetailsoverlay;
