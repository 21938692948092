var validator = require('./validator'),
	progress = require('../../progress'),
	checkout = require('./checkout'),
	applepay = require('./applepay');

var adyenCheckout = null;

if (SitePreferences.ADYEN_ENABLED) {
	adyenCheckout = require('../../adyen-checkout');
}

/**
 * Initializes all events for the payment options.
 */
function initializeEvents() {
	applepay.init();

	$('body').off('click', 'button.js-checkout-btn').on('click', 'button.js-checkout-btn', function(e) {
		e.preventDefault();

		var isFormValid = validator.validateForPlaceOrder();
		if (!isFormValid) {
			return;
		}

		progress.show();
		checkout.placeOrder();
	});

	// stand alone payment page with Adyen Form
	$('body').off('click', 'button.js-payment-btn').on('click', 'button.js-payment-btn', function(e) {
		e.preventDefault();

		var selectedPaymentMethod = $('.js-selected-paymentmethod').val();
		var paymentValuesValid = true;

		if (selectedPaymentMethod === 'CREDIT_CARD') {
			paymentValuesValid = adyenCheckout.adyenValidation();
		}

		var isBillingValid = true;
		var billingDetailsFields = $('.js-billing-form input, select');

		if (billingDetailsFields.length > 0) {
			isBillingValid = validator.validateSection(billingDetailsFields);
		}

		if (!paymentValuesValid || !isBillingValid) {
			return;
		}

		// TODO: do we show PP checkbox and billing form
		/*var isFormValid = validator.validateForPlaceOrder();
		if (!isFormValid) {
			return;
		}*/

		progress.show();

		$('#dwfrm_checkout').submit();
		$('button.js-payment-btn').attr('disabled', 'disabled');
	});
}

exports.init = function () {
	initializeEvents();
	checkout.init();
};