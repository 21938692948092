'use strict';

var util = require('./util'),
	ajax = require('./ajax');

function initEmailCheckEvents() {
	var emailInputSelector = '[data-email-check="account-registration"] input[name="dwfrm_profile_customer_email"]';

	var isModal = $('[data-email-check="account-registration"]').hasClass('js-modal-window');

	// checks if the email entered is already registered with an account
	$(document).off('focusout').on('focusout', emailInputSelector, function() {
		$('[data-js="already-registered-msg"]').remove();

		if ($(emailInputSelector).length === 0 || !$(emailInputSelector).valid()) {
			return;
		}

		var data = $(emailInputSelector).serializeArray();

		ajax.load({
			url: Urls.checkEmail,
			type: 'post',
			data: data,
			callback: function(response) {
				if (util.find(response, emailInputSelector).length && util.find(response, emailInputSelector).hasClass('error')) {
					$(emailInputSelector).closest('.input-container')
						.append('<div class="validation-message" data-js="already-registered-msg">'
							+ '<div class="validation-message__wrapper"> <span class="validation-message__txt">'
							+ Resources.ALREADY_REGISTERED
							+ '</span><a class="validation-message__link link-is-underline ' + (isModal ? 'js-login-popup' : '') + '" href="' + Urls.logIn + '">'
							+ Resources.SIGN_IN_TO_EXISTING
							+ '</a></div></div>');

					if (isModal) {
						$('[data-email-check="account-registration"]').trigger('bindloginlinkevent');
					}
				}
			}
		});
	});
}

function initDateOfBirthEvents() {
	var element = '#dwfrm_profile_customer_dateofbirth';
	$('body').off('change keyup', element).on('change keyup', element, function(e) {
		var locale = window.SitePreferences.LOCALE;
		var site = locale ? locale.slice(-2) : '';
		util.formatDateOfBirth(e, site);
	});
}

module.exports = {
	init: function() {
		initEmailCheckEvents();
		initDateOfBirthEvents();
	}
};
