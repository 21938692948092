'use strict';

var util = require('./util.js');
var accessibility = (util.isMobile()) ? require('./accessibilityForMobile') : require('./accessibility');
var dialog = require('./dialog');


/**
 * @function
 * @description  After Payment Widget (PDP)
 */
function initializePaymentWidgetAfterpay() {
	$('body').on('click', '[data-js="js-afterpay-more-info"]', function (e) {
		e.preventDefault();
		var afterPayInfo = util.isSmallScreenSize() ? $('.afterpay-more-info-containter.mobile') : $('.afterpay-more-info-containter.desktop');
		var keyboardNavActive = $(this).hasClass('focus-visible');
		dialog.open({
			html: afterPayInfo.html(),
			closeAll: false,
			options: {
				dialogClass: 'afterpay-info-content-dialog'
			},
			callback: function () {
				accessibility.setKeyboardNavigation(keyboardNavActive);
				accessibility.attachDialogEvents();
				accessibility.attachAfterPayDialogEvents();
			}
		});
	});
}

/**
 * @function
 * @description  Zippay Widget (PDP)
 */
function initializePaymentWidgetZippay() {
	$('body').on('click', '[data-js="js-zippay-more-info"]', function (e) {
		e.preventDefault();
		var zipPayInfo = util.isSmallScreenSize() ? $('.zippay-more-info-containter.mobile') : $('.zippay-more-info-containter.desktop');
		var keyboardNavActive = $(this).hasClass('focus-visible');
		dialog.open({
			html: zipPayInfo.html(),
			closeAll: false,
			options: {
				dialogClass: 'zippay-info-content-dialog'
			},
			callback: function () {
				accessibility.setKeyboardNavigation(keyboardNavActive);
				accessibility.attachDialogEvents();
				accessibility.attachZipPayDialogEvents();
			}
		});
	});
}

exports.init = function () {
	initializePaymentWidgetAfterpay();
	initializePaymentWidgetZippay();
};
