/**
 * Constants used for FE
 */
var naPhone = /^\(?([2-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/;

module.exports = {
	//keyboard accessibility
	keyboard: {
		TAB_KEY: 9,
		ENTER_KEY: 13,
		ESC_KEY: 27,
		SPACE_KEY: 32,
		ARROW_LEFT_KEY: 37,
		ARROW_RIGHT_KEY: 39
	},
	//regex values
	regex: {
		phone: {
			US: naPhone,
			CA: naPhone,
			JP: /^(0\d{1,4}- ?)?\d{1,4}-\d{4}$/,
			RU: /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
		},
		postal: {
			US: /^\d{5}(-\d{4})?$/,
			CA: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
			JP: /^([0-9]){3}[-]{0,1}([0-9]){4}$/,
			AU: /^(0[289][0-9]{2})$|^([1345689][0-9]{3})$|^(2[0-8][0-9]{2})$|^(290[0-9])$|^(291[0-4])$|^(7[0-4][0-9]{2})$|^(7[8-9][0-9]{2})$/,
			RU: /^([0-9]){6}$/
		},
		NOT_CC: /^(?!(([0-9 -]){13,19})).*$/
	},
	//OMNI map
	storeAvailabilityCheckType: {
		STOCK: 0,
		CLICK_AND_COLLECT: 1,
		CLICK_AND_RESERVE: 2,
		PDP_RESERVATION: 3
	}
};
