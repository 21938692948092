'use strict';

/**
 * @function
 * @description  Quantity selector on Cart page
 */
function updateQty() {
	var qtySelector = $('[data-js="cart-qty-input"]');
	var qtyMinus = $('[data-js="cart-qty-minus"]');
	var qtyPlus = $('[data-js="cart-qty-plus"]');

	qtyMinus.on('click', function() {
		var qty = Number(qtySelector.val());
		if (qty > 1) {
			qtySelector.val(qty - 1);
		}
	});

	qtyPlus.on('click', function() {
		var qty = Number(qtySelector.val());
		var maxQty = parseInt(qtySelector.attr('max'), 10);
		if (qty < maxQty) {
			qtySelector.val(qty + 1);
		}
	});

	qtySelector.on('keyup', function() {
		var maxQty = parseInt(qtySelector.attr('max'), 10);
		var qty = Number(qtySelector.val());
		if (qty > maxQty) {
			qtySelector.val(maxQty).trigger('change').trigger('focusin').trigger('focusout');
		}
		if (qty < 1) {
			qtySelector.val(1).trigger('change').trigger('focusin').trigger('focusout');
		}
	});
}


exports.init = function () {
	updateQty();
};

exports.updateQty = updateQty;
