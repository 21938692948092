'use strict';

var ajax = require('../../ajax'),
	util = require('../../util'),
	shipping = require('./shipping'),
	progress = require('../../progress'),
	promotions = require('./promotions'),
	forms = require('../../forms'),
	shippingdetailsoverlay = require('./shippingdetailsoverlay'),
	accessibility = (util.isMobile()) ? require('../../accessibilityForMobile') : require('../../accessibility');

var markerList = {}; // Sote list of markers so that it can be used for the "view on map" links in the list
var mapObject, bounds;

/**
 * Initialize pickup point map with detail section. In case that we already have opened map we are not refreshing it
 * @param {Boolean} isPickupPointMapOpened
 */
function handlePickupPoint(isPickupPointMapOpened) {
	var $useAsBillingAddress = $('input[id$="_useAsBillingAddress"]');

	if ($('.js-shipping-method-option.is-selected').hasClass('pickup-point-provider')) {
		//add class to div around shipping address so that we know by a reload that a pickuppoint shipping method was selected
		$('body').addClass('js-pickuppoint-checked');
		if ($useAsBillingAddress.prop('checked')) {
			$useAsBillingAddress.prop('checked', false).change();
		}

		$('.js-pickuppoint-section').show();
		$('.js-pickuppoints-error').hide();

		initStoresViewSwitcher();

		if ($('[data-js="pickup-point-buttons-wrapper"]').is(':visible')) {
			if ($('[data-js="store-is-selected"]').hasClass('is-selected')) {
				$('[data-js="map-wrapper"]').hide();
				$('.js-pickuppoints-list').show();
			}
			else {
				$('[data-js="map-wrapper"]').show();
				$('.js-pickuppoints-list').hide();
			}
		}

		//in case that we already have pickupPoint map opened we don't need to reload it
		if (!isPickupPointMapOpened) {
			//We hide the part with the map and list until the user submited the form
			$('.js-pickuppoints-selection').hide();

			initGetPickupPoints();
			accessibility.attachPickupPointEvents();
			var prefilledPostalCode = sessionStorage.getItem('pickuppoint-postal');
			if (prefilledPostalCode) {
				$('.js-pickuppoint-postal').val(prefilledPostalCode);
				$('.js-display-pickuppoints').trigger('click');
			}
		}else{
			var resultsHtml = '';
			var list = $('#pickuppoint-stores');
			//Build list
			for (var p in list) {
				var officeId = list[p]['office-id'];
				if($('#pickuppoint-officeID').val() === officeId){
					resultsHtml = getPickupPointDetail(list[p]) + resultsHtml;
					continue;
				}
				resultsHtml += getPickupPointDetail(list[p]);
			}

			$('.js-pickuppoints-list').html(resultsHtml);
		}
	}
	else if ($('body').hasClass('js-pickuppoint-checked')) {
		//if previous method was a pickuppoint shipping method then we have to reset the shipping addres form
		$('body').removeClass('js-pickuppoint-checked');

		clearShippingAddressForm();

		if (!$useAsBillingAddress.prop('checked')) {
			$('input[id$="_useAsBillingAddress"]').prop('checked', true).change();
		}
		$('.js-shipping-title').show();
		$('.js-shipping-form').show();
	}
}

function initGetPickupPoints() {
	$('.js-display-pickuppoints').off('click').on('click', function() {
		var fieldSet = $('.js-pickuppoint-form');

		// validate the zipcode first and only when valid display the result
		if (util.validateFieldset(fieldSet)) {
			displayPickupPoints($('.js-pickuppoint-postal').val());

			// fix to be able to properly validate zipcode field when it is automatically prefilled
			setTimeout(function() {
				var inputElement = fieldSet.find('.js-pickuppoint-postal');
				var container = inputElement.closest('.input-container');

				container.addClass('is-filled');
				container.addClass('is-valid');
				forms.checkInputFields();
				fieldSet.closest('form').valid();
				inputElement.valid();
			}, 300);
		}
	});
}

/**
 * Click events for the buttons to show the map or stores list
 */
function initStoresViewSwitcher() {
	$('[data-js="map-is-selected"]').off('click').on('click', showStoresMap);
	$('[data-js="store-is-selected"]').off('click').on('click', showStoresList);
}

function showStoresMap() {
	$('[data-js="map-is-selected"]').addClass('is-selected');
	$('[data-js="store-is-selected"]').removeClass('is-selected');
	$('.js-shipping-title').addClass('is-selected');
	$('.js-pickuppoints-list').hide();
	$('[data-js="map-wrapper"]').show();
	mapObject.fitBounds(bounds);
	mapObject.panToBounds(bounds);
}

function showStoresList() {
	$('[data-js="store-is-selected"]').addClass('is-selected');
	$('[data-js="map-is-selected"]').removeClass('is-selected');
	$('.js-shipping-title').addClass('is-selected');
	$('.js-pickuppoints-list').show();
	$('[data-js="map-wrapper"]').hide();
	$('[data-js="pickup-point-map-details"]').hide();
	$('[data-js="pickup-point-map-details"]').html('');
}

/**
 * Display the pickpoint in the google map
 */
function displayPickupPoints(postalCode) {
	$.ajax({
		url: Urls.getPickupPoints,
		data: { 'postalCode': postalCode },
		type: 'GET',
		success: function (data) {
			if (data.status == 'OK' && data.list.length > 0) {
				sessionStorage.setItem('pickuppoint-postal', postalCode);
				$('.js-pickuppoints-error').hide();
				$('.js-pickuppoints-selection').show();
				//Display map
				initGoogleMaps(data.list);

				//Display list
				var resultsHtml = '';
				var numberOfResults = data.list.length;
				if (numberOfResults === 1) {
					$('[data-js="number-results"]').html(Resources.PICKUPPOINT_SINGLERESULT);
				}
				else {
					$('[data-js="number-results"]').html(Resources.PICKUPPOINT_NUMBEROFRESULTS.replace('{0}', numberOfResults));
				}
				for (var p in data.list) {
					//Build list
					var officeId = data.list[p]['office-id'];
					if($('#pickuppoint-officeID').val() === officeId){
						resultsHtml = getPickupPointDetail(data.list[p]) + resultsHtml;
						continue;
					}
					resultsHtml += getPickupPointDetail(data.list[p]);
				}

				$('[data-js="pickup-points-search-wrapper"]').addClass('search-with-results');

				$('.js-pickuppoints-list').html(resultsHtml);
				initPickupPointListActions();

				accessibility.setFocusOnFirstPickupPoint();
			}
			else {
				showError(Resources.PICKUPPOINT_NORESULT);
				sessionStorage.setItem('pickuppoint-postal', '');
			}
		},
		error: function(error) {
			showError(Resources.PICKUPPOINT_SERVICEERROR);
		}
	});
}

/**
 * @description Pickpoints Error message
 */
function showError(message) {
	$('.js-pickuppoints-selection').hide();
	$('.js-pickuppoints-error').html('<div class="error-form"><p>' + message + '</p></div>');
	$('.js-pickuppoints-error').show();
}

/**
 * @description Add the Pickpoints Shop details with Html
 */
function getPickupPointDetail(item) {
	var selectedOfficeId = $('input#pickuppoint-officeID').val();
	var isSelected = selectedOfficeId === item['office-id'];
	var buttonText = isSelected ? Resources.PICKUPPOINT_SELECTEDLINK : Resources.PICKUPPOINT_SELECTLINK;
	var address = item.address['office-address'] + ', ' + item.address.city + ', ' + item.address.province + ', ' + item.address['postal-code'];

	var buttonHtml = '<button type="button" class="btn is-light-gray" data-js="select-pickuppoint" data-officeid="' + item['office-id'] + '" data-address="' + item.address['office-address'] +
		'" data-city="' + item.address.city + '" data-statecode="' + item.address.province + '" data-postalcode="' + item.address['postal-code'] + '" data-postofficename="' + item.name + '">' + buttonText + '</button>';

	var pointHtml = '<div data-officeid="' + item['office-id'] + '" data-js="pickuppoint-row" class="pickuppoint-row' + (isSelected ? ' is-selected' : '') + '"><div class="post-office-pickuppoint">' +
			'<div class="pickuppoint-distance">' + item.distance + ' <span class="distance-value">' + Resources.PICKUPPOINT_DISTANCEUNIT + '</span></div>' +
			'<h4 class="pickuppoint-name">' + item.name + '</h4>' +
			'<p class="pickuppoint-address">' + address + '</p>' +
			'<div class="pickuppoint-selection">' +
			buttonHtml + '</div></div></div>';

	return pointHtml;
}

/**
 * @description Show the list of stores
 */
function initPickupPointListActions() {
	// Select pickuppoint
	$('[data-js="select-pickuppoint"]').on('click', function() {
		var selectedItem = $(this);
		var data = {
			officeId: selectedItem.data('officeid'),
			address: selectedItem.data('address'),
			city: selectedItem.data('city'),
			state: selectedItem.data('statecode'),
			postalcode: selectedItem.data('postalcode'),
			officename: selectedItem.data('postofficename')
		};
		if (window.google !== undefined) {
			google.maps.event.trigger(markerList[selectedItem.val()], 'click');
		}
		setPickupPointData(data);
	});

	$('[data-js="pickup-point-map-details"]').hide();
	$('[data-js="pickup-point-map-details"]').html('');

	var isMobile = $('[data-js="pickup-point-buttons-wrapper"]').is(':visible');
	if (!isMobile) {
		// Show on map
		$('[data-js="pickuppoint-row"]').off('click mouseover').on('click mouseover', function(e) {
			e.preventDefault();
			if (window.google !== undefined) {
				google.maps.event.trigger(markerList[$(this).data('officeid')], 'desktop.click');
			}
			$('[data-js="pickuppoint-row"]').removeClass('is-active');
			$(this).addClass('is-active');
		});

		$('[data-js="pickuppoint-row"]').off('mouseout').on('mouseout', function(e) {
			e.preventDefault();
			$('[data-js="pickuppoint-row"]').removeClass('is-active');
		});
	}
}

/**
 * @description Call to Initialize the Google maps
 */
function initGoogleMaps(items) {
	var locale = $('#js-currentlocale').val().split('_')[0];

	//We want to load the google maps js only one time
	if (window.google === undefined) {
		$.getScript('//maps.googleapis.com/maps/api/js?key=' + SitePreferences.GOOGLEMAP_APIKEY + '&libraries=geometry&language=' + locale,
			function () {
				loadMap(items);
			});
	}
	else {
		loadMap(items);
	}

}

/**
 * @description Load Map points
 */
function loadMap(items) {
	var mapElement = $('[data-js="map"]')[0]; //[0] is needed to get the DOM object otherwise google maps will not work

	/** var mapLat = $('#js-googlemapstores').data('lat');
		var mapLong = $('#js-googlemapstores').data('long');
		var mapZoom = $('#js-googlemapstores').data('zoom');*/
	// initialize map
	var mapCenter = {
		lat: parseFloat(items[0].address.latitude),
		lng: parseFloat(items[0].address.longitude)
	};

	var map = new google.maps.Map(mapElement, {
		center: mapCenter,
		scrollwheel: true,
		zoom: 12,
		minZoom: 3
	});
	mapObject = map;

	addMarkersOnMap(items, map);
}

function resetMarkerIcons() {
	$.each(markerList, function (i, marker) {
		marker.setIcon({
			url: window.Urls.staticPath + 'img/mixed/icon-shop-default.png',
			scaledSize: new google.maps.Size(15, 20.5)
		});
	});
}

/**
 * @description Settings to display the Markers on the map
 */
function addMarkersOnMap(items, map) {
	var isMobile = $('[data-js="pickup-point-buttons-wrapper"]').is(':visible');
	var iconUrl = window.Urls.staticPath + 'img/mixed/icon-shop-default.png';
	bounds = new google.maps.LatLngBounds();

	$.each(items, function (i, item) {
		var markerPosition = new google.maps.LatLng(item.address.latitude, item.address.longitude);

		var marker = new google.maps.Marker({
			position: markerPosition,
			map: map,
			icon: iconUrl,
			title: item.name
		});

		if (!isMobile) {
			marker.setIcon({
				url: iconUrl,
				scaledSize: new google.maps.Size(15, 20.5)
			});
		}

		bounds.extend(marker.position);
		map.fitBounds(bounds);
		map.panToBounds(bounds);

		// fired when a pickup point cliked on the list of desktop
		google.maps.event.addListener(marker, 'desktop.click', function () {
			resetMarkerIcons();

			marker.setIcon({
				url: iconUrl,
				scaledSize: new google.maps.Size(30, 41)
			});

			//zoom and center to the marker
			map.setZoom(10);
			map.setCenter(marker.getPosition());
		});

		// the actual click on a marker
		google.maps.event.addListener(marker, 'click', function () {
			if (isMobile) {
				var selectedOfficeId = $('#pickuppoint-officeID').val();
				var isSelected = selectedOfficeId === item['office-id'];
				var buttonText = isSelected ? Resources.PICKUPPOINT_SELECTEDLINK : Resources.PICKUPPOINT_SELECTLINK;
				var html = '<div class="close" data-js="close-detail"></div></div><div class="map-point-name">' + item.name + '</div>'
					+ '<button type="button" class="btn is-light-gray ' + (isSelected ? 'is-selected' : '') + '" data-js="map-select-pickuppoint" data-officeid="' + item['office-id'] + '">' + buttonText + '</button>';

				$('[data-js="pickup-point-map-details"]').html(html);
				$('[data-js="pickup-point-map-details"]').show();
				$('[data-js="map-select-pickuppoint"]').off('click').on('click', function(e) {
					e.preventDefault();
					selectPickupPointViaMap($(this).data('officeid'));
				});

				$('[data-js="close-detail"]').off('click').on('click', function(e) {
					e.preventDefault();
					$('[data-js="pickup-point-map-details"]').hide();
					$('[data-js="pickup-point-map-details"]').html('');
				});

				// zoom and center to the marker
				map.setZoom(15);
				map.setCenter(marker.getPosition());
			}
			else {
				resetMarkerIcons();

				marker.setIcon({
					url: window.Urls.staticPath + 'img/mixed/icon-shop-default.png',
					scaledSize: new google.maps.Size(30, 41)
				});

				// scroll to point in the list
				var pointToScroll = $('[data-officeid="' + item['office-id'] + '"]');
				if (pointToScroll && pointToScroll.length > 0) {
					$('[data-js="pickuppoint-row"]').removeClass('is-active');
					pointToScroll.addClass('is-active');
					$('.js-pickuppoints-list')[0].scrollTop = pointToScroll[0].offsetTop - 30;
				}
			}
		});

		markerList[item['office-id']] = marker;
	});
}

/**
 * @description Select the pickpoints inside the map
 */
function selectPickupPointViaMap(pickuppointId) {
	$('[data-js="select-pickuppoint"][data-officeid="' + pickuppointId + '"]').trigger('click');
}

/**
 * @description Clear information on the shipping Address
 */
function setPickupPointData(data) {
	var officearray = data.officename.split(' ');
	var firstname = data.officename; // default firstname
	var lastname = data.officename; // default lastname

	//Getting a more 'logical' first- and lastname for the selected point
	if (officearray.length > 1) {
		lastname = officearray.splice(officearray.length - 1, 1).toString(); // remove last element from the array
		firstname = officearray.join(' ');
	}
	// Deselecting pickup point store
	if($('#pickuppoint-officeID').val() == data.officeId){
		$('#pickuppoint-officeID').val('');
		data = {};
	}else{
		$('#pickuppoint-officeID').val(data.officeId);
		var countryField = $('.js-country-for-phone');
		data.countryCode = countryField.val();
		data.firstname = firstname;
		data.lastname = lastname;
	}
	
	savePickupPoint(data);
}

/**
 * @description Clear information on the shipping Address
 */
function clearShippingAddressForm() {
	$('.js-shipping-form').find('input:not([type=hidden])').val('');
	$('.js-shipping-form').find('select').each(function () {
		var $this = $(this);

		$this.val($this.find('option:first').val());
	});
}

/**
 * @description Function sends ajax call to save pick up point to shipping address
 */
function savePickupPoint(reqData) {
	progress.show();
	reqData.action = 'savePickupPoint';

	ajax.load({
		url: Urls.checkoutSubmitForm,
		data: reqData,
		callback: function(data) {
			progress.hide();
			var $data = $(data);

			//order totals
			$('.order-totals-table').replaceWith($data.find('.order-totals-table'));
			$('.shopping-info-total').replaceWith($data.find('.shopping-info-total'));
			$('.js-checkout-method').replaceWith($data.find('.js-checkout-method'));

			promotions.init();
			shipping.init();
			shippingdetailsoverlay.close();
		}
	});
	
}

exports.handlePickupPoint = handlePickupPoint;
