var pickupPoint = require('./pickupPoint'),
	pickupInStore = require('./pickupInStore'),
	designatedDelivery = require('./designatedDelivery'),
	giftCard = require('./giftCard'),
	submitForm = require('./submitForm'),
	dialog = require('../../dialog'),
	util = require('../../util'),
	shippingdetailsoverlay = require('./shippingdetailsoverlay'),
	accessibility = (util.isMobile()) ? require('../../accessibilityForMobile') : require('../../accessibility');

var market = SitePreferences.LOCALE ? SitePreferences.LOCALE.substring(3) : '';

function showAmazonPayPopup(dialogContainer, selectedShippingMethod) {
	if (dialogContainer) {
		dialog.open({
			html: dialogContainer.html(),
			options: {
				dialogClass: 'amazonpay-dialog'
			},
			callback: function() {
				$('[data-js="continue-with-amazonpay"]').on('click', function(e) {
					e.preventDefault();
					dialog.close();
				});
				$('[data-js="continue-with-clickandreserve"]').off('click').on('click', function(e) {
					e.preventDefault();
					updateShippingValue(selectedShippingMethod, false, '.js-checkout-delivery-options > div.is-selected', function() {
						dialog.close();
					}, false);
				});
			}
		});
	}
}

/**
 * Events regarding shipping/delivery options
 */
function initializeEvents() {
	// shipping options click handler
	var shippingOptionsElement = $('.js-checkout-delivery-options > div');
	if (SitePreferences.IS_RESKIN) {
		shippingOptionsElement = $('.js-checkout-delivery-options input[type=radio]');
	}
	shippingOptionsElement.off('click').on('click', function (e) {
		var target = $(e.target);

		// if the actual clicked item on the delkivery option area is a tooltip element then skip making the delivery option as selected
		if ((target.attr('data-js') && target.attr('data-js') === 'tooltip-icon') || target.hasClass('js-tooltip-content') || target.parents('.js-tooltip-content').length > 0) {
			return;
		}

		var $this = $(this);
		if ($this.hasClass('is-selected')) { return; }

		accessibility.setKeyboardNavigation($(this).hasClass('focus-visible'));

		var selectedShippingMethod = $this.data('shippingmethodid');
		if (selectedShippingMethod) {
			var isOvernightShippingMethod = $this.data('overnight');
			var isClickAndReserveMethod = $this.hasClass('js-click-and-reserve');

			if (isClickAndReserveMethod) {
				var amazonPopupContainer = $this.find('[data-js="checkout-amazon-alert"]');
				if (amazonPopupContainer.length > 0) {
					showAmazonPayPopup(amazonPopupContainer, selectedShippingMethod);
					return;
				}
			}

			var isPickUpPointOption = $this.hasClass('js-pickup-point-delivery-option');
			var wasPickupPointOption = $('.js-checkout-delivery-options > div.is-selected').hasClass('js-pickup-point-delivery-option');

			updateShippingValue(selectedShippingMethod, isOvernightShippingMethod, '.js-checkout-delivery-options > div.is-selected', function() {
				if (isPickUpPointOption) {
					if ($('[data-js="pickup-point-shipping-methods-wrapper"]').hasClass('js-single-method')) {
						var isPickupPointMapOpened = !!$('[data-js="map"]').length && !!$('[data-js="map"]').html().length;
						showPickUpPointSearch(isPickupPointMapOpened);
					}

					shippingdetailsoverlay.show();
				}
			}, isPickUpPointOption && wasPickupPointOption);
		}
	});

	$('.js-open-shippingdetails').off('click keydown').on('click keydown', function(e) {
		let dialogClass;
		if (e.keyCode === 13 || e.type === 'click') {
			if ($(this).hasClass('js-select-store')) {
				$('.js-open-storeselector').trigger('click');
				var previousSearch = util.readCookie('previous-store-search');
				pickupInStore.getGeolocationAndPerformSearch(previousSearch);
			}
			else if ($(this).hasClass('js-pickup-point-method-menu')) {
				// make current one selected
				var currentMethodId = $(this).closest('.selected-option-wrapper').data('shippingmethodid');

				if (currentMethodId) {
					var container = $('[data-js="pickuppoint-methods-container"]');
					container.find('.js-pickup-point-method').removeClass('is-selected');
					container.find('[data-shippingmethodid="' + currentMethodId + '"]').addClass('is-selected');

					$('[data-js="shipping-details-flyout-title"]').text(Resources.PICKUPPOINT_OPTIONS_TITLE);
					$('[data-js="pickup-point-shipping-methods-wrapper"]').show();
					$('[data-js="pickup-points-search-wrapper"]').hide();
				}
			}
			else if ($(this).hasClass('js-select-pickup-point')) {
				var isPickupPointMapOpened = !!$('[data-js="map"]').length && !!$('[data-js="map"]').html().length;
				showPickUpPointSearch(isPickupPointMapOpened);
			}
			else if ($(this).hasClass('js-change-delivery-option')) {
				dialogClass = 'is-delivery-options';
			}

			shippingdetailsoverlay.show(dialogClass);
		}
	});

	$('[data-js="save-pickuppoint-options"]').off('click keydown').on('click keydown', function(e) {
		if (e.keyCode === 13 || e.type === 'click') {
			var $this = $(this);

			var activeShippingMethod = $this.data('active-method');
			var container = $('[data-js="pickuppoint-methods-container"]');
			var selectedShippingMethod = container.find('.js-pickup-point-method.is-selected').data('shippingmethodid');

			if (selectedShippingMethod) {
				if (activeShippingMethod === selectedShippingMethod) {
					var isPickupPointMapOpened = !!$('[data-js="map"]').length && !!$('[data-js="map"]').html().length;
					showPickUpPointSearch(isPickupPointMapOpened);
				}
				else {
					updateShippingValue(selectedShippingMethod, false, '.js-checkout-delivery-options > div.is-selected', function() {
						showPickUpPointSearch(false);
					}, true);
				}
			}
		}
	});

	// update shipping method
	$('.js-shipping-method-option').off('click').on('click', function(e) {
		var $this = $(this);
		if ($this.hasClass('is-selected')) { return; }

		if (!$this.hasClass('js-pickup-point-method')) {
			shippingdetailsoverlay.close();
		}

		accessibility.setKeyboardNavigation($this.hasClass('focus-visible'));
		var selectedShippingMethod = $this.data('shippingmethodid');
		var isOvernightShippingMethod = $this.data('overnight');

		if (selectedShippingMethod) {
			if ($this.hasClass('js-pickup-point-method')) {
				$('.js-pickup-point-method').removeClass('is-selected');
				$this.addClass('is-selected');
			}
			else {
				updateShippingValue(selectedShippingMethod, isOvernightShippingMethod, '.js-shipping-method-option.is-selected');
			}
		}
	});
}

function showPickUpPointSearch(isPickupPointMapOpened) {
	pickupPoint.handlePickupPoint(isPickupPointMapOpened);
	$('[data-js="pickup-point-shipping-methods-wrapper"]').hide();
	$('[data-js="pickup-points-search-wrapper"]').show();
	$('[data-js="shipping-details-flyout-title"]').text(Resources.PICKUPPOINT_SEARCH_TITLE);
}

/**
 * Ajax call to Checkout-SubmitForm.
 * @param {String} shippingMethodID
 * @param {Object} additionalRequestData
 * @param {Function} callbackFunction
 * @param {Boolean} keepFlyOutOpen
 */
function updateShippingValue (shippingMethodID, isOvernightShippingMethod, focusElementSelector, callbackFunction, keepFlyOutOpen) {
	var focusElement = $(focusElementSelector);

	var options = {
		action: 'updateShippingMethod',
		keepFlyOutOpen: keepFlyOutOpen,
		isShipToMeOvernightMethod: isOvernightShippingMethod,
		extraParameters: [{ name: 'shippingMethodID', value: shippingMethodID }],
		callback: function() {
			var shippingMethodName = focusElement.data('shippingmethodname');
			$(document).trigger('shippingmethod-changed', shippingMethodName);

			if (accessibility.isKeyBoardNavigationActive()) {
				accessibility.simulateTabKeyFocus(focusElement);
			}

			if (callbackFunction !== undefined) {
				callbackFunction();
			}
		}
	};
	submitForm.submitAjaxForm(options);
}

exports.init = function() {
	initializeEvents();
	pickupInStore.init();
	giftCard.init();

	if (market == 'JP') {
		//we use designated delivery only on JP
		designatedDelivery.init();
	}
};