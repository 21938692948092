'use strict';

var util = require('./util'),
	accessibility = util.isMobile() ? require('./accessibilityForMobile') : require('./accessibility'),
	swiper = require('./swiper'),
	flyout = require('./flyout'),
	plpMobileFilter = require('./plpMobileFilter');

var isMobileView = !!$('[data-js="open-filter-flyout"]:visible').length;

function initializeFilterBarEvets() {
	var plpRefineBar = $('[data-js="plp-refine-bar"]');
	if (!plpRefineBar.length) {
		return;
	}

	var refineBarFilters = plpRefineBar.find('[data-js="filter-block"]');
	if (!refineBarFilters.length) {
		return;
	}

	$('body').off('click', '[data-js="plp-refine-bar"] [data-js="filter-toggler"]')
		.on('click', '[data-js="plp-refine-bar"] [data-js="filter-toggler"]', function () {
			var toggler = $(this);
			var content = toggler.siblings('[data-js="filter-content"]');
			content.stop().slideToggle().toggleClass('is-open');
			toggler.toggleClass('is-open').attr('aria-expanded', toggler.hasClass('is-open'));

			var nonSelectedTogglers = refineBarFilters.find('[data-js="filter-toggler"].is-open').not(this);
			var nonSelectedContent = nonSelectedTogglers.siblings('[data-js="filter-content"].is-open');
			nonSelectedContent.stop().slideToggle().removeClass('is-open');
			nonSelectedTogglers.removeClass('is-open').attr('aria-expanded', 'false');

			var isKeyboardNav = toggler.hasClass('focus-visible');
			accessibility.setKeyboardNavigation(isKeyboardNav);
			accessibility.updateFilterContentTabindexes('1', content);
			accessibility.updateFilterContentAccessibility(toggler, content);
		});

	var refineBarSort = plpRefineBar.find('[data-js="product-sort-tag"]');
	if (!refineBarSort.length) {
		return;
	}

	$('body').off('click', '[data-js="plp-refine-bar"] [data-js="product-sort-tag"]')
		.on('click', '[data-js="plp-refine-bar"] [data-js="product-sort-tag"]', function () {
			var toggler = $(this);
			var content = toggler.siblings('[data-js="product-sort-categories"]');
			content.stop().slideToggle();
			toggler.toggleClass('is-open').attr('aria-expanded', toggler.hasClass('is-open'));

			var isKeyboardNav = toggler.hasClass('focus-visible');
			accessibility.setKeyboardNavigation(isKeyboardNav);
			accessibility.updateFilterContentTabindexes('1', content);
			accessibility.updateFilterContentAccessibility(toggler, content);
		});
}

function initializeFilterFlyoutEvents() {
	var plpRefineFlyout = $('[data-js="plp-refine-flyout"]');
	if (!plpRefineFlyout.length) {
		return;
	}

	if (isMobileView) {
		plpRefineFlyout.find('[data-js="filter-block"]').removeClass('js-expanded');
		$('[data-js="open-filter-flyout"]').attr('aria-expanded', $('body').hasClass('show-filter'));
		plpRefineFlyout.addClass('mobile-view').removeClass('desktop-view');
	}

	// Filter torggler click on desktop view
	$('body').off('click', '[data-js="plp-refine-flyout"].desktop-view [data-js="filter-toggler"]')
		.on('click', '[data-js="plp-refine-flyout"].desktop-view [data-js="filter-toggler"]', function () {
			var toggler = $(this);
			var content = toggler.parent().find('[data-js="filter-content"]');

			toggler.toggleClass('close open').attr('aria-expanded', toggler.hasClass('open'));
			toggler.parent().toggleClass('js-expanded');
			content.stop().slideToggle();

			accessibility.updateDesktopFlyoutAccessibility();
		});

	// Opens filter flyout on mobile
	$('body').off('click', '[data-js="open-filter-flyout"]')
		.on('click', '[data-js="open-filter-flyout"]', function () {
			$('body').removeClass('search-is-open');
			if (window.matchMedia('(min-width: 1025px)').matches) {
				$('body, .plp-wrapper .c-products-list').toggleClass('show-filter');
			}
			else {
				$('body').addClass('show-filter no-scroll');
				checkFilterVerticalVisibility();
			}

			$('[data-js="open-filter-flyout"]').attr('aria-expanded', $('body').hasClass('show-filter'));
			if (!$('body').hasClass('show-filter')) {
				$('body').trigger('filtersclosed');
			}

			$('[data-js="plp-refine-flyout"]').find('[data-js="filter-content"]').removeClass('mobile-panel-open');

			var isKeyboardNav = $(this).hasClass('focus-visible');
			accessibility.setKeyboardNavigation(isKeyboardNav);
			accessibility.updateFilterFlyoutTabindexes('1');
			accessibility.updateMobileFlyoutAccessibility();

			if (accessibility.isKeyBoardNavigationActive()) {
				var closeButton = $('[data-js="plp-refine-flyout"]').find('[data-js="filter-mobile-close"]').first();
				accessibility.simulateTabKeyFocus(closeButton);
			}
		});

	// Closes filter flyout on mobile
	$('body').off('click', '[data-js="plp-refine-flyout"].mobile-view [data-js="filter-mobile-close"]')
		.on('click', '[data-js="plp-refine-flyout"].mobile-view [data-js="filter-mobile-close"]', function () {
			var flyoutContents = $('[data-js="plp-refine-flyout"]').find('[data-js="filter-content"]');
			$('body').removeClass('show-filter no-scroll');
			flyoutContents.removeClass('mobile-panel-open');
			$('body').trigger('filtersclosed');
			swiper.initializeProductTileSwiper();

			var isKeyboardNav = $(this).hasClass('focus-visible');
			accessibility.setKeyboardNavigation(isKeyboardNav);

			accessibility.updateFilterFlyoutTabindexes('-1');
			if (accessibility.isKeyBoardNavigationActive()) {
				accessibility.simulateTabKeyFocus($('[data-js="open-filter-flyout"]'));
			}
		});

	// Opens filter content on mobile
	$('body').off('click', '[data-js="plp-refine-flyout"].mobile-view [data-js="filter-toggler"]')
		.on('click', '[data-js="plp-refine-flyout"].mobile-view [data-js="filter-toggler"]', function () {
			var addMoreBtn = $('[data-js="plp-refine-flyout"] [data-js="filter-flyout-mobile-btn-wrap"] > [data-js="filter-mobile-back"]');
			var toggler = $(this);
			var content = toggler.next();
			content.toggleClass('open mobile-panel-open');
			toggler.parent().addClass('js-expanded');
			addMoreBtn.removeClass('u-hidden');
			checkFilterVerticalVisibility();

			var isKeyboardNav = toggler.hasClass('focus-visible');
			accessibility.setKeyboardNavigation(isKeyboardNav);
			accessibility.updateFilterFlyoutTabindexes('-1');
			accessibility.updateFilterContentTabindexes('1', content, true);
			accessibility.updateMobileFlyoutAccessibility(content);

			if (accessibility.isKeyBoardNavigationActive()) {
				var mobileBackBtn = content.find('[data-js="filter-mobile-back"]');
				accessibility.simulateTabKeyFocus(mobileBackBtn);
			}
		});

	// Closes filter content on mobile
	$('body').off('click', '[data-js="plp-refine-flyout"].mobile-view [data-js="filter-mobile-back"]')
		.on('click', '[data-js="plp-refine-flyout"].mobile-view [data-js="filter-mobile-back"]', function () {
			var plpRefineFlyout = $('[data-js="plp-refine-flyout"]');
			var openedFilterBlock = plpRefineFlyout.find('[data-js="filter-block"].js-expanded');
			var flyoutContents = plpRefineFlyout.find('[data-js="filter-content"]');
			var addMoreBtn = plpRefineFlyout.find('[data-js="filter-flyout-mobile-btn-wrap"] > [data-js="filter-mobile-back"]');

			openedFilterBlock.removeClass('js-expanded');
			flyoutContents.removeClass('mobile-panel-open');
			addMoreBtn.addClass('u-hidden');
			$('html, body').animate({
				scrollTop: 0
			}, 500);
			checkFilterVerticalVisibility();

			var isKeyboardNav = $(this).hasClass('focus-visible');
			accessibility.setKeyboardNavigation(isKeyboardNav);
			var content = openedFilterBlock.find('[data-js="filter-content"]');
			var toggler = openedFilterBlock.find('[data-js="filter-toggler"]');

			accessibility.updateFilterContentTabindexes('-1', content, true);
			accessibility.updateFilterFlyoutTabindexes('1');
			accessibility.updateMobileFlyoutAccessibility();

			if (accessibility.isKeyBoardNavigationActive()) {
				accessibility.simulateTabKeyFocus(toggler);
			}
		});
}

function checkFilterVerticalVisibility() {
	var plpRefineFlyout = $('[data-js="plp-refine-flyout"]');
	var filterFlyoutBtnWrap = plpRefineFlyout.find('[data-js="filter-flyout-mobile-btn-wrap"]');
	var isFilterOpen = $('body').hasClass('show-filter');
	if (!util.isSmallScreenSize() || !isFilterOpen || !(filterFlyoutBtnWrap.length > 0)) {
		return;
	}

	var isBtnWrapFixed = filterFlyoutBtnWrap.css('position') === 'fixed';
	if (!isBtnWrapFixed) {
		return;
	}

	var flyoutContents = plpRefineFlyout.find('[data-js="filter-content"]');
	var heightBtnWrap = filterFlyoutBtnWrap.outerHeight();
	plpRefineFlyout.find('[data-js="refinements-wrapper"]')
		.css('padding-bottom', heightBtnWrap);

	if (flyoutContents.length > 0) {
		flyoutContents.css('padding-bottom', heightBtnWrap);
	}
}

/**
 * Function that prepares flyout in case that we switch between desktop and mobile view
 */
function resize() {
	var plpRefineFlyout = $('[data-js="plp-refine-flyout"]');
	if (!plpRefineFlyout.length) {
		return;
	}
	isMobileView = !!$('[data-js="open-filter-flyout"]:visible').length;

	// If we keep the same view type we don't need to update flyout
	if (plpRefineFlyout.hasClass('desktop-view') && !isMobileView ||
		plpRefineFlyout.hasClass('mobile-view') && isMobileView) {
		return;
	}

	var flyoutBlocks = plpRefineFlyout.find('[data-js="filter-block"]');
	plpRefineFlyout.find('[data-js="close-flyout-filters"]').trigger('click');
	plpRefineFlyout.find('[data-js="filter-mobile-close"]').trigger('click');
	accessibility.updateFilterFlyoutTabindexes('-1');
	accessibility.updateFilterFlyoutTabindexes('-1', true);

	if (!isMobileView) {
		flyoutBlocks.addClass('js-expanded');
		plpRefineFlyout.addClass('desktop-view').removeClass('mobile-view');
		return;
	}

	flyoutBlocks.removeClass('js-expanded');
	$('[data-js="open-filter-flyout"]').attr('aria-expanded', false);
	plpRefineFlyout.addClass('mobile-view').removeClass('desktop-view');
}

/**
 * Updates tabindexes, accessibility logic, element focus of flyout
 * Used on mobile once we apply some refinement and override HTML
 * @param {Object} originalElement
 */
function updateMobileFlyoutOnSelection(originalElement) {
	$('[data-js="plp-refine-flyout"]').removeClass('desktop-view').addClass('mobile-view');

	var elementDataJS = originalElement.data('js');
	var isSortingElement = elementDataJS === 'sorting-item';
	var isResetFiltersElement = elementDataJS === 'clear-filters';
	var focusElement = null;
	var value = null;

	if (isResetFiltersElement) {
		accessibility.updateFilterFlyoutTabindexes('1');
		accessibility.updateMobileFlyoutAccessibility();
		focusElement = $('[data-js="plp-refine-flyout"] [data-js="mobile-header"] [data-js="clear-filters"]');
	}
	else {
		var valueElementOld = isSortingElement ? originalElement : originalElement.find('[data-js="value"]');
		value = valueElementOld.data('value');
	}

	if (isSortingElement) {
		accessibility.updateFilterFlyoutTabindexes('1');
		accessibility.updateMobileFlyoutAccessibility();
		focusElement = $('[data-js="plp-refine-flyout"] [data-value="' + value + '"]');
		focusElement.parents('[data-js="filter-block"]').addClass('js-expanded');
	}

	if (!isResetFiltersElement && !isSortingElement) {
		focusElement = $('[data-js="plp-refine-flyout"] [data-value="' + value + '"]').parent();
		focusElement.parents('[data-js="filter-block"]').addClass('js-expanded');
		var content = focusElement.parents('[data-js="filter-content"]');
		accessibility.updateFilterContentTabindexes('1', content, true);
		accessibility.updateMobileFlyoutAccessibility(content);
	}

	if (accessibility.isKeyBoardNavigationActive() && focusElement && focusElement.length) {
		accessibility.simulateTabKeyFocus(focusElement);
	}
}

exports.updateMobileFlyout = updateMobileFlyoutOnSelection;
exports.resize = resize;
exports.init = function() {
	initializeFilterBarEvets();
	initializeFilterFlyoutEvents();
	plpMobileFilter.init();
	flyout.init();
};