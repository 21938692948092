'use strict';
var trackingHelper = require('./trackingHelper');
var checkout = require('./checkout');

// Map used to map values from our namespaces of pages to ometria ones
var pageTitleMap = {
    "storefront": "homepage",
    "orderconfirmation": "confirmation",
    "search": "listing",
    "cart": "basket"
};

/**
 * Function used to initialize and start ometria tracking
 */
function initOmetriaTracking() {
    if (typeof ometria == 'undefined') {
        return;
    }
    var ometriaStoreId = Constants.OMETRIA_STORE_ID;
    var pageTitle = pageTitleMap[window.pageContext.ns] != null ? pageTitleMap[window.pageContext.ns] : window.pageContext.ns != null ? window.pageContext.ns : 'other';
    var pageData = null;

    if (pageTitle == "product") {
        pageData = getProductInfo();
    }
    ometria.init(pageTitle, pageData, ometriaStoreId);

    if (pageTitle == "confirmation") {
        trackPlaceOrder();
        initEmptyBasket();
    } else {
        //We call ometria basket on every page where we can access to it, minicart or cart
        ometriaTrackBasket();
    }

}

/**
 * Method used to initialize empty ometria basket
 */
function initEmptyBasket() {
    var basket = new ometria.Basket();
    var currency = $('#js-currency').val();
    basket.setTotal(0, currency);
    ometria.setBasket(basket);
}

/**
 * Used to identify customer if he is logged in
 * parameter is used only for registration and login
 */
function ometriaUserIdentify(userIdContainer) {
    if (typeof ometria == 'undefined') {
        return;
    }

    // if there is a logged in authenicated user
    if (userIdContainer && userIdContainer.length > 0) {
        var trackingDataValue = userIdContainer.val();

        if (trackingDataValue) {
            var trackingData = JSON.parse(trackingDataValue);
            var authenticatedUserMail = trackingData.authenticatedUserMail;
            ometria.identify(authenticatedUserMail);
        }
    }
}
/**
 *  Function used for basket tracking add or remove
 * @param {*} product param is used to send product which color/size is changed
 * so it can be skipped in temporary setBasket
 * 
 */
function ometriaTrackBasket(product) {
    if (typeof ometria == 'undefined') {
        return;
    }
    var basket = new ometria.Basket();
    var basketIdContainer = $("#ometria-basket-id");
    if (basketIdContainer && basketIdContainer.length > 0) {
        var ometriaBasketId = basketIdContainer.val();
        if (ometriaBasketId != null) {
            basket.setId(ometriaBasketId);
        }

    }
    var basketUrlContainer = $("#basket-url");
    if (basketUrlContainer && basketUrlContainer.length > 0) {
        var basketUrl = basketUrlContainer.val();
        if (basketUrl != null) {
            basket.setUrl(basketUrl);
        }
    }
    extractBasketData(basket, product);
    ometria.setBasket(basket);
}
/**
 * Function used to get Information about product and send it to track
 */
function getProductInfo() {
    var jqueryObj = $('.js-pdp-product-info');
    var variantgroupId = jqueryObj.data('variantgroup-id') !== null ? jqueryObj.data('variantgroup-id').toString() : '';
    var product = {
        'pid': variantgroupId,
    };
    return product;
}
/**
 * Function used to track order number when order is placed
 */
function trackPlaceOrder() {
    if (typeof ometria == 'undefined') {
        return;
    }
    var confirmationOrderId = $('[data-js="ometria-order-id"]').attr("data-ometriaorderid");
    if (confirmationOrderId != null) {
        ometria.trackTransaction(confirmationOrderId);
    }
}
/**
 * Function used to get basket information from mini cart or cart page
 * @param {*} basket 
 */
function extractBasketData(basket, product) {
    if (typeof ometria == 'undefined') {
        return;
    }
    var cartItemsContainer = $(".mini-cart-products");
    var productTarget = ".js-mini-cart-product";

    if (trackingHelper.isCartUrl()) {
        cartItemsContainer = $(".js-cart-articles");
        productTarget = ".js-cart-row";

    } else if (checkout.isCheckoutUrl()) {
        var cartItemsContainer = $(".mini-cart-products");
        var productTarget = ".js-order-product";
    }
    //Check if we have only one product and we changed color/size so we set empty basket on temporary setBasket event
    if (cartItemsContainer.find(productTarget).length == 1 && product != null) {
        initEmptyBasket();
        return;
    }
    // Temporary Cart total field is used for C&R in checkout 
    //because on that view we doesn't have total values so we need to calculate them
    var tempCartTotal = 0;
    
    cartItemsContainer.find(productTarget).each(function () {
        var quantity = parseInt($(this).data('quantity'));
        var price = parseFloat($(this).data('price'));
        //variant-id attribute is used only in  cart page, mini cart item id have value of variant id while in cart is master id
        //so if we are in minicat (variant-id not exist) we take values for mini cart in other case we take values from cart page
        var variantId = $(this).data('variant-id') == undefined ? $(this).data('itemid') : $(this).data('variant-id');
        var itemId = $(this).data('variantgroup-id');
        if (product != null) {
            if (product.variantGroupId != itemId || product.variantId != variantId) {
                basket.addLineItem(itemId, quantity, price, variantId);
            }
        } else {
            basket.addLineItem(itemId, quantity, price, variantId);
        }
        tempCartTotal += price * quantity;
    });
    var cartValue = $('.js-order-subtotal').attr('data-ordertotal') ? $('.js-order-subtotal').attr('data-ordertotal') : $('.mini-cart-subtotals').attr('data-ordertotal') ? $('.mini-cart-subtotals').attr('data-ordertotal') : tempCartTotal;
    var currency = $('#js-currency').val();
    //Check if we changing color/size for product and for that product we update total price in temporary basket
    if (product != null && product.price > 0) {
        cartValue -= product.price * product.quantity;
    }
    basket.setTotal(cartValue, currency);
}

exports.initOmetriaTracking = initOmetriaTracking;
exports.ometriaTrackBasket = ometriaTrackBasket;
exports.ometriaUserIdentify = ometriaUserIdentify;
exports.trackPlaceOrder = trackPlaceOrder;