var flags = {
	preventShippingAddressUpdate: false, //used to prevent multiple ajax calls at the same time (state, address list, address complete)
	shippingAddressUpdateTriggered: false, //used to trigger address update address after preventing it
	shippingAddressStateChanged: false, //used to send isStateChanged parameter on address update
	isTriggeredByAddressSelect: false, // used to identify if shipping address update is triggered by selecting an address from addres book or not
	isTriggeredByBillingAddressSelect: false // used to identify if billing address update is triggered by selecting an address from addres book or not
};


exports.setFlag = function(flag, value) {
	flags[flag] = value;
};

exports.getFlag = function(flag) {
	return flags[flag];
};
