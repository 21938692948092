'use strict';

var dialog = require('./dialog');
var util = require('./util');
var accessibility = (util.isMobile()) ? require('./accessibilityForMobile') : require('./accessibility');
var progress = require('./progress');


function initEvents() {
	/**
     * @description event that can be atached using 'js-open-in-dialog' class, this event will open defined href in dialog
     * @params dialogclass can be passed as data attribute if we need custom class for modal
     */
	$('body').on('click', '.js-open-in-dialog', function(e) {
		e.preventDefault();
		var $this = $(this);

		var params = {
			options: {
				dialogClass: $this.data('dialogclass'),
			},
			url: $(e.target).attr('href') || $(this).attr('href'),
			focusVisible: $this.hasClass('focus-visible'),
			setTabIndexToDialogContent: true
		};

		open(params);
	});
}

/**
 * Opens modal dialog with custom params
 * @param {Object} params
 * params can contain:
 * {
 *  url/html - used for getting/rendering html
 *  options - options object to pass to jquery modal
 *  focusVisible - to set keyboard navigation active
 *  callback - callback function that will be triggered after modal is opened
 * }
 */
function open(params) {
	if (params.hasOwnProperty('html')) {
		HTMLOpen(params);
		return;
	}
	URLOpen(params);
}

/**
 * Opens modal dialog using url from params
 * @param {Object} params
 */
function URLOpen(params) {
	progress.show();
	dialog.open({
		options: params.options,
		url: params.url,
		closeAll: false,
		callback: function() {
			progress.hide();

			dialogOpenCallback(params);
		}
	});
}

/**
 * Opens modal dialog using html from params
 * @param {Object} params
 */
function HTMLOpen(params) {
	dialog.open({
		options: params.options,
		html: params.html,
		closeAll: false,
		callback: function() {
			dialogOpenCallback(params);
		}
	});
}

/**
 * Closes modal dialog
 */
function close() {
	dialog.close();
}

/**
 * Callback for dialog open
 */
function dialogOpenCallback(params) {
	// Keyboard accessibility
	if (params.setTabIndexToDialogContent) {
		$('.dialog-content').attr('tabindex', '0');
	}
	if (params.focusVisible != undefined) {
		accessibility.setKeyboardNavigation(params.focusVisible);
	}
	accessibility.attachDialogEvents();

	if (params.callback) {
		params.callback();
	}
}

exports.init = function () { initEvents(); };
exports.open = open;
exports.close = close;
