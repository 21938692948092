'use strict';

var googleMap = require('./googlemap'),
	noMap = require('./nomap');

var currentMap;
var maps = {
	googlemap: googleMap,
	nomap: noMap
};

/**
 * Initializes map object
 * @param {String} type
 * @param {String} sortBy
 * @param {Object} resultsObject
 */
module.exports = function map(type, sortBy, resultsObject) {
	if (!type || !resultsObject) {
		return;
	}
	currentMap = maps[type.toLowerCase()];
	currentMap.init(resultsObject, sortBy);

	this.init = currentMap.init;
	this.searchAddress = currentMap.searchAddress;
	this.getAdditionalStores = getAdditionalStores;
	this.sortStoreResults = currentMap.sortStoreResults;
	this.updateNearMeFlag = currentMap.updateNearMeFlag;
};


/**
 * Returns a new batch of visible stores from current map
 * @param {Number} loadedStoresNo
 * @param {Number} visibleStoresLoadMax
 * @returns {Array}
 */
function getAdditionalStores(loadedStoresNo, visibleStoresLoadMax) {
	var allResults = currentMap.getVisibleStores();
	var additionalResults = [];
	var start = loadedStoresNo;
	var end = Math.min(allResults.length, (start + visibleStoresLoadMax));

	if (loadedStoresNo > allResults.length) {
		return additionalResults;
	}

	for (var i = start; i < end; i++) {
		var store = allResults[i];
		additionalResults.push(store);
	}

	return additionalResults;
}