'use strict';

var util = require('./util');
var accessibility = (util.isMobile()) ? require('./accessibilityForMobile') : require('./accessibility');

/**
 * @function
 * @description  Accordion on PDP page
 */
function pdpAccordion() {
	var accordionContent = ('.accordion-section__content');
	var accordionBtn = $('[data-js="accordion-btn"]');

	accordionBtn.off('click').on('click', function() {
		var $this = $(this);

		$this.next(accordionContent).siblings(accordionContent).slideUp('fast');
		$this.siblings(accordionBtn).removeClass('open');

		$this.next(accordionContent).slideToggle('fast');
		$this.toggleClass('open');

		setTimeout(function () { // delayed animation to solve the position
			$('html, body').animate({
				scrollTop: $this.offset().top - 145 //This beacuse of the fixed header and sticky button
			}, 500);
		}, 500);
	});
}

/**
 * Scroll-down animation when user clicks on anchor link item (Shoe Care or Reviews)
 */
function pdpAnchorItemLink() {
	var anchorLink = $('[data-js="pdp-anchor-item-link"]');
	var headerHeight = $('.c-header-nav').height() + 20;

	anchorLink.on('click', function() {
		var $this = $(this);
		var scrollToElementSelector = '#' + $this.data('href');

		if ($('.yotpo-no-reviews').length && scrollToElementSelector === '#yotpo-pdp-reviews-wrapper' && util.isSmallScreenSize()) {
			$('html, body').animate({
				scrollTop: $(scrollToElementSelector).offset().top - headerHeight - 150
			}, 1000);
			return;
		}

		$('html, body').animate({
			scrollTop: $(scrollToElementSelector).offset().top - headerHeight
		}, 1000);

		accessibility.setKeyboardNavigation($this.hasClass('focus-visible'));

		if (scrollToElementSelector === '#yotpo-pdp-reviews-wrapper') {
			accessibility.setFocusOnReviews();
			return;
		}

		if (scrollToElementSelector === '#pdp-shoe-care-block') {
			accessibility.setFocusOnShoeCare();
		}
	});
}

exports.init = function () {
	pdpAccordion();
	pdpAnchorItemLink();
};
