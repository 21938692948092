'use strict';

var _ = require('lodash'),
	Promise = require('promise'),
	variant = require('./variant'),
	dialog = require('../../dialog'),
	minicart = require('../../minicart'),
	page = require('../../page'),
	util = require('../../util'),
	ajax = require('../../ajax'),
	progress = require('../../progress'),
	cart = require('../cart/index'),
	fbconversion = require('../../fbconversion');
/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addItemToCart = function(form) {
	// to avoid getting the broken page on the outlet site if the cookie gets deleted (edge case when
	// customer deletes it manually) redirect customer on the FE using javascript
	// otherwise HTML will get mixed and page becomes broken
	if (SitePreferences.IS_OUTLET && !util.readCookie('ecco_outlet_auth')) {
		page.redirect(Urls.outlet);
		return;
	}

	var $form = $(form),
		$qty = $form.find('input[name="Quantity"]');
	if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
		$qty.val('1');
	}
	return Promise.resolve($.ajax({
		type: 'POST',
		url: util.ajaxUrl(Urls.addProduct),
		data: $form.serialize()
	})).then(function(response) {
		// handle error in the response
		if (response.error) {
			throw new Error(response.error);
		}
		else {
			return response;
		}
	});
};

/**
 * Updates cart page, restores cart url and scrolls to the top of the page
 */
function updateCartPage() {
	progress.show();
	ajax.load({
		url: Urls.cartShow,
		target: $('[data-js="cart-wrapper"]'),
		callback: function () {
			cart.init();
			util.updateBrowserURLByReplacingTheCurrentState(Urls.cartShow);
			progress.hide();
			util.scrollBrowser(0);
		}
	});
}

/**
 * @description Remove size selection
 */

var removeSelectedSize = function(callback) {
	var url = document.URL;
	var urlParams = util.getQueryStringParams(util.getQueryString(url));

	Object.keys(urlParams).forEach((paramName) => {
		if (paramName.includes('size')) {
			url = util.removeParamFromURL(url, paramName);
		}
	});

	//first parameter isColorSwatch and because this function is about removing selected size, it's set to false
	variant.updateContent(false, url, null, null, callback, null);
};

/**
 * @description Check if color and size are selected
 * @returns {Boolean} true if color and size are selected in other case returns false
 */

var allOptionsSelected = function(productInfoContainer) {
	var productVariations = productInfoContainer.find('.js-pdp-product-info .product-variations');
	var optionsSelected = false,
		options = productVariations.find('.js-color-swatch');
	if (productInfoContainer.find('.giftcard-block').length > 0) {
		if (productInfoContainer.find('.list-btn').children().find('.js-amount-listitem').filter('.is-selected').length > 0) {
			optionsSelected = true;
		}
	}
	$(options).each(function () {
		if ($(this).children().filter('.selected').length > 0) {
			optionsSelected = true;
		}
		else {
			optionsSelected = false;
			return false; // break
		}
	});

	return optionsSelected;
};

/**
 * @function
 * @description Handler to manage the AJAX call to add a product to cart, and show
 * the minicart open if it proceeds (only for desktop)
 * @returns nothing
 *
 */
var addToCart = function(e) {
	e.preventDefault();
	var isQuickView = $(this).closest('.product-detail-block').hasClass('quickview');

	var $form = $(this).closest('form');
	var $productInfoContainer = $(this).closest('#product-content');
	const isSticky = $productInfoContainer.find('.js-product-bottom-info').hasClass('is-sticky');
	// we retrieve the product name now as in the PLP the dialog will be closed when the callback is executed
	// (and it won't change)
	const productName = $productInfoContainer.find('#product-top-info .js-product-name').text().trim();
	const productId = $productInfoContainer.find('#product-top-info').data('variant-id');
	// same - cached as the interaction with the tile might modify its state, we want the state at the clicked moment
	var $this = $(this);
	// to check if we will keep the minicart open, or we'll close after some secs
	var keyboardInteraction = $this.hasClass('focus-visible');
	// Size out of stock error msg
	var oosSizeErrorMsgSelector = $productInfoContainer.find('.out-of-stock-size.msg-error');

	if (allOptionsSelected($productInfoContainer)) {
		addItemToCart($form).then(function(response) {
			var $uuid = $form.find('input[name="uuid"]');
			if ($uuid.length > 0 && $uuid.val().length > 0) {
				page.refresh();
				return;
			}
			var data = { eventId: util.generateUUID(), event: 'AddToCart', productId: productId };
			fbconversion.triggerEvent(data);
			$(document).trigger('addtocart', data.eventId);
			// do not close quickview if adding individual item that is part of product set
			if (!$this.hasClass('sub-product-item')) {
				$('#QuickViewDialog').dialog('close');
			}

			if (util.isMobileAndBelow()) {
				removeSelectedSize(function () {
					$('.js-icon-close').trigger('click');
					var pid = $form.find('input[name="pid"]').val();
					showSuccessfulMessage(pid, function() {
						// update the cart after we display the success message componnent
						updateCart(response, productName, keyboardInteraction);
					});
				});
			}
			else if (util.isTabletAndBelow()) {
				removeSelectedSize(function () {
					$('.js-icon-close').trigger('click');
					var pid = $form.find('input[name="pid"]').val();
					updateCart(response, productName, keyboardInteraction);
				});
			}
			else {
				updateCart(response, productName, keyboardInteraction);
			}

			//here we update GTM event
			$(document).trigger('updatedcart');
		}.bind(this));
	}
	else {
		//show message only for addToCart button and hide store availability related warning message
		var messageElement = $productInfoContainer.find('#add-to-cart-description');
		messageElement.removeClass('u-hidden');

		if (!isQuickView) {
			var pdpDesktopStickBarErrorMessage = $('[data-js="js-pdp-sticky-bar"]').find('[data-js="sticky-error"]');
			pdpDesktopStickBarErrorMessage.html(messageElement.html());
			pdpDesktopStickBarErrorMessage.removeClass('u-hidden');
		}

		var storeAvailabilityMsg = $productInfoContainer.find('#store-availability-msg');
		if (!storeAvailabilityMsg.hasClass('u-hidden')) {
			storeAvailabilityMsg.addClass('u-hidden');
		}

		// if is sticky show options selector else scroll to the message
		if (isSticky) {
			// show size options if not shown
			if (util.isSmallScreenSize()) {
				$('.js-product-bottom-info').addClass('option-is-open');
			}
		}
		else {
			if (!isQuickView && (!pdpDesktopStickBarErrorMessage.length || !pdpDesktopStickBarErrorMessage.is(':visible'))) {
				var elementTop = messageElement.offset().top,
					elementHeight = messageElement.height(),
					viewportHeight = $(window).height(),
					scrollTo = elementTop - ((viewportHeight - elementHeight) / 2);

				$('html, body').animate({ scrollTop: scrollTo }, 400);
			}
		}
	}
	// Hide error msg and border for oos size when user clicks on add to cart button
	if (!oosSizeErrorMsgSelector.hasClass('u-hidden')) {
		oosSizeErrorMsgSelector.addClass('u-hidden');
		$productInfoContainer.find('.unselectable.oos-clicked').removeClass('oos-clicked');
	}
};

/**
 * Updates either whole cart page or mini cart depending on which page are we on
 * @param {String} response
 * @param {String} productName
 * @param {Boolean} keyboardInteraction
 */
function updateCart(response, productName, keyboardInteraction) {
	var isCart = $('.pt_cart').length > 0;

	if (isCart) {
		updateCartPage();
	}
	else {
		updateMiniCart(response, productName, keyboardInteraction);
	}
}

/**
 * Opens minicart and dispalys overlay for desktop
 * @param {String} response
 * @param {String} productName
 * @param {Boolean} keyboardInteraction
 */
function updateMiniCart(response, productName, keyboardInteraction) {
	minicart.show(response);
	minicart.addNotification(`${Resources.ADD_TO_BAG_SUCCESS_1} "${productName}" ${Resources.ADD_TO_BAG_SUCCESS_2}`);

	//start timer if keyboard is not used for navigation
	if (!keyboardInteraction) {
		minicart.startTimer(8000);
	}

	if (!util.isMobileAndBelow()) {
		var overlay = $('.js-overlay');
		overlay.fadeIn('slow', function () {
			// not two elements get focus at the same time, so we remove it from the tile as the minicart gets it
			$('.focus-visible,.force-hover')
				.removeClass('focus-visible')
				.removeClass('force-hover');
			// we only apply focus programatically if keyboard was used, to not steal the mouse focus
			if (keyboardInteraction) {
				minicart.$close.first().trigger('focus');
				minicart.$close.first().addClass('focus-visible');
			}
		});
	}
}

/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function(e) {
	e.preventDefault();
	var $productForms = $('#product-set-list').find('form').toArray();
	Promise.all(_.map($productForms, addItemToCart))
		.then(function(responses) {
			dialog.close();
			// show the final response only, which would include all the other items
			minicart.show(responses[responses.length - 1]);
		});
};


/**
 * @function
 * @description This function will show successful message after we add product to the cart (only on mobile)
 */
var showSuccessfulMessage = function(pid, callback) {
	var successMessageContainer = $('[data-js="pdp-successful-msg"]');
	var data = { pid: pid };

	ajax.load({
		url: Urls.addToCartSuccess,
		data: data,
		target: successMessageContainer,
		callback: function () {
			successMessageContainer.addClass('is-active');

			var addToCartWrapper = $('[data-js="pdp-add-to-cart"]');
			addToCartWrapper.addClass('u-hidden');

			var productCloseSection = $('[data-js="product-close-section"]');
			productCloseSection.addClass('u-hidden');

			var addToCartButtonContainer = $('.js-product-bottom-info');
			addToCartButtonContainer.addClass('is-sticky');
			addToCartButtonContainer.removeClass('step-before-sticky');

			var closeDialog = $('[data-js="close-sucessfull-msg"]');
			closeDialog.off('click').on('click', closeSuccessfulMessage);

			util.rememberScrollPosition('product-is-added');
			callback();
		}
	});
};

/**
 * @function
 * @description Close Dialog after product is added (Only mobile)
 */
var closeSuccessfulMessage = function () {
	util.restoreScrollPosition('product-is-added');

	var msgDialog = $('[data-js="pdp-successful-msg"]');
	msgDialog.removeClass('is-active');

	var addToCartWrapper = $('[data-js="pdp-add-to-cart"]');
	addToCartWrapper.removeClass('u-hidden');

	var productCloseSection = $('[data-js="product-close-section"]');
	productCloseSection.removeClass('u-hidden');

	var addToCartButtonContainer = $('.js-product-bottom-info');
	addToCartButtonContainer.removeClass('is-sticky');

	$(document).trigger('checkaddtocartbuttonvisibility');
};
/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */
module.exports = function() {
	$('.add-to-cart[disabled]').attr('title', $('.availability-msg').text());
	$(document).off('click', '.js-add-to-cart').on('click', '.js-add-to-cart', addToCart);
	$('#add-all-to-cart').on('click', addAllToCart);
};
