'use strict';

var util = require('../util');
var page = require('../page');

function initializeEvents() {
	$('.js-account-link-loggedin').on('click', function(e) {
		e.preventDefault();
		var $this = $(this);

		if(!util.isSmallScreenSize()) {
			$('.js-account-links').toggleClass('is-open');
			// Toggle aria expanded - doesnt work
			var expanded = $this.attr('aria-expanded') == 'true';
			$this.attr('aria-expanded', expanded);
		} else {
			page.redirect(Urls.MyAccount);
		}
	});
}

exports.init = initializeEvents;