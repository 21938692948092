'use strict';

var util = require('./util');

/**
 * @function
 * @description Initializes the Sticky Header on the pages
 */

let initialPadding;

function stickyHeader() {
	if ($('.pt_card_edit_mode').length > 0) {
		return;
	}

	var $topDivs = $('body').children('div[class^="pt_"]');
	var $scrollParent = util.getScrollParent();
	var $header = $('[data-js="header"]');
	var $main = $topDivs.children('.c-main');
	var uspBannerHeight = $('[data-js="usp-banner"]').length ? $('[data-js="usp-banner"]').innerHeight() : 0;

	var shouldBeSticky = $scrollParent.scrollTop() > 1 && $('#is-not-sticky').length === 0 && !($('body').hasClass('no-scroll'));

	// if the body is blocked from scrolling but is not set to the top of the page, add the sticky header
	if (!shouldBeSticky && $('body').hasClass('no-scroll') && parseInt(document.body.style.top || '0', 10) < 0) {
		shouldBeSticky = true;
	}

	if (typeof initialPadding === 'undefined') {
		initialPadding = parseInt($main.css('padding-top'), 10);
	}

	if (shouldBeSticky) {
		if ($scrollParent.scrollTop() > uspBannerHeight) {
			$header.css('top', - uspBannerHeight);
			$main.css('padding-top', initialPadding + $header.innerHeight());
			// following line should be kept after the previous 2 lines, otherwise it causes an additional scroll event which causes sticky header to be removed again
			$topDivs.addClass('sticky-header');

			var appSmartBanner = $('.js_smartbanner');

			if (appSmartBanner.length > 0) {
				$('[data-js="nav-container"]').addClass('smart-banner');
			}
		}
		else {
			$topDivs.removeClass('sticky-header');
			$header.css('top', 0);
			$main.css('padding-top', initialPadding);
			$('[data-js="nav-container"]').removeClass('smart-banner');
		}
	}
	else {
		$header.css('top', 0);
		$topDivs.removeClass('sticky-header');
		$main.css('padding-top', initialPadding);
		$('[data-js="nav-container"]').removeClass('smart-banner');
	}
}

exports.init = function () {
	stickyHeader();
};
