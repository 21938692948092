var dialog = require('../../dialog'),
	progress = require('../../progress'),
	ajax = require('../../ajax');

var proceedFunction;


function davCheck(data, proceedFunctionCallback) {
	data.push({ name: 'action', value: 'verifyAddress' });
	proceedFunction = proceedFunctionCallback;

	ajax.load({
		url: Urls.checkoutSubmitForm,
		type: 'POST',
		data: data,
		keepLoaderOpen: true,
		callback: function(responseHtml) {
			if (!responseHtml) {
				proceedFunction();
				return;
			}

			var response = $(responseHtml);
			var verifyAddressBlock = response.filter('.js-verify-address-block');

			if (!verifyAddressBlock.children().length) {
				proceedFunction();
				return;
			}

			// In case that we have skipDAVDialog data js we are applying values without opening DAV result dialog
			var skipDAVDialog = verifyAddressBlock.find('[data-js="skipDAVDialog"]');
			if (skipDAVDialog.length) {
				applyStandardizedAddress(verifyAddressBlock.find('[data-js="address-standardized"]'));
				proceedFunction();
				return;
			}

			// if we're not submitting the order, hide the loader
			progress.hide();

			dialog.open({
				html: verifyAddressBlock,
				options: {
					closeOnEscape: false,
					dialogClass: 'no-close',
					open: davDialogEvents
				}
			});
		}
	});
}

/**
 * Applies address data from [StandardizedAddress] element to checkout shipping address fields
 * @param {Object} StandardizedAddress
 */
function applyStandardizedAddress(StandardizedAddress) {
	var stdAddr = $(StandardizedAddress).data('standardized');
	var $checkoutForm = $('#dwfrm_checkout');

	// update form field values
	$checkoutForm.find("input[name*='shipping_address_address1']").val(stdAddr.address1);
	$checkoutForm.find("input[name*='shipping_address_address2']").val(stdAddr.address2 || '');
	$checkoutForm.find("input[name*='shipping_address_city']").val(stdAddr.city);
	$checkoutForm.find("input[name*='shipping_address_postal']").val(stdAddr.postalCode);
	$checkoutForm.find("select[name*='shipping_address_states_state']").val(stdAddr.state);
	$checkoutForm.find("input[name*='shipping_address_country']").val(stdAddr.country);
}

/**
 * Attaches events to the DAV dialog
 */
function davDialogEvents() {
	$(this).on('click', '[data-js="address-standardized"]', function(e) {
		//if customer select new (verified) address we update values and proceed
		e.preventDefault();

		applyStandardizedAddress(this);
		proceedFunction(true);

		dialog.close();
	}).on('click', '[data-js="address-original"]', function(e) {
		//if customer select original address we just proceed
		e.preventDefault();

		proceedFunction();
		dialog.close();
	}).on('click', '[data-js="option-update"]', function(e) {
		//if customer clicks on update, close the dialog in case the edit view is active, or open the edit mode if not
		e.preventDefault();
		dialog.close();
		if ($('[data-js="edit-shipping-details"]').length > 0) {
			$('[data-js="edit-shipping-details"]').trigger('click');
		}
		else {
			progress.hide();
		}
	})
		.on('click', '[data-js="option-proceed"]', function(e) {
		//if customer options to proceed, just proceed
			e.preventDefault();

			proceedFunction();
			dialog.close();
		});
}

exports.davCheck = davCheck;