'use strict';

var MarkerClusterer = require('node-js-marker-clusterer'),
	util = require('../../../util');

var resultsObject = null;
var map = null;
var markers = [];
var visibleMarkers = [];
var nearMeSearchFlag = false;
var sortBy = '';

var mapLat = $('#js-googlemapstores').data('lat');
var mapLong = $('#js-googlemapstores').data('long');
var mapCountryLat = $('#js-googlemapstores').data('country-lat');
var mapCountryLong = $('#js-googlemapstores').data('country-long');
var mapZoom = $('#js-googlemapstores').data('zoom');

var unitMultiplier = $('#js-distance-unit-multiplier').val();
var currentCountry = $('#js-currentcountry').val();
var locale = $('#js-currentlocale').val().split('_')[0];

function init(results, sortByFilter) {
	sortBy = sortByFilter;
	resultsObject = results;
	initMap();
}

/**
 * Initializes map and markers
 */
function initMap() {
	var mapElement = document.getElementById('js-googlemapstores');

	map = null;
	markers = [];
	visibleMarkers = [];

	$.getScript('//maps.googleapis.com/maps/api/js?key=' + SitePreferences.GOOGLEMAP_APIKEY + '&libraries=geometry&language=' + locale,
		function () {
			var mapCenter = {
				lat: mapLat,
				lng: mapLong
			};

			map = new google.maps.Map(mapElement, {
				center: mapCenter,
				scrollwheel: true,
				zoom: mapZoom,
				minZoom: 3
			});

			google.maps.event.addListener(map, 'zoom_changed', function () {
				updateVisibleMarkers();
			});

			google.maps.event.addListener(map, 'center_changed', function () {
				nearMeSearchFlag = false;
				resultsObject.updateNearMeNotification(false);
				updateVisibleMarkers();
			});

			util.getLocationCoordinates().then(
				function (coords) {
					map.setCenter(coords);
					prepareMarkers();
				},
				function () {
					prepareMarkers();
				}
			);
		});
}

/**
 * Prepares markers. First removes all markers from the map,
 * Second initializes markers and lastly adds them to clusterer.
 */
function prepareMarkers() {
	removeMarkers();
	addMarkersToTheMap();
	createCluster();
}

/**
 * Removes all markers from the map
 */
function removeMarkers() {
	if (!markers || !markers.length) {
		return;
	}

	for (var i = 0; i < markers.length; i++) {
		markers[i].setMap(null);
	}
	markers = [];
}

/**
 * Initializes markers
 */
function addMarkersToTheMap() {
	if (storesJson == null || storesJson.length == 0) {
		return;
	}

	$.each(storesJson, function(i, store) {
		var markerPosition = new google.maps.LatLng(store.latitude, store.longitude);

		var iconUrl = window.Urls.staticPath
			+ (store.name.toLowerCase().indexOf('ecco') > -1 ? 'img/mixed/icon-shop-ecco.png' : 'img/mixed/icon-shop-default.png');

		var marker = new google.maps.Marker({
			position: markerPosition,
			icon: iconUrl,
			city: store.city,
			country: store.countryCode,
			storeID: store.storeID,
			name: store.name,
			address1: store.address1,
			address2: store.address2,
			data: store
		});

		markers.push(marker);

		google.maps.event.addListener(marker, 'click', function () {
			resultsObject.openStoreDetail(marker);
		});
	});
}

/**
 * Creates map marker clusterer containing all the stores
 */
function createCluster() {
	var clusterStyles = [{
		url: window.Urls.staticPath + 'img/mixed/icon-shop-cluster.png',
		height: 32,
		width: 32,
		textColor: '#fff'
	}];
	var clusterOptions = {
		gridSize: 40,
		styles: clusterStyles,
		maxZoom: 15
	};
	var markerCluster = new MarkerClusterer(map, markers, clusterOptions);
}

/**
 * Prepares all visible markers, sorts them and calls displayStoreResults from resultsObject in order to display them.
 */
function updateVisibleMarkers() {
	visibleMarkers = [];
	resultsObject.resetLoadedStores();

	var searchBounds = map.getBounds();
	var centerOfMap = map.getCenter();

	if (!markers || !markers.length || !searchBounds) {
		resultsObject.displayStoreResults(visibleMarkers);
	}

	for (var i = 0; i < markers.length; i++) {
		var storeMarker = markers[i];
		if (!searchBounds.contains(storeMarker.getPosition())) {
			continue;
		}

		storeMarker.distance = google.maps.geometry.spherical.computeDistanceBetween(storeMarker.position, centerOfMap);
		visibleMarkers.push(storeMarker);
	}

	sortVisibleMarkers();
	resultsObject.displayStoreResults(visibleMarkers);
}

/**
 * Sorts the visible markers either by distance or by name.
 * We use global sortBy flag for the sort by type.
 * Distance - closer stores first
 * Name - ecco stores first (also sorts by distance)
 */
function sortVisibleMarkers() {
	visibleMarkers.sort(function (a, b) {
		var firstStoreDistance = a.distance;
		var secondStoreDistance = b.distance;

		var firstStoreName = a.name;
		var secondStoreName = b.name;

		// sort by distance
		if (sortBy == 'bydistance') {
			return sortByDistance(firstStoreDistance, secondStoreDistance);
		}

		var firstStoreIsEcco = firstStoreName.toLowerCase().indexOf('ecco') > -1;
		var secondStoreIsEcco = secondStoreName.toLowerCase().indexOf('ecco') > -1;

		if ((firstStoreIsEcco && secondStoreIsEcco) || (!firstStoreIsEcco && !secondStoreIsEcco)) {
			return sortByDistance(firstStoreDistance, secondStoreDistance);
		}

		if (firstStoreIsEcco) {
			return -1;
		}

		return 1;
	});
}

/**
 * Returns the sort values for sort by distance (lower to higher)
 * @param {String} firstStoreDistance
 * @param {Number} secondStoreDistance
 * @returns {Number}
 */
function sortByDistance(firstStoreDistance, secondStoreDistance) {
	if (firstStoreDistance < secondStoreDistance) {
		return -1;
	}

	if (firstStoreDistance > secondStoreDistance) {
		return 1;
	}

	return 0;
}

/**
 * Entry function for search functionality, based on provided flags we trigger we call either:
 *  -nearMeSearch (used for near me, either browser or IP location is used)
 *  -searchForCityOrPostcode (regular search, uses google map geocoder for location)
 *  -updateMap (just updates the map, used for distance selector)
 * @param {String} searchLocation
 * @param {Number} maxDistance
 * @param {Boolean} isWholeCountry
 * @param {Boolean} keepCenter
 */
function searchAddress(searchLocation, maxDistance, isWholeCountry, keepCenter) {
	var searchObj = {
		maxDistance: maxDistance,
		center: new google.maps.LatLng(mapCountryLat, mapCountryLong),
		keepCenter: keepCenter
	};

	if (keepCenter) {
		updateMap(searchObj);
		return;
	}

	if (nearMeSearchFlag) {
		nearMeSearch(maxDistance);
		return;
	}

	if (isWholeCountry) {
		updateMap(searchObj);
		return;
	}

	if (!searchLocation) {
		searchObj.center = map.getCenter();
		updateMap(searchObj);
		return;
	}

	nearMeSearchFlag = false;
	searchForCityOrPostcode(searchLocation, maxDistance);
}

/**
 * Searches for stores on current location (either browser or IP location)
 * @param {String} searchLocation
 * @param {Number} maxDistance
 */
function nearMeSearch(distance) {
	var mapCenter = new google.maps.LatLng(mapLat, mapLong);
	var searchObj = {
		maxDistance: distance,
		center: mapCenter
	};

	util.getLocationCoordinates().then(
		function (coords) {
			if ('lat' in coords && 'lng' in coords) {
				searchObj.center = new google.maps.LatLng(coords.lat, coords.lng);
				updateMap(searchObj);
				nearMeSearchFlag = true;
				resultsObject.updateNearMeNotification(true);
			}
			else {
				nearMeSearchFlag = false;
				resultsObject.updateNearMeNotification(false);
				resultsObject.displayStoreResultsError(Resources.STORE_NOTDETERMINED);
			}
		},
		function () {
			updateMap(searchObj);
			nearMeSearchFlag = true;
			resultsObject.updateNearMeNotification(true);
		}
	);
}

/**
 * Searches for provided location using google map geocoder.
 * If we have some result we update the map, otherwise we display an error message.
 * @param {String} searchLocation
 * @param {Number} maxDistance
 */
function searchForCityOrPostcode(searchLocation, maxDistance) {
	var geocoder = new google.maps.Geocoder();

	var searchData = {
		address: searchLocation,
		componentRestrictions: { country: currentCountry }
	};

	searchData.address = formatSearchLocation(searchLocation);

	geocoder.geocode(searchData, function (results, status) {
		if (status !== google.maps.GeocoderStatus.OK) {
			var errorMsg = status === 'ZERO_RESULTS' ? Resources.STORE_NO_RESULT : Resources.STORE_ERROR;
			resultsObject.displayStoreResultsError(errorMsg);
			return;
		}

		if (!results || !results.length || !results[0].address_components.length) {
			resultsObject.displayStoreResultsError(Resources.STORE_NO_RESULT);
			return;
		}

		if (results[0].address_components[0].length === 1
			&& results[0].address_components[0].toLowerCase() === currentCountry.toLowerCase()) {
			resultsObject.displayStoreResultsError(Resources.STORE_NO_RESULT);
			return;
		}

		var searchObj = {
			maxDistance: maxDistance
		};

		if (maxDistance) {
			searchObj.center = results[0].geometry.location;
			updateMap(searchObj);
			return;
		}

		searchObj.bounds = results[0].geometry.viewport;
		updateMap(searchObj);
	});
}

/**
 * Updates map bounds and map center based on searchObject.
 * Search object can contain:
 * 	-center (new center of the map)
 * 	-bounds (new map bounds)
 * 	-maxDistance (used for calculating new bounds in case that we don't have bounds provided)
 * 	-keepCenter (should we update both center and bounds, or just bounds)
 * @param {Object} searchObject
 */
function updateMap(searchObject) {
	var center = searchObject.center;
	var bounds = searchObject.bounds;
	var maxDistance = searchObject.maxDistance;
	var keepCenter = searchObject.keepCenter;

	//We need either bounds or center/maxDistance combination in order to update map
	if ((!center && !bounds) || (!bounds && !maxDistance)) {
		return;
	}

	//if we don't have center we can calculate it from bounds
	if (!center) {
		center = bounds.getCenter();
	}

	//for bounds if we don't have them we can calculate it using center and maxDistance,
	//but if we have it we should check if we have a visible store (if not we should extend the bounds)
	if (!bounds) {
		var calculatedDistance = maxDistance * unitMultiplier;
		var topRightCoordinate = google.maps.geometry.spherical.computeOffset(center, calculatedDistance, 45);
		var bottomLeftCoordinate = google.maps.geometry.spherical.computeOffset(center, calculatedDistance, -135);
		bounds = new google.maps.LatLngBounds(bottomLeftCoordinate, topRightCoordinate);
	}
	else {
		extendBounds(bounds);
	}

	map.fitBounds(bounds);
	if (!keepCenter) {
		map.setCenter(center);
	}
}

/**
 * Extends map bounds to include closest marker
 * @param {Object} originalBounds
 */
function extendBounds(originalBounds) {
	if (!markers || markers.length === 0) {
		return;
	}

	var closestMarker;

	for (var i = 0; i < markers.length; i++) {
		var marker = markers[i];
		if (originalBounds.contains(marker.getPosition())) {
			break;
		}

		marker.distance = google.maps.geometry.spherical.computeDistanceBetween(marker.position, originalBounds.getCenter());

		if (!closestMarker || marker.distance < closestMarker.distance) {
			closestMarker = marker;
		}
	}

	if (!!closestMarker) {
		originalBounds.extend(closestMarker.position);
	}
}

/**
 * Formats the search location for the AU market
 * @param {String} searchLocation
 * @returns {String}
 */
function formatSearchLocation(searchLocation) {
	if (currentCountry.toLowerCase() != 'au') {
		return searchLocation;
	}

	return util.modifyPostalCodeSearch(searchLocation, currentCountry);
}

/**
 * Updates sort by filter and triggers updateVisibleMarkers.
 * By doing this we refresh currently visible markers so the new sort by filter is applied
 * @param {Boolean} isActive
 */
function sortStoreResults(sortByFilter) {
	sortBy = sortByFilter;
	updateVisibleMarkers();
}

/**
 * Set near me search flag
 * @param {Boolean} isActive
 */
function updateNearMeFlag(isActive) {
	nearMeSearchFlag = isActive;
}

/**
 * Returns all currently visible stores
 * @returns {Array}
 */
function getVisibleStores() {
	return visibleMarkers;
}

module.exports = {
	init: init,
	searchAddress: searchAddress,
	getVisibleStores: getVisibleStores,
	sortStoreResults: sortStoreResults,
	updateNearMeFlag: updateNearMeFlag
};