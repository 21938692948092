'use strict';

var dialog = require('../dialog'),
	util = require('../util'),
	accessibility = (util.isMobile()) ? require('../accessibilityForMobile') : require('../accessibility');

exports.init = function init () {
	$('.privacy-policy, .js-open-url-in-lightbox').on('click', function(e) {
		e.preventDefault();
		dialog.open({
			url: $(e.target).attr('href'),

			callback: function() {
				// service pages might contain links which cause a bug in jquery dialogs
				// https://stackoverflow.com/questions/19038346/jquery-dialog-box-auto-scrolling-to-bottom
				// fortunatelly we have controll via the rendering template
				$('.js-service-page-block').scrollTop(0);

				// Keyboard accessibility
				$('.js-service-page-block').attr('tabindex', '0');
				$('.js-service-page-block').trigger('focus');
				$('.js-service-page-block').closest('.dialog-content').removeAttr('tabindex');
			},
			options: {
				dialogClass: 'service-dialog'
			}
		});
		return false;
	});


	$('.legal-link.has-content').on('click', function (e) {
		var legalContent = $(this).siblings('.lightbox-content').html();
		e.preventDefault();
		if (legalContent) {
			var keyboardNavActive = $(this).hasClass('focus-visible');
			dialog.open({
				html: legalContent,
				options: {
					dialogClass: 'legal-content-dialog'
				},
				callback: function() {
					accessibility.setKeyboardNavigation(keyboardNavActive);
					accessibility.attachDialogEvents();
				}
			});
		}
	});
};