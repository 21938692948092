'use strict';

var giftcert = require('../giftcert'),
	util = require('../util'),
	detailsmodaldialog = require('../detailsmodaldialog'),
	page = require('../page'),
	login = require('../login'),
	forms = require('../forms'),
	payment = require('../payment'),
	addressComplete = require('../addresscomplete'),
	progress = require('../progress'),
	ajax = require('../ajax'),
	registerform = require('../registerform'),
	tooltip = require('../tooltip');

/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
	forms.init();
	toggleFullOrder();
	initMenuHighlight();
	initAddressEvents();
	initPaymentEvents();
	registerform.init();
	login.init();
	payment.init();
	tooltip.init();
	initSizeEvents();
	initProductOrderHistory();
	initSkipLinks();
	addressComplete.init('account');
	tooltip.initializeCustomTooltip();
	$('button[name="dwfrm_profile_confirm"]').off('click').on('click', submitForm);
	initIntermediaryLogin();
}

/**
 * Checks the form before submitting it.
 * @param {Object} e
 */
function submitForm(e) {
	e.preventDefault();
	var $form = $(this).closest('form');
	var isFormValid = $form.valid();
	var isReadonlyPostalCodeValid = true;

	var $readonlyPostalCode = $('.js-address-postal-code.readonly');
	if ($readonlyPostalCode.length > 0) {
		isReadonlyPostalCodeValid = $readonlyPostalCode.valid();
	}

	//if either form or readonly postal code aren't valid don't submit the form
	if (!isFormValid || !isReadonlyPostalCodeValid) {
		return;
	}

	var $accountCreationBtn = $('[data-js="create-account"]');
	$accountCreationBtn.attr('disabled', 'disabled');
	$accountCreationBtn.html(Resources.CREATING_ACCOUNT);

	$form.submit();
}

function initSizeEvents() {
	// event listener for gender select drop down - used on account personal information page
	var $genderSelect = $('[data-js="personal-information"] #dwfrm_profile_customer_gender');
	$genderSelect.on('change', function() {
		if ($genderSelect.val().length == 0 || this.value == '3') {
			$('.js-size-chart-link').attr('href', Urls.genderAllChart);
		}
		else if (this.value == '1') {
			$('.js-size-chart-link').attr('href', Urls.genderMaleChart);
		}
		else if (this.value == '2') {
			$('.js-size-chart-link').attr('href', Urls.genderFemaleChart);
		}
		updateSizeBlock();
	});

	// event listener for gender radio button group - used on account registration page
	var $genderRadioBtn = $('[data-js="account-registration"] input[name="dwfrm_profile_customer_gender"]');
	$genderRadioBtn.on('change', function() {
		$('.js-size-msg').remove();
		if ($genderRadioBtn.val().length == 0 || this.value == '3') {
			$('.js-size-chart-link').attr('href', Urls.genderMaleChart);
			updateSizeSelect('prefernottosay', true);
		}
		else if (this.value == '1') {
			$('.js-size-chart-link').attr('href', Urls.genderMaleChart);
			updateSizeSelect('male', true);
		}
		else if (this.value == '2') {
			$('.js-size-chart-link').attr('href', Urls.genderFemaleChart);
			updateSizeSelect('female', true);
		}
	});

	// populates size select on page load if a gender option is selected - used on account registration page
	var $selectedGenderRadio = $('input.input-radio.gender:checked');
	if ($selectedGenderRadio.length > 0) {
		if ($('input.input-radio.gender:checked').val() == '3') {
			$('.js-size-chart-link').attr('href', Urls.genderMaleChart);
			updateSizeSelect('prefernottosay', false);
		}
		else if ($('input.input-radio.gender:checked').val() == '1') {
			$('.js-size-chart-link').attr('href', Urls.genderMaleChart);
			updateSizeSelect('male', false);
		}
		else if ($('input.input-radio.gender:checked').val() == '2') {
			$('.js-size-chart-link').attr('href', Urls.genderFemaleChart);
			updateSizeSelect('female', false);
		}
	}

	// event listener that prevents selecting size before gender
	$(document).on('mousedown', '#dwfrm_profile_customer_shoesizedefault', function(e) {
		if (($genderRadioBtn.length > 0 && !$('[data-js="account-registration"] input[name="dwfrm_profile_customer_gender"]:checked').val())
			|| ($genderSelect.length > 0 && $genderSelect.val().length === 0)) {
			e.preventDefault();
			$('.js-size-msg').remove();
			var infoMsg = '<span class="warning-msg js-size-msg" role="alert">' + Resources.SHOE_SIZE_WARNING + '</span>';
			$(this).parents('.select-container').append(infoMsg);
		}
	});

	$('.js-size-chart-link').on('click', function(e) {
		e.preventDefault();
		var $this = $(this);
		var hasExpandedAttr = $this[0].hasAttribute('aria-expanded');

		var dialogParams = {
			url: $this.attr('href'),
			options: {
				dialogClass: 'account-size-chart-content size-chart-content',
				open: function() {
					//Toggle data-expanded
					if (hasExpandedAttr) {
						$this.attr('aria-expanded', true);
					}
				},
				close: function () {
					//Toggle data-expanded
					if (hasExpandedAttr) {
						$this.attr('aria-expanded', false);
					}
				}
			},
			focusVisible: $this.hasClass('focus-visible')
		};
		detailsmodaldialog.open(dialogParams);
	});
}

// updating size select drop down on personal information page
function updateSizeBlock() {
	var data = $('[data-js="personal-information"] #dwfrm_profile_customer_gender').serializeArray();
	progress.show('#main-content');

	ajax.load({
		url: Urls.updateShoeSizes,
		type: 'post',
		data: data,
		callback: function(data) {
			$('#dwfrm_profile_customer_shoesizedefault').replaceWith($(data).find('#dwfrm_profile_customer_shoesizedefault'));
			$('.js-size-msg').remove();
			progress.hide();
		}
	});
}

// updating size select drop down on account registration page
function updateSizeSelect(gender, isGenderChanged) {
	$('#dwfrm_profile_customer_shoesizedefault').children().remove().end()
		.append('<option label="Select" value>Select</option>');

	var sizesPerGenderJSON = $('.js-sizes-per-gender').val();
	var sizesPerGenderObj = JSON.parse(sizesPerGenderJSON);
	var selectedSize = $('.js-selected-size').val();
	$.each(sizesPerGenderObj[gender], function (i, item) {
		$('#dwfrm_profile_customer_shoesizedefault').append($('<option>', {
			value: item.value,
			text: item.label,
			label: item.label
		}));
		if (selectedSize == item.value && !isGenderChanged) {
			$('#dwfrm_profile_customer_shoesizedefault')[0].children[i + 1].setAttribute('selected', 'selected');
			$('#dwfrm_profile_customer_shoesizedefault').change();
		}
	});
}
/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder () {
	$('.order-items')
		.find('li.hidden:first')
		.prev('li')
		.append('<a class="toggle">View All</a>')
		.children('.toggle')
		.on('click', function () {
			$(this).parent().siblings('li.hidden').show();
			$(this).remove();
		});
}

/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
	var addresses = $('.js-address-block');
	if (addresses.length === 0) { return; }

	addresses.on('click', '.js-delete-address', function (e) {
		e.preventDefault();
		if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
			$.ajax({
				url: util.appendParamToURL($(this).attr('href'), 'format', 'ajax'),
				dataType: 'json'
			}).done(function (data) {
				if (data.status.toLowerCase() === 'ok') {
					page.redirect(Urls.addressesList);
				}
				else if (data.message.length > 0) {
					window.alert(data.message);
				}
				else {
					page.refresh();
				}
				util.eraseCookie('_preferred_shipping_address_id');
				util.eraseCookie('_preferred_billing_address_id');
			});
		}
	});


	$('.js-address-default').on('change', function (e) {
		e.preventDefault();
		util.eraseCookie('_preferred_shipping_address_id');
		util.eraseCookie('_preferred_billing_address_id');
		window.location = $(this).data('href');
	});

	$('button[name="dwfrm_profile_address_create"]').off('click').on('click', submitForm);
	$('button[name="dwfrm_profile_address_edit"]').off('click').on('click', submitForm);
}
/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
	var paymentList = $('.payment-list');
	if (paymentList.length === 0) {
		// we don't have new credit card form on Account Pages with Adyen
		/*if (SitePreferences.ADYEN_ENABLED && SitePreferences.ADYEN_SF_ENABLED) {
			adyenCheckout.initAccount();
		}*/
		return;
	}

	util.setDeleteConfirmation(paymentList, String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD));

	$('form[name="payment-remove"]').on('submit', function (e) {
		e.preventDefault();
		// override form submission in order to prevent refresh issues
		var button = $(this).find('.js-delete-card');
		$('<input/>').attr({
			type: 'hidden',
			name: button.attr('name'),
			value: button.attr('value') || 'delete card'
		}).appendTo($(this));
		var data = $(this).serialize();
		$.ajax({
			type: 'POST',
			url: $(this).attr('action'),
			data: data
		})
			.done(function () {
				page.redirect(Urls.paymentsList);
			});
	});

	$('.js-payment-default').on('change', function (e) {
		e.preventDefault();
		window.location = $(this).data('href');
	});
}

function getSectionFromUrl(url) {
	var currentSection = '';
	try {
		currentSection = url.substr(url.lastIndexOf('/') + 1);
	}
	catch (e) {

	}
	return currentSection;
}

function isCurrentUrl(url) {
	var currentUrl = location.protocol + '//' + location.host + location.pathname;
	return (getSectionFromUrl(currentUrl) == getSectionFromUrl(url));
}

/**
 * The left hand menu in the account page is a content asset, editable and not dynamically generated.
 * Therefore the "current" page is not linked to it.
 * To highlight the current page the URL is used - if it matches one of the links on the page, we add a class.
 */
function initMenuHighlight() {
	var $menu = $('.account-nav-asset ul');
	var currentElement = $('#account-section-parent').val() ? $('#account-section-parent').val() : false;

	$menu.find('li').find('a').each(function(index, element) {
		var $el = $(element);
		if (currentElement == getSectionFromUrl($el.attr('href')) || isCurrentUrl($el.attr('href'))) {
			$(element).addClass('active');
		}
	});
}

function initProductOrderHistory() {
	$('.js-order-detail').off('click').on('click', function() {
		var $this = $(this);
		var expanded = $this.attr('aria-expanded') !== 'true';

		$this.attr('aria-expanded', expanded);
		$this.closest('.order-list-item')
			.toggleClass('is-open')
			.find('.order-list-item-product').slideToggle();
	});

	$('#loadMore').on('click', function(e) {
		e.preventDefault();
		progress.show();

		var start = $('.order-history-item').length;
		$.ajax({
			url: Urls.ordersLoadMore,
			data: {
				format: 'ajax',
				start: start,
				sz: SitePreferences.ORDERS_PER_LOAD_MORE
			}
		}).always(function() {
			progress.hide();
		}).done(function (response) {
			progress.hide();

			if (response && $(response).find('.order-item')) {
				$('.order-history-list').append($(response).find('.order-history-list').html());
				$('.order-history-btn__wrapper').replaceWith($(response).find('.order-history-btn__wrapper'));

				initProductOrderHistory();
			}
			else {
				$('#loadMore').hide();
				$('.order-history-list').scrollTop();
			}
		});

		return false;
	});
}

function initSkipLinks() {
	$('.js-skip-account-menu').on('click', function() {
		var $el = $('.account__inner').find(':visible:focusable:first');
		$el.addClass('focus-visible').attr('data-focus-visible-added', '');
		$el.trigger('focus');
	});
}

/**
 * Events for Intermediary Login Screen - part of A/B Test (Epic SFCC-2769)
 */
function initIntermediaryLogin() {
	$('body').off('click', '[data-js="intermediary-checkout-button"]')
		.on('click', '[data-js="intermediary-checkout-button"]', function(e) {
			var trackingAction = $(this).data('tracking-action');
			$(document).trigger('intermediaryLoginClicked', trackingAction);
		});
}

var account = {
	init: function () {
		initializeEvents();
		giftcert.init();
	},
	initCartLogin: function () {
		login.init();
	}
};

module.exports = account;
