'use strict';

var cartreminder = require('../cartreminder'),
	util = require('../util');

function exitIntentEvent(e) {
	if (!e.toElement && !e.relatedTarget && e.clientY < 10) {
		document.removeEventListener('mouseout', exitIntentEvent);
		// show cart reminder when customer tries to leave
		cartreminder.show('leave');
		util.createCookie('exit_intent_popup', 'shown');
	}
}

exports.init = function init () {
	var cartNotEmpty = document.querySelector('[data-js="mini-cart-not-empty"]');
	var isEditMode = $('.page-designer-ui', window.parent.document).length > 0;

	if (cartNotEmpty && !isEditMode && window.pageContext.ns !== 'cart' && window.pageContext.ns !== 'checkout') {
		// show cart reminder when customer arrives back
		if (SitePreferences.SHOW_CART_REMINDER_ON_ENTER && !util.readCookie('remindershown')) {
			cartreminder.show('return');
		}
		if (SitePreferences.SHOW_CART_REMINDER_ON_EXIT && !util.readCookie('exit_intent_popup')) {
			document.addEventListener('mouseout', exitIntentEvent);
		}
	}

	if (SitePreferences.SHOW_CART_REMINDER_ON_ENTER) {
		// set a cookie value to prevent reminder popup shown again while user is still on the website
		util.createCookie('remindershown', true);
	}
}
