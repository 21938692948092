'use strict';

var trackingHelper = require('./trackingHelper'),
	customEvents = require('./checkout'),
	ometriaTracker = require('./ometriaTracking');
/**
 * This pushes the Unique User-ID when a visitor is a known customer and logged-in
 */
function userAuthenticated(userID) {
	var trackingObj = {
		'event': 'authenticate',
		'userID': userID
	};
	trackingHelper.pushToDataLayer(trackingObj);
}

/**
 * When a visitor logs into his personal account the following event is fired
 */
function userLoggedIn() {
	var trackingObj = {
		'event': 'GA Tracking',
		'eventCategory': 'Account',
		'eventAction': 'Account Login'
	};
	trackingHelper.pushToDataLayer(trackingObj);
}

/**
 * When a client registers his personal account the following event is fired
 */
function userRegistered() {
	var trackingObj = {
		'event': 'GA Tracking',
		'eventCategory': 'Account',
		'eventAction': 'Account Registration'
	};
	trackingHelper.pushToDataLayer(trackingObj);
}
/**
 * Function used to extract email for ometria identify event and for user to be identified on every page
 */
function ometriaUserTracking() {
	if(typeof ometria === 'undefined') {
		return;
	}

	var ometriaUserContainer = $('#js-ometria-user-object');
	if(ometriaUserContainer && ometriaUserContainer.length > 0) {
		var ometriaTrackingValue = ometriaUserContainer.val();

		if(ometriaTrackingValue) {
			var trackingData = JSON.parse(ometriaTrackingValue);
			var authenticatedUserMail = trackingData.authenticatedUserMail;
			
			if (authenticatedUserMail) {
				ometria.identify(authenticatedUserMail);
			}
		}
	}
}

function userTracking() {
	var userIdContainer = $('#js-user-id');

	// if there is a logged in authenicated user
	if (userIdContainer && userIdContainer.length > 0) {
		var trackingDataValue = userIdContainer.val();

		if (trackingDataValue) {
			var trackingData = JSON.parse(trackingDataValue);

			var userId = trackingData.authenticatedUserId;
			userAuthenticated(userId);
			var userAction = trackingData.userAction;
			if (userAction) {
				if (userAction === 'userlogin') {
					userLoggedIn();
				}
				else if (userAction === 'userregister') {
					userRegistered();
				}
			}
		}
	}
}

/**
 * Pushes the amount of search results
 */
function searchResultsTracking() {
	var resultsContainer = $('[data-js="resultscontainer"]');

	if (resultsContainer && resultsContainer.length > 0) {
		var resultsCount = resultsContainer.data('resultcount');

		var trackingObj = {
			'event': 'searchResults',
			'numOfProducts': resultsCount
		};
		trackingHelper.pushToDataLayer(trackingObj);
	}
}

/**
 * Pushes tracking data layer object for the active AB Tests
 */
function abTestTracking() {
	var dataContainer = $('#js-ab-test-tracking');

	if (dataContainer && dataContainer.length > 0) {
		var trackingDataValue = dataContainer.val();

		if (trackingDataValue) {
			var trackingData = JSON.parse(trackingDataValue);

			for (var i = 0; i < trackingData.length; i++) {
				var trackingObj = {
					'event': 'experimentEvent',
					'experimentID': trackingData[i].ABTestID,
					'experimentvariant': trackingData[i].ID
				};
				trackingHelper.pushToDataLayer(trackingObj);
			}
		}
	}
}

/**
 * Pushes purchase tracking data
 */
function purchaseTracking() {
	var dataContainer = $('#js-purchases-tracking');

	if (dataContainer && dataContainer.length > 0) {
		var trackingDataValue = dataContainer.val();

		if (trackingDataValue) {
			var trackingData = JSON.parse(trackingDataValue);
			trackingHelper.pushToDataLayer(trackingData);
		}
	}
}

/**
 * When a user filters or sorts the PDP
 */
function filteringOrSortingEvent(event, filterName, filterValue) {
	var trackingObj = {
		'event': 'GA Tracking',
		'eventCategory': 'Product Filter',
		'eventAction': filterName,
		'eventLabel': filterValue,
		'eventValue': 0,
		'eventNonInteraction': false
	};
	trackingHelper.pushToDataLayer(trackingObj);
}

/**
 * when a popular items suggestion or search suggestion is clicked on quick search window
 */
function trackQuickSearchItemClick(action, label, destinationUrl) {
	if (destinationUrl) {
		var trackingObj = {
			'event': 'GA Tracking',
			'eventCallback': function () {
				document.location = destinationUrl;
			},
			'eventTimeout': 1000,
			'eventCategory': 'Quick Search',
			'eventAction': action,
			'eventLabel': label,
			'eventValue': 0,
			'eventNonInteraction': false
		};
		trackingHelper.pushToDataLayer(trackingObj);

		return;
	}

	var trackingObj = {
		'event': 'GA Tracking',
		'eventCategory': 'Quick Search',
		'eventAction': action,
		'eventLabel': label,
		'eventValue': 0,
		'eventNonInteraction': false
	};
	trackingHelper.pushToDataLayer(trackingObj);
}

/**
 * Gets necessary information to trach cart values
 */
function getCartTotalAndQuantities() {
	var cartTotalAndQuantities = {};
	var _cartValue = $('.js-order-subtotal').attr('data-ordertotal') ? $('.js-order-subtotal').attr('data-ordertotal') : $('.mini-cart-subtotals').attr('data-ordertotal');
	var _cartDiscountValue = $('[data-js="order-discount"]').attr('data-order-discount') ? $('[data-js="order-discount"]').attr('data-order-discount') : $('.mini-cart-subtotals').attr('data-order-discount');

	var _cartQuantity = $('.items-counter').attr('data-cartquantity') ? $('.items-counter').attr('data-cartquantity') : $('.js-mini-cart').attr('data-cartquantity');
	var _cartProductQuantity = $('.items-counter').attr('data-totaluniqueitems') ? $('.items-counter').attr('data-totaluniqueitems') : $('.js-mini-cart').attr('data-totaluniqueitems');

	if (SitePreferences.IS_RESKIN) {
		_cartQuantity = $('[data-js="items-counter"]').attr('data-cartquantity') ? $('[data-js="items-counter"]').attr('data-cartquantity') : $('.js-mini-cart').attr('data-cartquantity');
		_cartProductQuantity = $('[data-js="items-counter"]').attr('data-totaluniqueitems') ? $('[data-js="items-counter"]').attr('data-totaluniqueitems') : $('.js-mini-cart').attr('data-totaluniqueitems');
	}

	cartTotalAndQuantities = {
		cartValue: _cartValue,
		cartQuantity: _cartQuantity,
		cartProductQuantity: _cartProductQuantity
	};

	if (_cartDiscountValue) {
		cartTotalAndQuantities.cartDiscountValue = _cartDiscountValue;
	}

	return cartTotalAndQuantities;
}

/**
 * Event that triggered when the cart is completely cleared
 */
function clearCart() {
	var event = 'clearCart';

	var trackingObj = {
		'event': event,
		'cartValue': '0',
		'cartQuantity': '0',
		'cartProductQuantity': '0',
		'eventNonInteraction': true
	};
	trackingHelper.pushToDataLayer(trackingObj);
}

function cartTracking() {
	var cartTotalAndQuantities = getCartTotalAndQuantities();
	trackingHelper.pushToDataLayer(cartTotalAndQuantities);
}

/**
 * Triggerred updatedCart event when user add product or remove it, we update this event on multiple pages as required
 */
function updatedCart() {
	var cartTotalAndQuantities = getCartTotalAndQuantities();

	if (typeof ometria !== 'undefined') {
		ometriaTracker.ometriaTrackBasket();
	}

	if (SitePreferences.IS_RESKIN) {
		if (pageContext.gtmPageType == 'Cart' && !$('[data-js="items-counter"]').attr('data-totaluniqueitems')) {
			clearCart();
		}
		else {
			var rekinEvent = 'updatedCart';
			cartTotalAndQuantities.event = rekinEvent;
			cartTotalAndQuantities.eventNonInteraction = true;
			trackingHelper.pushToDataLayer(cartTotalAndQuantities);
		}
	}
	else {
		if (pageContext.gtmPageType == 'Cart' && !$('.items-counter').attr('data-totaluniqueitems')) {
			clearCart();
		}
		else {
			var event = 'updatedCart';
			cartTotalAndQuantities.event = event;
			cartTotalAndQuantities.eventNonInteraction = true;
			trackingHelper.pushToDataLayer(cartTotalAndQuantities);
		}
	}
}

/**
 * Triggerred updateChecout event when user add product or remove, we update this event on Cart page so we have latest changes
 */
function updateCheckout() {
	var productsContainer = $('.js-mini-cart');
	var productTarget = '.js-order-product';

	if (trackingHelper.isCartUrl()) {
		productsContainer = $('.js-cart-articles');
		productTarget = '.js-cart-row';
	}

	var products = [];
	productsContainer.find(productTarget).each(function () {
		products.push(trackingHelper.getTrackingProduct($(this), true));
	});
}

/**
 * Pushes data to track checkout login page
 * @param {*} event
 * @param {*} action
 */
function trackIntermediaryLoginClick(event, action) {
	var trackingObj = {
		'event': 'GA Tracking',
		'eventCategory': 'New Login',
		'eventAction': action
	};
	trackingHelper.pushToDataLayer(trackingObj);
}

/**
 * Pushes data to track selected payment method
 */
function pushPaymentMethodClick() {
	var trackingObj = {
		'event': 'GA Tracking',
		'eventCategory': 'Cart',
		'eventAction': 'Click',
		'eventLabel': $(this).data('payment-label')
	};
	trackingHelper.pushToDataLayer(trackingObj);
}

/**
 * Pushes data to track add coupon event
 */
function pushAddCouponEvent() {
	var trackingObj = {
		'event': 'GA Tracking',
		'eventCategory': 'Cart',
		'eventAction': 'Click',
		'eventLabel': 'Promotion Code'
	};
	trackingHelper.pushToDataLayer(trackingObj);
}

/**
 * Triggerred when quick view is opened on PLP
 */
function showQuickView() {
	var trackingObj = {
		pageType: 'PDP-quickview',
		pageCurrency: $('#js-currency').val(),
		pageCategory: 'Ecommerce',
		pageSubCategory: '',
		eventNonInteraction: true
	};
	trackingHelper.pushToDataLayer(trackingObj);
}

function initializeEvents() {
	userTracking();
	ometriaUserTracking();
	abTestTracking();
	purchaseTracking();

	$(document).on('plpFilteredOrSorted', filteringOrSortingEvent);
	$(document).on('searchSuggestionPhraseClicked', function (event, phrase, destinationUrl) {
		trackQuickSearchItemClick('Click search suggestion', phrase, destinationUrl);
	});
	$(document).on('updatedcart', updatedCart);
	$(document).on('updatecheckout', updateCheckout);
	$(document).on('intermediaryLoginClicked', trackIntermediaryLoginClick);
	$(document).on('updatecarttotals', cartTracking);
	$(document).on('updateProductListing', searchResultsTracking);

	if (trackingHelper.isCartUrl()) {
		$('.js-payment-method').on('click', pushPaymentMethodClick);
		$('.js-add-coupon').on('click', pushAddCouponEvent);
	}
}

exports.updatedCart = updatedCart;
exports.showQuickView = showQuickView;
exports.trackQuickSearchItemClick = trackQuickSearchItemClick;

/**
 * Initialize all events for custom GTM tracking
 */
exports.init = function () {
	initializeEvents();
};