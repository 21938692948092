'use strict';

var trackingHelper = require('./trackingHelper');
/**
 * Checks if current URL is one of checkout pages
 */
function isCheckoutUrl() {
	var checkoutUrls = [];
	checkoutUrls.push('Checkout-PaypalStart');
	checkoutUrls.push('Checkout-Start');
	checkoutUrls.push('Cart-Show');
	checkoutUrls.push('Login-CheckoutLogin');
	checkoutUrls.push('Checkout-SubmitForm'); //Regular ThankYou page
	checkoutUrls.push('confirmationpagepd'); //PD ThankYou page

	for (var i = 0; i < checkoutUrls.length; i++) {
		if ($('#js-rawurl').val().indexOf(checkoutUrls[i]) > -1) {
			return true;
		}
	}
	return false;
}

/**
 * Checks the URL to see if checkout login page
 */
function isCheckoutLoginUrl() {
	return $('#js-rawurl').val().indexOf('Login-CheckoutLogin') != -1;
}

/**
 * Checks the URL to see if is Order Confirmation page (ThankYou page)
 */
function isOrderConfirmationUrl() {
	return $('#js-rawurl').val().indexOf('Checkout-SubmitForm') != -1 || $('#js-rawurl').val().indexOf('confirmationpagepd') != -1;
}

/**
 * Returns the last part after all underscores so GTM has clean values.
 * @param {*} name The name to clean
 */
function cleanName(name) {
	if (name.indexOf('_') > -1) {
		return name.substr(name.lastIndexOf('_') + 1);
	}
	return name;
}

/**
 * Returns the name to pascal case naming conventions
 * @param {*} name The name to clean
 */
function toPascalCase(name) {
	if (name.indexOf(' ') > -1 || name.indexOf('_') > -1) {
		return name.replace('_', ' ')
			.split(' ')
			.map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
			.join(' ')
			.replace(' ', '');
	}
	return name;
}

/**
 * Returns the selected or pre-selected delivery option
 */
function getDeliveryOption() {
	var shippingMethodSelector = '.js-shipping-method-option.is-selected',
	pickupInStore = '.pickup-store.is-selected, .pickup-store.js-is-selected',
	clickAndReserve = '.click-and-reserve.is-selected, .click-and-reserve.js-is-selected',
	pickupPointDelivery = '.pickup-point.is-selected, .pickup-point.js-is-selected',
		pickupInStoreReadOnly = '.readonly-delivery-info.pickup-in-store-selected',
		clickAndReserveReadOnly = '.readonly-delivery-info.click-and-reserve-selected',
		pickupPointDeliveryReadOnly = '.readonly-delivery-info.pickup-point-selected';

	var deliveryOptions = [shippingMethodSelector, pickupInStore, clickAndReserve, pickupPointDelivery, pickupInStoreReadOnly, clickAndReserveReadOnly, pickupPointDeliveryReadOnly];

	for (var i = 0; i < deliveryOptions.length; i++) {
		if ($(deliveryOptions[i]).length > 0) {
			return $(deliveryOptions[i]).data('shippingmethodname');
		}
	}
}

/**
 * Returns the selected or pre-selected payment option
 */
function getPaymentOption() {
	var paymentMethodValue = $('.js-radio-container-payment.is-active > .js-payment-method').val();
	var isPaymentPaypalReadonly = $('.checkout-paypal-readonly').length > 0;
	var selectedPaymentMethod = paymentMethodValue ? paymentMethodValue : isPaymentPaypalReadonly ? 'PayPal' : '';

	return toPascalCase(selectedPaymentMethod);
}

/**
 * Returns the defalut option value for the current step
 * @param {*} step The current checkout step
 */
function getDefaultOptionValue(step) {
	var defaultOptionValue;
	switch (step) {
		case 1:
			defaultOptionValue = User.userData.visitorStatus;
			break;
		case 2:
			defaultOptionValue = getDeliveryOption();
			break;
		case 3:
			defaultOptionValue = getPaymentOption();
			break;
		default:
			defaultOptionValue = '';
	}

	return defaultOptionValue;
}

/**
 * Store the default payment option and the selected payment option into the session storage if the user selected different payment option than the default one
 * @param {*} step The payment step number
 */
function setPaymentMethodCheckoutOption(step) {
	var defaultPaymentOptionValue = sessionStorage.getItem('paymentMethod') ? JSON.parse(sessionStorage.getItem('paymentMethod'))[0] : $('.js-checkout-block').data('current-option') ? $('.js-checkout-block').data('current-option')[step-1] : undefined;
	var currentPaymentOptionValue = getPaymentOption();

	sessionStorage.removeItem('paymentMethod');

	if (defaultPaymentOptionValue !== currentPaymentOptionValue) {
		//We store the Payment Method in session storage and then we fire the checkoutOption event in the Order Confirmation Page (Thank You Page)
		sessionStorage.setItem('paymentMethod', JSON.stringify([defaultPaymentOptionValue, currentPaymentOptionValue]));
	}
}

/**
 * Pushes tracking object for current checkout step
 * @param {*} step
 * @param {*} products
 */
function triggerCheckoutStep(step, products) {
	var option = getDefaultOptionValue(step);
	var isRedirected = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
	var element = $('[value="InitiateCheckout"]').data('eventdata');
	var eventId = '';

	if (element) {
		eventId = element.eventId;
	}

	if (step !== $('.js-checkout-block').data('current-step')) {
		var currentOptionValues = $('.js-checkout-block').data('current-option') ? $('.js-checkout-block').data('current-option') : [];

		if (option !== currentOptionValues[step - 1]) {
			currentOptionValues[step - 1] = option;

			$('.js-checkout-block').data('current-step', step);
			$('.js-checkout-block').data('current-option', currentOptionValues);

			if (!isRedirected) {
				var trackingObj = {
					'event': 'checkout',
					'ecommerce': {
						'eventId': eventId,
						'currencyCode': $('#js-currency').val(),
						'checkout': {
							'actionField': { 'step': step, 'option': option },
							'products': products
						}
					}
				};
				trackingHelper.pushToDataLayer(trackingObj);
			}
		}
	}
}

/**
 * Pushes tracking object for checkout options
 * @param {*} step
 * @param {*} option
 */
function checkoutOption(step, option) {
	var defaultOptionValues = $('.js-checkout-block').data('current-option') ? $('.js-checkout-block').data('current-option') : [];
	var isRedirected = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
	var isForce = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

	if ((option !== defaultOptionValues[step - 1] && defaultOptionValues[step - 1] !== undefined) || isForce) {
		defaultOptionValues[step - 1] = option;
		$('.js-checkout-block').data('current-option', defaultOptionValues);

		if (!isRedirected) {
			var trackingObj = {
				'event': 'checkoutOption',
				'ecommerce': {
					'checkout_option': {
						'actionField': {
							'step': step,
							'option': option
						}
					}
				}
			};
			trackingHelper.pushToDataLayer(trackingObj);
		}
	}
}

/**
 * Tracking events for checkout process
 */
function checkoutProcess() {
	if (isCheckoutUrl()) {
		var productsContainer = $('.js-mini-cart');
		var productTarget = '.js-order-product';
		var isCartUrl = trackingHelper.isCartUrl();

		if (isCartUrl) {
			productsContainer = $('.js-cart-articles');
			productTarget = '.js-cart-row';
		}

		var products = [];

		productsContainer.find(productTarget).each(function() {
			products.push(trackingHelper.getTrackingProduct($(this), isCartUrl));
		});

		if (isCartUrl) {
			$('body').on('click', '.js-payment-method', function() {
				// all possible payment methods are sent as options
				var optionName = $(this).attr('name');

				sessionStorage.removeItem('cartCheckoutOption');
				if (optionName) {
					checkoutOption(1, cleanName(optionName), false, true);
				}
				else {
					// in case the 'name' attribute is not present (e.g. Paypal) we store the checkout option in the session storage
					optionName = $(this).data('paymentLabel');
					sessionStorage.setItem('cartCheckoutOption', optionName);
				}
			});
		}
		else if (isCheckoutLoginUrl()) {
			triggerCheckoutStep(1, products);
			checkoutOption(1, User.userData.visitorStatus);
		}
		else if (isOrderConfirmationUrl()) {
			var option = sessionStorage.getItem('paymentMethod') ? JSON.parse(sessionStorage.getItem('paymentMethod'))[1] : undefined;

			//Fire a payment checkoutOption event in case the defaule payment checkout is different than the selected one
			if (option) {
				checkoutOption(3, option, false, true);
				sessionStorage.removeItem('paymentMethod');
			}
		}
		else {
			var isRedirected = false;

			if (window.location.search.indexOf('?edit=true&container=js-checkout-payment') > -1
				|| window.location.search.indexOf('?paypalSuccessful') > -1 //paypal
				|| window.location.search.indexOf('?res_cd') > -1 //kcp
				|| window.location.search.indexOf('?adyen_successful_authorization') > -1 //adyen
				|| (window.location.search.indexOf('merchantReference') > -1 && window.location.search.indexOf('signature') > -1 && window.location.search.indexOf('orderToken') > -1) //adyen
				|| window.location.search.indexOf('?amazonCheckoutSessionId') > -1
				|| window.location.search.indexOf('?resultCode') > -1
			) {
				//Execute checkoutOption in case Cart option is PayPal
				var cartCheckoutOption = sessionStorage.getItem('cartCheckoutOption');
				if (cartCheckoutOption === 'PayPal') {
					sessionStorage.removeItem('cartCheckoutOption');
				}
				else {
					isRedirected = true;
				}
			}

			//When redirected from HPP, we don't push datalayer events
			if (isRedirected) {
				triggerCheckoutStep(1, products, true);

				if ($('.js-checkout-method .js-checkout-shipping').length > 0) {
					triggerCheckoutStep(1, products, true);
				}
				if ($('.js-checkout-contact .section-readonly').length > 0 && $('.js-checkout-method .section-readonly').length > 0) {
					triggerCheckoutStep(3, products, true);
				}
			}
			else {
				triggerCheckoutStep(1, products);

				if ($('.js-checkout-method .js-checkout-shipping').length > 0) {
					triggerCheckoutStep(2, products);
				}
				if ($('.js-checkout-contact .section-readonly').length > 0 && $('.js-checkout-method .section-readonly').length > 0) {
					triggerCheckoutStep(3, products);
				}
			}

			$('body').on('tracklogin', '.js-checkout-contact', function() {
				checkoutOption(1, 'Logged-in');
			});
			$('body').on('trackCheckoutStep', '.js-checkout-contact', function() {
				triggerCheckoutStep(1, products);
			});
			$('body').on('trackCheckoutStep', '.js-checkout-method', function() {
				triggerCheckoutStep(2, products);
			});
			$('body').on('trackSaveDetails', '.js-checkout-method', function() {
				checkoutOption(2, getDeliveryOption());
			});
			$('body').on('trackCheckoutStep', '.js-checkout-payment', function() {
				triggerCheckoutStep(3, products);
			});
			$('body').on('trackSaveDetails', '.js-checkout-payment', function() {
				setPaymentMethodCheckoutOption(3);
			});
		}
	}
}

function initializeEvents() {
	checkoutProcess();
}

exports.triggerCheckoutStep = triggerCheckoutStep;
exports.checkoutOption = checkoutOption;
exports.isCheckoutUrl = isCheckoutUrl;

/**
 * Initialize all events for checkout GTM tracking
 */
exports.init = function () {
	initializeEvents();
};