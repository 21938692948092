'use strict';

var dialog = require('./dialog'),
	keyboard = require('./constants').keyboard,
	accessibility = require('./accessibility'),
	cartReminderSlider = require('./swiper'),
	trackingHelper = require('./tracking/trackingHelper');

var cartreminder = {
	init: function() {
		if (!this.exists()) {
			this.$container = $('<div/>').attr('id', 'CartReminderDialog').appendTo(document.body);
		}
	},
	exists: function() {
		return this.$container && (this.$container.length > 0);
	},
	setup: function(url, action) {
		$('[data-js="close-cart-reminder"]').on('click keydown', function(e) {
			if (e.keyCode === keyboard.ENTER_KEY || e.type === 'click') {
				e.stopPropagation();
				dialog.close();
				trackingHelper.pushBasicTrackingEvent('cart check popup', action, 'click - continue', null, 'false');
			}
		});

		$('[data-js="add-to-bag-cart-reminder"]').on('click keydown', function(e) {
			if (e.keyCode === keyboard.ENTER_KEY || e.type === 'click') {
				trackingHelper.pushBasicTrackingEvent('cart check popup', action, 'click - view bag', null, 'false');
			}
		});

		trackingHelper.pushBasicTrackingEvent('cart check popup', action, 'impression', null, 'true');
	},
	show: function(action) {
		var url = Urls.showReminder;

		if (!this.exists()) {
			this.init();
		}

		var dialogClass = 'cart-reminder-dialog';

		dialog.open({
			target: this.$container,
			url: url,
			options: {
				dialogClass: dialogClass,
				open: function() {
					$('html').addClass('dialog-is-open');
					$('body').addClass('cart-reminder-is-open');

					dialog.bindOverlayClose(this.$container);
					this.setup(url, action);
					cartReminderSlider.initializeCartReminder();
					var elements = $('body').find('[data-js="add-to-bag-cart-reminder"], [data-js="close-cart-reminder"], .ui-dialog-titlebar-close');
					accessibility.focusTrap(elements, true);
				}.bind(this),
				close: function () {
					$('body').removeClass('cart-reminder-is-open');
					$('html').removeClass('dialog-is-open');
					$('#CartReminderDialog').html('');
				}
			}
		});
	}
};

module.exports = cartreminder;