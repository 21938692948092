'use strict';

var Promise = require('promise'),
	page = require('../page'),
	ajax = require('../ajax'),
	minicart = require('../minicart'),
	progress = require('../progress'),
	util = require('../util'),
	account = require('./account');


// Global Variable to control the click event on the wishlist icon
var isClicked = false;
var wishlistTimeOut;
var isRemoved;

/**
 * Refreshes-updates the wishlist object after add-remove-edit operations
 * @param {object} response
 */
function updateWishlistHeader(response) {
	var callback = function (result) {
		$('.js-user-wishlist').replaceWith(result);
		var isPLPPage = $('.pt_product-search-result').length > 0;

		if (isPLPPage) {
			$('.js-user-wishlist > a').attr('tabindex', 1);
		}

		if (isRemoved) {
			isRemoved.then(function () {
				checkProductsInWishList();
			});
		}
		else {
			checkProductsInWishList();
		}
		$(document).trigger('trackUpdatedWishList');
	};

	if (!response) {
		ajax.load({
			url: Urls.wishlistHeader,
			callback: callback
		});
	}
	else {
		callback(response);
	}
}

exports.init = function () {
	initializeEvents();
};
exports.showWishlistNotification = showWishlistNotification;
exports.checkProductsInWishList = checkProductsInWishList;

var initializeEvents = function () {
	$('#editAddress').on('change', function () {
		page.redirect(util.appendParamToURL(Urls.wishlistAddress, 'AddressID', $(this).val()));
	});

	// Logic to return to the last page where the user was in the browser
	$('[data-js="continue-shopping"],[data-js="continue-shopping-link"]').off('click').on('click', function () {
		var qs = util.getQueryStringParams(window.location.href);
		if (qs.continue) {
			window.location = qs.continue;
			return;
		}
		if (document.referrer) {
			var pathArray = document.referrer.split('/');
			var host = pathArray[2];

			var previousHost = host;
			var currentHost = window.location.host;

			if (previousHost !== currentHost) {
				window.location = '/';
				return;
			}
		}

		if (window.history.length < 3) {
			window.location = '/';
			return;
		}

		window.history.back();
	});

	$(document).ready(function () {
		checkProductsInWishList();
	});

	$('.js-product-edit').on('click', function (e) {
		e.preventDefault();
		var $parent = $(this).closest('[data-js="wishlist-product"]');
		$parent.find('.select-wrapper').addClass('is-open');
		$('body').addClass('items-is-open');
	});

	closeEditOverlayEvents();
	addRemoveWishlistItems();
	addWishlistItemToCart();
	updateWishlistItemVariationSelection();
	allWishListToCart();
	util.calculateSize('[data-js="product-variation-selector"]');
	account.initCartLogin();
	account.init();
};

/**
 * Init event handlers for closing mini dialog for editing product on mobile
 */
function closeEditOverlayEvents() {
	$('.select-wrapper-inner .js-icon-close').off('mouseup touchend').on('mouseup touchend', function () {
		closeEditOverlay();
	});

	$('body').on('mouseup touchend', function (e) {
		var container = $('.select-wrapper-inner');
		// if the target of the click isn't the container nor a descendant of the container
		if (!container.is(e.target) && container.has(e.target).length === 0) {
			closeEditOverlay();
		}
	});
}

/**
 * Close mini dialog for editing product on mobile
 */
function closeEditOverlay() {
	$('.select-wrapper').removeClass('is-open');
	$('body').removeClass('items-is-open');
}

/**
 * Both add and remove operations for wishlist items/ toggles the heart icon according to the operation
 */
function addRemoveWishlistItems() {
	$('.js-favorites').off('click').on('click', function (e) {
		if (isClicked) {
			return false;
		}
		e.preventDefault();
		isClicked = true;
		var $this = $(this),
			pid = $this.data('pid'),
			wishListElement = $('.js-favorites[data-pid="' + pid + '"]'),
			isCartPage = $('.pt_cart').length > 0,
			isPDPPage = $('.pt_product-detail').length > 0,
			heartClass = isPDPPage ? 'is-full' : 'icon-heart-full',
			isWishlistPage = $('.pt_wishlist').length > 0,
			action = $this.hasClass(heartClass) ? 'remove' : 'add',
			url = wishListElement.data(action),
			removeFromWishlistOrCart = (isWishlistPage && action === 'remove') || isCartPage;

		$this.addClass('u-pointer-events-none');

		if (removeFromWishlistOrCart) {
			progress.show();
			if (isWishlistPage) {
				//if customer clicks to remove another item before previous notification faded out - we need to remove previous notification row
				$('.js-wishlist .wishlist-product-wrapper.information-msg.is-info').remove();
			}
		}
		url = util.appendParamsToUrl(url, { 'currenturl': window.location.pathname + window.location.search });
		ajax.load({
			url: url,
			keepLoaderOpen: true,
			callback: function (response) {
				isClicked = false;
				$this.removeClass('u-pointer-events-none');
				if (isWishlistPage) {
					updateWishlistHeader($(response).find('.js-user-wishlist'));
					updateToWishlistEmptyPage(response);
				}
				else {
					updateWishlistHeader(response);
				}
				if (removeFromWishlistOrCart) {
					removeLineItemRow(pid, action, isWishlistPage, isCartPage);
				}
				else {
					// Show notification that item is added / removed
					showWishlistNotification(action);
				}

				if (action === 'add') {
					$(document).trigger('addtowishlist', wishListElement);
					wishListElement.attr('title', Resources.REMOVE_FROM_WISHLIST);

					var ariaLabelAttr = wishListElement.attr('aria-label');
					if (typeof ariaLabelAttr !== 'undefined' && ariaLabelAttr !== false) {
						wishListElement.attr('aria-label', Resources.REMOVE_FROM_WISHLIST);
					}
				}
				else {
					$(document).trigger('removefromwishlist', wishListElement);
					wishListElement.attr('title', Resources.ADD_TO_WISHLIST);

					var attr = wishListElement.attr('aria-label');
					if (typeof attr !== 'undefined' && attr !== false) {
						wishListElement.attr('aria-label', Resources.ADD_TO_WISHLIST);
					}
				}
			}
		});
	});
}

/**
 * We remove the item row from wishlist/cart page after we remove the item on back end
 * @param {String} pid
 * @param {String} action
 * @param {Boolean} isWishlistPage
 * @param {Boolean} isCartPage
 */
function removeLineItemRow(pid, action, isWishlistPage, isCartPage) {
	if (isWishlistPage && action === 'remove') {
		var wishlistItemRow = $('.js-wishlist [data-js="wishlist-product"][data-product-id="' + pid + '"]');
		var innerItemWrapper = wishlistItemRow.children();
		//notification that item is removed from wishlist
		var notificationWrapper = $('<span>' + Resources.ITEM_REMOVED_WISHLIST + '</span>').hide();

		isRemoved = new Promise(
			function (resolve) {
				innerItemWrapper.fadeOut(500, function () {
					$(this).remove();
					wishlistItemRow.append(notificationWrapper);
					wishlistItemRow.addClass('information-msg is-info');
					notificationWrapper.fadeIn(500);
					progress.hide();
					// we remove the notification after 5 seconds
					clearTimeout(wishlistTimeOut);
					wishlistTimeOut = setTimeout(function () {
						wishlistItemRow.fadeOut(500, function () {
							$(this).remove();
						});
					}, 5000);

					resolve();
				});
			}
		);
	}
	else if (isCartPage) {
		// we remove the item from cart page when adding to the wishlist
		var lineItemRow = $('.js-cart-row[data-variant-id="' + pid + '"]');

		// since cart page will be reloaded we need to save the action and the line item row index to use it on cart page load
		util.createCookie('_ecco_wishlist_notification', action + ' ' + lineItemRow.index(), 30);

		lineItemRow.fadeOut(500, function () {
			$(this).remove();
		});

		// then we reload the page to update cart total and other data
		page.refresh();
	}
}

/**
 * @function
 * @description Show notification after click on the wishlist button
 */
function showWishlistNotification(action) {
	const notification = $('[data-js="wishlist-notification"]');
	const activeClass = 'is-open';
	const $wishlistWrapper = $('.js-user-wishlist');
	const hideWishlistIcon = $wishlistWrapper.hasClass('u-hidden');


	if ($('body').hasClass('quickview-is-open')) {
		$('body').addClass('wishlist-notification');
	}
	if (hideWishlistIcon) {
		$wishlistWrapper.removeClass('u-hidden');
	}

	if (action === 'add') {
		addItemWishlistMsg();
		notification.addClass(activeClass);
	}

	function closeNotification() {
		notification.removeClass(activeClass);
		if ($('body').hasClass('wishlist-notification')) {
			$('body').removeClass('wishlist-notification');
		}
		if (hideWishlistIcon) {
			$wishlistWrapper.addClass('u-hidden');
		}
	}

	clearTimeout(wishlistTimeOut);
	wishlistTimeOut = setTimeout(function () {
		closeNotification();
	}, 5000);

	// Hide Notification if you click outside
	$(document).on('mouseup', function (e) {
		if (!notification.is(e.target) && notification.has(e.target).length === 0) {
			closeNotification();
		}
	});
	closeWishlistMsg();
}

/**
 * @function
 * @description Close wishlist Notification (button on mobile view)
 */
function closeWishlistMsg() {
	const notificationBtn = $('[data-js="close-wishlist-notification"]');
	const notification = $('[data-js="wishlist-notification"]');
	const activeClass = 'is-open';

	notificationBtn.off('click').on('click', function () {
		notification.removeClass(activeClass);
	});
}

/**
 * @function
 * @description Show Notification Text ( add item )
 */
function addItemWishlistMsg() {
	const notification = $('[data-js="add-item"]');
	const activeClass = 'u-hidden';
	const userLogged = $('.js-account-links');

	notification.removeClass(activeClass);
	userLogged.removeClass('is-open');
}


/**
 * @function
 * @description Adds a single product to wishlist
 */
function addWishlistItemToCart() {
	$('[data-js="add-wishlistitem-to-cart"]').off('click').on('click', function (e) {
		e.preventDefault();
		if ($(this).hasClass('out-of-stock')) {
			return;
		}
		var productIds = [],
			$thisButton = $(this),
			productId = $thisButton.data('itemid'),
			productName = $thisButton.data('itemname');

		var keyboardInteraction = $thisButton.hasClass('focus-visible');
		var optionsSelected = checkSelectedOptions($thisButton);

		if (optionsSelected) {
			productIds.push(productId);
			addItemsToCart(productIds, keyboardInteraction, productName);
		}

		// change text and disable the button after the product is added to the cart
		updateAddedItem($(this));
	});
}

function updateAddedItem(item) {
	item.addClass('is-added');
	item.prop('disabled', true);
	item.text(item.data('swap'));
}

/**
 * @function
 * @description Updates the size/color selection of wishlist item
 * prepares the requestData as the variationAttribute and value of it
 */
function updateWishlistItemVariationSelection() {
	$('.pt_wishlist [data-js="product-variation-selector"]').off('change').on('change', function () {
		var variationAttributeID = $(this).data('variationAttributeId');
		var variationAttributeValueID = $(this).val();
		var uuid = $(this).data('uuid');
		var productPosition = $(this).closest('[data-js="wishlist-product"]').data('product-position');
		var requestData = {
			uuid: uuid,
			variationAttributeID: variationAttributeID,
			variationAttributeValueID: variationAttributeValueID
		};
		updateVariationSelection(requestData, productPosition);
	});
}

/**
 * @function
 * @description Checks the every single product item in the page and
 * 	decides which products are in wishlist by comparing them to the
 * 	user's wishlist (wishlistHeader list)
 */
function checkProductsInWishList() {
	var $userWishList = $('.js-user-wishlist');
	var $wishListItems = $('.js-favorites');
	var isCartPage = $('.pt_cart').length > 0;
	var isPDPPage = $('.pt_product-detail').length > 0;
	var isPLPPage = $('.pt_product-search-result').length > 0;
	var heartClass = isPDPPage ? 'is-full' : 'icon-heart-full';
	if ($wishListItems.length > 0 && $userWishList.length > 0) {
		var isThereAnyItemCanBeAddedToCart = false;

		$('.js-favorites').each(function (i, wishListItem) {
			var wishListItemPID = $(wishListItem).data('pid');
			var isOneSizeProduct = $(wishListItem).data('isonesizeproduct');
			var isMaster = $(wishListItem).data('ismaster');
			var oneSizeVariantId = $(wishListItem).data('onesizevariantid');
			var defaultOrFirstVariationGroupId = $(wishListItem).data('defaultvariationgroupid');
			var variationGroupId = $(wishListItem).data('variationgroupid');
			var isItemInTheList = false;

			if (isOneSizeProduct) {
				isItemInTheList = $userWishList.find('.js-product-item[data-pid="' + oneSizeVariantId + '"]').length > 0;
			}
			else if (isMaster) {
				if (isPLPPage) {
					isItemInTheList = $userWishList.find('.js-product-item[data-pid="' + variationGroupId + '"]').length > 0;
				}
				else {
					isItemInTheList = $userWishList.find('.js-product-item[data-pid="' + defaultOrFirstVariationGroupId + '"]').length > 0;
				}
			}
			else {
				isItemInTheList = $userWishList.find('.js-product-item[data-pid="' + wishListItemPID + '"]').length > 0;
			}

			var isItemInCart = $('.js-mini-cart-product[data-itemid="' + wishListItemPID + '"]');
			var wishlistRowCTA = $('[data-js="add-wishlistitem-to-cart"][data-itemid="' + wishListItemPID + '"]');

			if (isItemInCart.length > 0 && wishlistRowCTA.length > 0) {
				updateAddedItem($(wishlistRowCTA));
			}

			if (isItemInCart.length === 0) {
				isThereAnyItemCanBeAddedToCart = true;
			}

			if (isItemInTheList) {
				$(wishListItem).addClass(heartClass);
				$(wishListItem).attr('title', Resources.REMOVE_FROM_WISHLIST);

				var ariaLabelAttr = $(wishListItem).attr('aria-label');
				if (typeof ariaLabelAttr !== 'undefined' && ariaLabelAttr !== false) {
					$(wishListItem).attr('aria-label', Resources.REMOVE_FROM_WISHLIST);
				}
			}
			else {
				$(wishListItem).removeClass(heartClass);
				$(wishListItem).attr('title', Resources.ADD_TO_WISHLIST);

				var attr = $(wishListItem).attr('aria-label');
				if (typeof attr !== 'undefined' && attr !== false) {
					$(wishListItem).attr('aria-label', Resources.ADD_TO_WISHLIST);
				}

				if (isCartPage) {
					$(wishListItem).parent().removeClass('u-hidden');
				}
			}
		});

		// if there is any product not added to cart yet
		if (isThereAnyItemCanBeAddedToCart) {
			$('[data-js="all-wishlist-to-cart"]').removeClass('u-hidden');
			$('[data-js="go-to-cart-btn"]').addClass('u-hidden');
		}
		else if ($('.js-mini-cart-product').length > 0) {
			// if there is any product in the cart
			$('[data-js="all-wishlist-to-cart"]').addClass('u-hidden');
			$('[data-js="go-to-cart-btn"]').removeClass('u-hidden');
		}
		else {
			$('[data-js="go-to-cart-btn"]').addClass('u-hidden');
			$('[data-js="all-wishlist-to-cart"]').addClass('u-hidden');
		}
	}
}

function updateToWishlistEmptyPage(response) {
	if (!response) {
		return;
	}

	function updatePage(result) {
		if ($(result).find('[data-js="empty-wishlist"]').length !== 0) {
			$('[data-js="wishlist-wrapper"]').replaceWith($(result).find('[data-js="wishlist-wrapper"]'));
			initializeEvents();
		}
	}

	if (isRemoved) {
		isRemoved.then(function () {
			updatePage(response);
		});
	}
	else {
		updatePage(response);
		progress.hide();
	}
}

/**
 * @function
 * @description Adds single or multiple products to cart
 * @param {Array} productIds
 * @param {boolean} keyboardInteraction
 * @param {string} productName // only if adding single item to wishlist
 */
function addItemsToCart(productIds, keyboardInteraction, productName) {
	var productsData = {
		productsJSON: JSON.stringify(productIds)
	};
	var alreadyInCart = getCartProducts();


	return ajax.load({
		type: 'POST',
		url: Urls.addMultipleProducts,
		data: productsData,
		callback: function (data) {
			if (!data) { return; }
			updateMiniCart(data, keyboardInteraction, productName, productIds);
			checkProductsInWishList();
			triggerTrackingEvents(productIds, alreadyInCart);
		}
	});
}
function getCartProducts() {
	var cartItemsContainer = $('.mini-cart-products');
	var productTarget = '.js-mini-cart-product';
	var alreadyInCart = [];
	cartItemsContainer.find(productTarget).each(function () {
		var variantId = $(this).data('itemid');
		alreadyInCart.push(variantId);
	});

	return alreadyInCart;
}

/**
 * Triggers tracking add-to-cart/add-all-to-cart events
 * @param {Array} productIds
 */
function triggerTrackingEvents(productIds, alreadyInCart) {
	if (!productIds || !productIds.length) {
		return;
	}
	if (productIds.length > 1) {
		// Removing already existing wishlist items to cart
		var index;
		for (var i = 0; i < alreadyInCart.length; i++) {
			index = productIds.indexOf(alreadyInCart[i]);
			if (index > -1) {
				productIds.splice(index, 1);
			}
		}

		$(document).trigger('wishlistaddalltocart', JSON.stringify(productIds));
		return;
	}

	var productSelector = $('[data-js="wishlist-product"][data-product-id="' + productIds[0] + '"]');
	$(document).trigger('wishlistaddtocart', productSelector);
}

/**
 * @function
 * @description Function that sends the request data and renders the response after variation selection
 * @param {Object} requestData
 * @param {Number} productPosition
 */
function updateVariationSelection(requestData, productPosition) {
	var product = $('[data-product-position="' + productPosition + '"]');
	progress.show();
	ajax.load({
		url: Urls.replaceProductListItem,
		type: 'POST',
		data: requestData,
		callback: function (response) {
			$('[data-js="wishlist-wrapper"]').html($(response).find('[data-js="wishlist-wrapper"]'));
			updateWishlistHeader();
			initializeEvents();
			var event = 'wishlistselect' + requestData.variationAttributeID;
			var argument = getProduct(product);
			$(document).trigger(event, argument);
			progress.hide();
		}
	});
}

/**
 * Returns correct product.
 * @param {Object} product
 * @returns product
 */
function getProduct(product) {
	if (!product || !product.length) {
		return;
	}

	var correctProduct;
	var masterId = product.data('itemid');
	var selectedSize = product.find('[data-variation-attribute-id="size"] > option:selected').val();
	var selectedColor = product.find('[data-variation-attribute-id="color"] > option:selected').val();
	var products = $('[data-itemid="' + masterId + '"]');

	products.each(function () {
		var productSize = $(this).find('[data-variation-attribute-id="size"] > option:selected').val();
		var productColor = $(this).find('[data-variation-attribute-id="color"] > option:selected').val();

		if (productSize === selectedSize && productColor === selectedColor) {
			correctProduct = $(this);
			return false;
		}
	});

	return correctProduct;
}

/**
 * @function
 * @description Adds all wishlist items to cart
 */
function allWishListToCart() {
	$('#add-all-to-cart-error').addClass('u-hidden');

	$('[data-js="all-wishlist-to-cart"]').off('click').on('click', function () {
		var products = [],
			$thisButton = $(this);
		var keyboardInteraction = $thisButton.hasClass('focus-visible');

		var isThereAnItemWithoutOptionSelected = false;

		$('[data-js="wishlist-product"]').each(function () {
			var optionsSelected = checkSelectedOptions($(this).find('[data-js="add-wishlistitem-to-cart"]'));
			if (optionsSelected) {
				var productId = $(this).data('product-id');
				products.push(productId);
			}
			else {
				isThereAnItemWithoutOptionSelected = true;
			}
		});

		if (isThereAnItemWithoutOptionSelected) {
			$('#add-all-to-cart-error').removeClass('u-hidden');
		}
		else if (products.length) {
			addItemsToCart(products, keyboardInteraction);
		}
	});
}

/**
 * @function
 * @description Updates the minicart template and opens the minicart overlay
 * @param {string} response
 * @param {boolean} keyboardInteraction
 * @param {string} productName // only if adding single item to wishlist
 */
function updateMiniCart(response, keyboardInteraction, productName, productIds) {
	var overlay = $('.js-overlay');
	minicart.show(response);
	if (productName) {
		minicart.addNotification(`${Resources.ADD_TO_BAG_SUCCESS_1} "${productName}" ${Resources.ADD_TO_BAG_SUCCESS_2}`);
	}
	else {
		minicart.addNotification(Resources.ADD_ALL_TO_BAG_SUCCESS);
	}

	//start timer if keyboard is not used for navigation
	if (!keyboardInteraction) {
		minicart.startTimer();
	}

	if (util.isSmallScreenSize()) {
		showSuccessfulMessage(productIds);
	}
	else {
		overlay.fadeIn('slow', function () {
			// not two elements get focus at the same time, so we remove it from the tile as the minicart gets it
			$('.focus-visible,.force-hover')
				.removeClass('focus-visible')
				.removeClass('force-hover');
			// we only apply focus programatically if keyboard was used, to not steal the mouse focus
			if (keyboardInteraction) {
				minicart.$close.trigger('focus');
				minicart.$close.addClass('focus-visible');
			}
		});
	}
}

/**
 * @function
 * @description This function will show successful message after we add product to the cart (only on mobile)
 */
var showSuccessfulMessage = function (productIds) {
	var successMessageContainer = $('[data-js="pdp-successful-msg"]');
	var data = { pids: JSON.stringify(productIds) };

	ajax.load({
		url: Urls.addToCartSuccess,
		data: data,
		target: successMessageContainer,
		callback: function () {
			successMessageContainer.addClass('is-active');

			var addToCartWrapper = $('[data-js="pdp-add-to-cart"]');
			addToCartWrapper.addClass('u-hidden');

			var productCloseSection = $('[data-js="product-close-section"]');
			productCloseSection.addClass('u-hidden');

			var addToCartButtonContainer = $('.js-product-bottom-info');
			addToCartButtonContainer.addClass('is-sticky');
			addToCartButtonContainer.removeClass('step-before-sticky');

			var closeDialog = $('[data-js="close-sucessfull-msg"]');
			closeDialog.off('click').on('click', closeSuccessfulMessage);

			util.rememberScrollPosition('product-is-added');
		}
	});
};

/**
 * @function
 * @description Close Dialog after product is added (Only mobile)
 */
var closeSuccessfulMessage = function () {
	util.restoreScrollPosition('product-is-added');

	var msgDialog = $('[data-js="pdp-successful-msg"]');
	msgDialog.removeClass('is-active');

	var addToCartWrapper = $('[data-js="pdp-add-to-cart"]');
	addToCartWrapper.removeClass('u-hidden');

	var productCloseSection = $('[data-js="product-close-section"]');
	productCloseSection.removeClass('u-hidden');

	var addToCartButtonContainer = $('.js-product-bottom-info');
	addToCartButtonContainer.removeClass('is-sticky');

	$(document).trigger('checkaddtocartbuttonvisibility');
};

/**
 * @function
 * @description Checks if all variation attributes have value and product is ready to be added to cart
 * also adds the error line if there are empty variation attributes
 * @param {object} wishlistRow
*/
function checkSelectedOptions(wishListRow) {
	if ($(wishListRow).hasClass('out-of-stock')) {
		return;
	}
	var $this = $(wishListRow),
		uuid = $this.data('uuid');

	var options = $('.js-product-variation-selector[data-uuid="' + uuid + '"]');
	var optionsSelected = false;
	$(options).each(function () {
		if ($(this).children().filter('[selected="selected"],[selected]').length > 0) {
			optionsSelected = true;
		}
		else {
			optionsSelected = false;
			return false; // break
		}
	});

	if (optionsSelected) {
		return true;
	}

	var messageElement = $(wishListRow).parent().find('#add-to-cart-description');
	messageElement.removeClass('u-hidden');
	return false;
}
