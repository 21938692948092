'use strict';

var ajax = require('./ajax');
var util = require('./util');

function init() {
	//On init check if we need to push any data on page load
	var eventTriggers = $('[name="FBConversionTrigger"]');

	eventTriggers.each(function() {
		var element = $(this);
		var event = element.val();
		var data = element.data('eventdata');

		if (data) {
			data.event = event;
			triggerEvent(data);
		}
	});
}

/**
 * Sends ajax call to FB Conversion
 * @param {Object} data
 */
function triggerEvent(data) {
	var source_url = window.location.href;
	var clickId = util.readCookie('_fbc');
	var browserId = util.readCookie('_fbp');

	if (clickId) {
		data.clickId = clickId;
	}

	if (browserId) {
		data.browserId = browserId;
	}

	if (source_url) {
		data.source_url = source_url;
	}

	ajax.load({
		url: Urls.fbConversionSubmit,
		requestType: 'POST',
		data: data,
		keepLoaderOpen: true // since this AJAX call doesn't open a loader, we don't have to close it
	});
}

exports.init = init;
exports.triggerEvent = triggerEvent;