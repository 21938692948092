'use strict';

var util = require('../util');
var googleMap = false;

// init position set by configuration
var storeLat = $('#js-googlemapstore').data('lat');
var storeLong = $('#js-googlemapstore').data('long');
var storeName = $('#js-googlemapstore').data('name');

/**
 * Initializes the map to show the location of the store with a marker on it
 */
function initStoreMap() {
	var mapElement = document.getElementById('js-googlemapstore');

	// initialize the map
	var locale = $('#js-currentlocale').val().split('_')[0];
	$.getScript('//maps.googleapis.com/maps/api/js?key=' + SitePreferences.GOOGLEMAP_APIKEY + '&libraries=geometry&language=' + locale,
		function () {
			//init position set by configuration
			var storePosition = {
				lat: storeLat,
				lng: storeLong
			};

			//initialize google maps object
			googleMap = new google.maps.Map(mapElement, {
				center: storePosition,
				scrollwheel: true,
				zoom: 14,
				minZoom: 3
			});

			var iconUrl = window.Urls.staticPath
                + (storeName.toLowerCase().indexOf('ecco') > -1 ? 'img/mixed/icon-shop-ecco.png' : 'img/mixed/icon-shop-default.png');

			var marker = new google.maps.Marker({
				position: storePosition,
				map: googleMap,
				icon: iconUrl
			});
		});
}

/**
 * Gets the current location of the customer based on browser or IP and calculates the distance of it to the store
 * Then it renders the distance on the page
 */
function getStoreDistance() {
	// get coordinates from browser or based on IP as fallback
	util.getLocationCoordinates().then(
		function (coords) {
			var storeDistanceInKm = null;
			var storeDistanceString = '';
			if (coords.lat && coords.lng && storeLat && storeLong) {
				storeDistanceInKm = calcStoreDistance(coords.lat, coords.lng, storeLat, storeLong);
			}
			if (storeDistanceInKm) {
				storeDistanceString = getStoreDistanceString(storeDistanceInKm);
			}

			$('[data-js="store-title-distance"]').html(storeDistanceString);
		},
		function () {
		// do nothing in case browser/IP coords aren't available
		}
	);
}

/**
 * To avoid an ajax call to the server we calculate the distance between the browser/IP address and the store on the front end
 * using a variation of Haversine formula
 */
function calcStoreDistance(lat1, lon1, lat2, lon2) {
	var p = 0.017453292519943295; // Math.PI / 180
	var cos = Math.cos;
	var a = 0.5 - cos((lat2 - lat1) * p)/2 + 
            cos(lat1 * p) * cos(lat2 * p) * 
            (1 - cos((lon2 - lon1) * p))/2;

	return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

/**
 * Transforms the distance value to a string which includes distance rounded to one decimal and the unit (ml/km)
 */
function getStoreDistanceString(distanceInKm) {
	var exactDistance = distanceInKm;

	var storeDistanceUnit = SitePreferences.STORE_DISTANCE_UNIT;
	if (storeDistanceUnit === 'ml') {
		exactDistance = distanceInKm / 1.609344;
	}

	var approxDistance = exactDistance.toFixed(1);
	return approxDistance.toString() + ' ' + storeDistanceUnit;
}

exports.init = function () {
	initStoreMap();
	getStoreDistance();
};