'use strict';

var jpAddressComplete = require('./jp-postal-code');
var krAddressComplete = require('./kr-postal-code');
var inPinCodeLookup = require('./in-pincode-lookup');
var caAddressComplete = require('./ca-addresscomplete');
var googleAddressComplete = require('./googleAddressComplete');

//Initialization of address auto complete based on market
exports.init = function(context) {
	var params = context ? context : '';
	var market = SitePreferences.LOCALE ? SitePreferences.LOCALE.substring(3) : '';

	switch (market) {
		case 'KR':
			krAddressComplete.init();
			break;
		case 'JP':
			jpAddressComplete.init(params);
			break;
		case 'CA':
			caAddressComplete.init(params);
			break;
		case 'IN':
			inPinCodeLookup.init(params);
			break;
		default:
			googleAddressComplete.init();
			break;
	}
};