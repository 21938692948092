'use strict';

const util = require('./util');

var wistiaApis = {};

/**
 * @function
 * @description Initializes the events used for the video player
 */
function initEvents() {
	window.wistiaInit = function(W) {
		$('[data-js="wistia-overlay"]').each(function () {
			var videoID = $(this).attr('id');
			if (!wistiaApis[videoID]) {
				wistiaApis[videoID] = W.api.all().filter(obj => { return obj.container.id === videoID})[0];
			}
		});

		// for each PD wistia video on the hero banner, we bind "play" and "pause" events
		$('[data-js="pd-wistia-video"]').each(function () {
			var videoID = $(this).attr('data-video-id');

			window._wq = window._wq || [];
			window._wq.push({
				id: videoID,
				onReady: function(wistiaVideo) {
					var playPauseButton = $(wistiaVideo.container).closest('[data-js="video-wrapper"]').find('[data-js="pd-wistia-video-play-pause"]');
					var muteUnmuteButton = $(wistiaVideo.container).closest('[data-js="video-wrapper"]').find('[data-js="pd-wistia-video-mute-unmute"]');

					if (wistiaVideo.state() === 'playing') {
						playPauseButton.addClass('playing');
						playPauseButton.removeClass('paused');
					}
					else {
						playPauseButton.addClass('paused');
						playPauseButton.removeClass('playing');
					}

					if (wistiaVideo.isMuted()) {
						muteUnmuteButton.addClass('muted');
						muteUnmuteButton.removeClass('unmuted');
					}
					else {
						muteUnmuteButton.addClass('unmuted');
						muteUnmuteButton.removeClass('muted');
					}

					wistiaVideo.bind("play", function () {
						onPlayFunction(wistiaVideo);
					});
					wistiaVideo.bind("pause", function () {
						onPauseFunction(wistiaVideo);
					});
					wistiaVideo.bind("mutechange", function (isMuted) {
						onMuteChangeFunction(wistiaVideo, isMuted);
					});
					wistiaVideo.bind("end", function () {
						onPauseFunction(wistiaVideo);
					});
				}
			});

			var wistiaVideo = wistiaApis[videoID];

			if (!wistiaVideo) {
				wistiaVideo = W.api.all().filter(obj => { return obj.container.id === videoID})[0];

				if (wistiaVideo) {
					wistiaApis[videoID] = wistiaVideo;
				}
			}
		});

		$(document).ready(function () {
			setThumbnailImageUrl();
		});
	};

	var onMuteChangeFunction = function(wistiaVideo, isMuted) {
		var muteUnmuteButton = $(wistiaVideo.container).closest('[data-js="video-wrapper"]').find('[data-js="pd-wistia-video-mute-unmute"]');
		if (isMuted) {
			muteUnmuteButton.addClass('muted');
			muteUnmuteButton.removeClass('unmuted');
		}
		else {
			muteUnmuteButton.addClass('unmuted');
			muteUnmuteButton.removeClass('muted');
		}
	};

	var onPlayFunction = function(wistiaVideo) {
		var playPauseButton = $(wistiaVideo.container).closest('[data-js="video-wrapper"]').find('[data-js="pd-wistia-video-play-pause"]');
		playPauseButton.addClass('playing');
		playPauseButton.removeClass('paused');
	};

	function onPauseFunction(wistiaVideo) {
		var playPauseButton = $(wistiaVideo.container).closest('[data-js="video-wrapper"]').find('[data-js="pd-wistia-video-play-pause"]');
		playPauseButton.removeClass('playing');
		playPauseButton.addClass('paused');
	};

	// Page Designer Inspirational Video compnent
	window._wq = window._wq || [];
	var playButton = $('[data-js="inspirational-video-play"]');
	playButton.on('click', function() {
		var $this = $(this);
		var pageDesignerOverlay = $this.parent().find('[data-js="inspirational-overlay"]');
		$this.parent().addClass('video-played');
		var wistiaVideo = $this.parent().find('[data-js="wistia-video"]');
		_wq.push({
			id: $(wistiaVideo).data('video-id'),
			onReady: function(video) {
				video.play();
			}
		});
		pageDesignerOverlay.slideUp();
	});

	// Page Designer video component custom play/pause button
	var playPauseButton = $('[data-js="pd-wistia-video-play-pause"]');
	playPauseButton.on('click', function() {
		var $this = $(this);
		var wistiaVideo = $this.closest('[data-js="video-wrapper"]').find('[data-js="pd-wistia-video"]');

		_wq.push({
			id: $(wistiaVideo).data('video-id'),
			onReady: function(video) {
				if (video.state() === 'playing') {
					video.pause();
				}
				else {
					video.play();
				}
			}
		});
	});

	// Page Designer video component custom mute/unmute button
	var muteUnmuteButton = $('[data-js="pd-wistia-video-mute-unmute"]');
	muteUnmuteButton.on('click', function() {
		var $this = $(this);
		var wistiaVideo = $this.closest('[data-js="video-wrapper"]').find('[data-js="pd-wistia-video"]');

		_wq.push({
			id: $(wistiaVideo).data('video-id'),
			onReady: function(video) {
				if (video.isMuted()) {
					video.unmute();
				}
				else {
					video.mute();
				}
			}
		});
	});
}

function startVideo(videoId, swiper) {
	var wistiaVideo = wistiaApis[videoId];

	if (!wistiaVideo) {
		wistiaVideo = window.Wistia ? window.Wistia.api.all().filter(obj => { return obj.container.id === videoId })[0] : null;
	}

	if (wistiaVideo) {
		wistiaVideo.unbind("end").bind("end", function (t) {
			onPauseFunction(wistiaVideo);
			if (swiper) {
				swiper.slideNext();
				swiper.autoplay.start();
			}
		});

		wistiaVideo.play();

		return wistiaVideo.duration();
	}

	return 0;
}

/**
 * @function
 * @description sets the video thumbnail image on zoom view
 */
function setThumbnailImageUrl() {
	var zoomViewVideoOverlay = $('[data-js="video-overlay-zoom"]');
	if (!!zoomViewVideoOverlay.length) {
		var wistiaOverlay = zoomViewVideoOverlay.find('[data-js="wistia-overlay"]');
		if (!!wistiaOverlay.length) {
			var videoID = wistiaOverlay.attr('id');
			var hashedVideoId = wistiaOverlay.data('video-id');
			var videoObj = wistiaApis[videoID];
			if (videoObj) {
				var thumbnailImageUrl = null;
				if (videoObj.stillUrl()) {
					thumbnailImageUrl = videoObj.stillUrl();
				}
				if (!thumbnailImageUrl && videoObj.data.media && videoObj.data.media.assets) {
					var thumbnailImageAsset = videoObj.data.media.assets.find(asset => {
						return asset.type === 'still_image';
					});
					if (thumbnailImageAsset) {
						thumbnailImageUrl = thumbnailImageAsset.url;
					}
				}
				if (thumbnailImageUrl) {
					$('[data-video-thumbnail="' + hashedVideoId + '"]').css('background-image', 'url(' + thumbnailImageUrl + ')');
				}
			}
		}
	}
}

/**
 * Check if wistia script need to be loaded
 * @param {html} data
 * @returns {boolean}
 */
function initWistia(data) {
	var isVideoLoaded = false;

	//if we have data (html) as param we will check if video is present otherwise we will check current page
	if (data) {
		//check if product video is set or video content asset
		var isVideoMovieFound = !!util.find(data, '.video-movie').length;
		var isVideoContainerFound = !!util.find(data, '.video-container').length;

		isVideoLoaded = isVideoMovieFound || isVideoContainerFound;
	}
	else {
		//check if product video is set or video content asset
		isVideoLoaded = !!$('body').find('.video-movie').length || !!$('body').find('.video-container').length;
	}

	var isWistiaScriptLoaded = $('script[src="' + wistiaSrc + '"]').length;

	// if there is a video of the product and if the wistia script is not already loaded
	if (isVideoLoaded && !isWistiaScriptLoaded) {
		var wistiaSrc = '//fast.wistia.com/assets/external/E-v1.js';
		var wistiaScript = document.createElement('script');
		wistiaScript.type = 'text/javascript';
		wistiaScript.async = true;
		wistiaScript.src = wistiaSrc;

		var firstScript = $('body').find('script')[0];
		firstScript.parentNode.appendChild(wistiaScript);
	}
}

/**
 * @function
 * @description function to pause the video
 */
exports.pauseVideo = function () {
	var wistiaVideos = window.Wistia ? window.Wistia.api.all() : [];
	for (let index = 0; index < wistiaVideos.length; index++) {
		var video = wistiaVideos[index];
		if (video && video.state() === 'playing') {
			video.pause();
		}
	}
};

/**
 * @function
 * @description initialization of wistia video player component and methods
 */
exports.init = function () {
	initEvents();
};

exports.initWistia = initWistia;
exports.startVideo = startVideo;
