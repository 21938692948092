'use strict';

var util = require('../util');

exports.init = function() {
	FaqAccordion();
	FaqAccordionAriaLabel();
	initServiceDetailsEvents();
	initAccordionEvents();
};

exports.initAccordionEvents = initAccordionEvents;

// FAQ accordeon
function FaqAccordion() {
	$('.folder-content').find('.js-accordion-content').addClass('is-closed'); // Add class as default state, withou js is going to be open

	// When is clicked
	$('.folder-content .js-faq').on('click', function(e) {
		e.preventDefault();
		var $el = $(this).closest('.folder-content');
		$el.find('.js-accordion-content').slideToggle(function() {
			// move focus to the region
			$(this).trigger('focus');
			$(this).attr('tabindex', '0');
		});
		$el.toggleClass('is-open');
		$el.find('.js-accordion-content.is-closed').removeClass('is-closed');
	});
}

//Todo: This type of function for accordion can become a global function in the future
// FAQ accordeon Aria label
function FaqAccordionAriaLabel() {
	// When is clicked
	$('.folder-content .js-faq').on('click', function(e) {
		var $this = $(this);
		var isExpanded = $this.attr('aria-expanded') == 'true' ? false : true;
		$(this).attr('aria-expanded', isExpanded);
	});
}

/**
 * Initializes accordion events
 */
function initAccordionEvents() {
	$(document).off('click.accordionEvents').on('click.accordionEvents', '[data-js="accordion-expander"]', function(e) {
		e.preventDefault();
		var expander = $(this);
		var wrapper = expander.closest('[data-js="accordion-wrapper"]');
		var content = wrapper.find('[data-js="accordion-content"]');

		toggleAccordion(wrapper, content, expander);

		var otherAccordions = wrapper.siblings();
		otherAccordions.each(function() {
			var wrapper = $(this);
			if (wrapper.hasClass('is-open')) {
				var content = wrapper.find('[data-js="accordion-content"]');
				var expander = wrapper.find('[data-js="accordion-expander"]');
				toggleAccordion(wrapper, content, expander);
				return false;
			}
		});
	});

	// click on a faq folder link initializes a scroll-down animation to the correct folder title
	$('[data-js="faq-anchor-item-link"]').off('click').on('click', function(e) {
		var $this = $(this);
		var scrollToElementSelector = '#' + $this.data('href');
		var headerHeight = $('.c-header-nav').height() + 170;
		if (util.isSmallScreenSize()) {
			headerHeight -= 70;
		}


		$('html, body').animate({
			scrollTop: $(scrollToElementSelector).offset().top - headerHeight
		}, 1000);
	});
}

/**
 * Toggles accordion content and sets classes/updates aria expanded
 */
function toggleAccordion(wrapper, content, expander) {
	content.slideToggle(function() {
		$(this).trigger('focus');
		$(this).attr('tabindex', '0');
	});

	wrapper.toggleClass('is-open');
	content.toggleClass('is-closed');

	var isExpanded = !(expander.attr('aria-expanded') === 'true');
	expander.attr('aria-expanded', isExpanded);
}

/**
 * Init Service details page events
 */
function initServiceDetailsEvents() {
	$('[data-js="scroll-to-customer-service"]').on('click', function(e) {
		e.preventDefault();
		var customerServiceBlock = $('[data-js="customer-service"]');
		var headerMenuHeight = $('.c-header-nav').height();
		var offsetHeight = $('[data-js="header"]').height();

		$('html, body').animate({
			scrollTop: customerServiceBlock.offset().top - offsetHeight - headerMenuHeight
		}, 500);
	});
}