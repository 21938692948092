'use strict';

var detailsmodaldialog = require('../../detailsmodaldialog'),
	util = require('../../util'),
	accessibility = (util.isMobile()) ? require('../../accessibilityForMobile') : require('../../accessibility'),
	submitForm = require('./submitForm');

function initializeEvents() {
	$('body').off('click', '[data-js="promotion-code-toggler"]').on('click', '[data-js="promotion-code-toggler"]', function() {
		var $parent = $(this).closest('[data-js="promotion-inline"]');
		var $content = $('[data-js="promotion-content"]');

		if ($parent.hasClass('open')) {
			$content.slideUp();
			$parent.removeClass('open');
			$(this).attr('aria-expanded', false);
			$(this).attr('title', Resources.PROMO_BLOCK_EXPAND);
			document.cookie = 'promoblock-expanded=false; path=/';
		}
		else {
			$content.slideDown();
			$parent.addClass('open');
			$(this).attr('aria-expanded', true);
			$(this).attr('title', Resources.PROMO_BLOCK_COLLAPSE);
			document.cookie = 'promoblock-expanded=true; path=/';
		}

		// aria accessibility attribute
		$(this).attr('aria-expanded', $parent.hasClass('open'));
	});

	$('body').off('click', '.js-add-coupon').on('click', '.js-add-coupon', function(e) {
		e.preventDefault();
		var $this = $(this);
		var $container = $this.closest('.js-promotion-popup');

		if ($container.length === 0) {
			$container = $('.js-promotion-block');
		}

		var couponCode = $('.js-promotion-input').val();
		var attrname = $this.attr('name');

		var options = {
			extraParameters: [
				{ name: attrname, value: attrname },
				{ name: 'couponCode', value: couponCode }
			],
			callback: addCoupon
		};

		var isFocusVisible = $container.find('.focus-visible').length > 0;
		accessibility.setKeyboardNavigation(isFocusVisible);

		submitForm.submitAjaxForm(options);
	});

	$('body').off('click', '.js-remove-coupon').on('click', '.js-remove-coupon', function(e) {
		e.preventDefault();
		var $this = $(this);
		var $container = $this.closest('.js-promotion-popup');

		if ($container.length === 0) {
			$container = $('.js-promotion-block');
		}

		var attrname = $this.attr('name');
		var options = {
			extraParameters: [
				{ name: attrname, value: attrname }
			],
			callback: removeCoupon
		};

		var isFocusVisible = $container.find('.focus-visible').length > 0;
		accessibility.setKeyboardNavigation(isFocusVisible);

		submitForm.submitAjaxForm(options);
	});

	$('body').off('click', '.js-add-manage-promotion-codes').on('click', '.js-add-manage-promotion-codes', function(e) {
		e.preventDefault();

		var options = {
			action: 'openPromotionDialog',
			callback: openPromoPopup
		};

		var $container = $(this).closest('.js-promotion-block');
		var isFocusVisible = $container.find('.focus-visible').length > 0;
		accessibility.setKeyboardNavigation(isFocusVisible);

		submitForm.submitAjaxForm(options);
	});
}

/**
 * Callback for add coupon
 */
var addCoupon = function (data) {
	handleCouponCallback(data, true, accessibility.isKeyBoardNavigationActive());
};

/**
 * Callback for remove coupon
 * @param {Object} e
 */
var removeCoupon = function (data) {
	handleCouponCallback(data, false, accessibility.isKeyBoardNavigationActive());
};

/**
 * Callback for open promo popup
 * @param {String} data
 */
function openPromoPopup(data) {
	if (!data) {
		return;
	}
	var promotionBlock = $(data).filter('.js-promotion-popup');

	if (promotionBlock.length === 0) {
		return;
	}

	detailsmodaldialog.open({
		html: promotionBlock,
		options: {
			closeOnEscape: false,
			dialogClass: 'no-close'
		}
	});

	if (accessibility.isKeyBoardNavigationActive()) {
		accessibility.simulateTabKeyFocus($('.js-promotion-input'));
	}
}

/**
 * Handle the server response after sending ajax call to add/remove the coupon
 */
function handleCouponCallback(response, isAddCoupon, isFocusVisible) {
	var $response = $(response);
	var error = $response.find('[data-js="promotion-error"]');
	var isPromoModal = $('.js-add-manage-promotion-codes');

	//if there are no errors reload the page
	if (!error.length) {
		addNotification(isAddCoupon);
		//if coupon successfully applied we want to clear the input
		if (isAddCoupon) {
			$('[data-js="promotion-input"]').val('');
		}

		//Check if promotion block is displayed in dialog(modal)
		if (isPromoModal.length) {
			detailsmodaldialog.close();
		}

		// accessibility feature - returns focus to add promo codes link after dialog is closed
		if (isFocusVisible) {
			var toggleIcon = $('[data-js="promotion-code-toggler"]');
			if (toggleIcon.length > 0) {
				accessibility.simulateTabKeyFocus($('[data-js="promotion-code-toggler"]'));
			}
			else {
				accessibility.simulateTabKeyFocus($('.js-add-manage-promotion-codes'));
			}
		}
	}
	else {
		$('.js-promotion-popup').html($response.filter('.js-promotion-popup').html());
		$('[data-js="promotion-inline"]').html($response.filter('[data-js="promotion-inline"]').html());

		if (isFocusVisible) {
			accessibility.simulateTabKeyFocus($('.js-promotion-input'));
		}
	}
}

/**
 * Function appends promo code message to order summary. Gets called when promo code is applied/removed
 * @param {boolean} isAddCoupon True if coupon is applied
 */
function addNotification(isAddCoupon) {
	var $notifications = $('#promo-code-notification');
	var text = Resources.PROMO_CODE_REMOVED;

	if (isAddCoupon) {
		text = Resources.PROMO_CODE_APPLIED;
	}

	$notifications.append('<span class="promotion-code-message msg-info">' + text + '</span>');
	$notifications.attr('aria-hidden', 'false');
}

exports.init = function () {
	initializeEvents();
};
exports.addNotification = addNotification;