
'use strict';

var dialog = require('./dialog');

exports.init = function () {
	initEvents();
	displayCardTypeLogo();
};

function initEvents() {
	var $creditcardForm = $('.js-panel-credit_card');
	var $expDateField = $('[data-js="exp-date-field"]');

	$('input[name$="_creditCard_number"], input[name*="_newcreditcard_number"]', $creditcardForm).on('focusin', function() {
		$(this).data('val', $(this).val());
	});


	$('input[name$="_creditCard_number"], input[name*="_newcreditcard_number"]', $creditcardForm).on('change', function() {
		var prev = $(this).data('val');
		var current = $(this).val();
		if (prev !== current) {
			//We want to enable the creditcard validation from jsvalidation
			//when the user modify the value of the field after the page was loaded
			// it's done here and not in the next event handler to NOT manage the tabbing keypress too
			$(this).removeClass('js-noccvalidation');
		}
	});

	$('input[name$="_creditCard_number"], input[name*="_newcreditcard_number"]', $creditcardForm).on('change keyup', function (e) {
		formatCreditCard(e);
		$(this).removeAttr('data-is-masked');

		// updating image for selected credit card
		var cardType = getCardType($(this).val());
		$(this).parent('div').parent('div').removeClass(function (index, css) {
			return css.split(' ').filter(function (style) { return style.indexOf('creditcard-') > -1; }).join(' ');
		});
		if (cardType) {
			var cardTypeStr = cardType.toLowerCase();
			cardTypeStr = cardTypeStr.replace(/\s/g, '');
			$(this).parent('div').parent('div').addClass('creditcard-' + cardTypeStr);

			$('select[name$="_creditCard_type"]').val(cardType);
			$('select[name*="_newcreditcard_type"]').val(cardType);
		}
	});
	var $monthAndYearField = $('input[name$="_creditCard_expiration_monthAndYear"]');
	$monthAndYearField.on('change focusout', function () {
		if (!$(this).val()) {
			$(this).closest('.select-container').addClass('has-error');
		}
		$(this).valid();
	});

	$expDateField.on('change keyup', function(e) {
		formatExpDate(e);
	});

	$expDateField.off('focusin focusout').on('focusin focusout', function() {
		checkAndFormatExpirationDate();
	});

	$('body')
		.off('click', 'button[name="dwfrm_paymentinstruments_creditcards_create"]')
		.on('click', 'button[name="dwfrm_paymentinstruments_creditcards_create"]', submitForm);

	$('[data-js="checkout-amazon"]').off('click').on('click', function(e) {
		e.preventDefault();

		var dialogContainer = $('[data-js="checkout-amazon-alert"]');

		if (dialogContainer) {
			dialog.open({
				html: dialogContainer.html(),
				options: {
					dialogClass: 'amazonpay-dialog'
				},
				callback: function() {
					$('[data-js="continue-with-amazonpay"]').on('click', function(e) {
						e.preventDefault();
						dialog.close();
					});
				}
			});
		}
	});
}

/**
 * Removes spaces before submitting form
 * @param {Object} e
 */
function submitForm(e) {
	e.preventDefault();
	var $form = $(this).closest('form');

	var isFormValid = $form.validate().checkForm();
	var ccNumberField = $('input[name*="dwfrm_paymentinstruments_creditcards_newcreditcard_number"]');

	if (isFormValid) {
		ccNumberField.val(ccNumberField.val());
	}

	$form.submit();
}

//formats the credit card number fields as xxxx xxxx xxxx xxxx
function formatCreditCard (e) {
	//Retrieve the value of the input and remove all non-number characters
	var number = String(e.target.value);
	var cleanNumber = '';
	for (var i = 0; i < number.length; i++) {
		if (/^[0-9]+$/.test(number.charAt(i))) {
			cleanNumber += number.charAt(i);
		}
	}
	//Only parse and correct the input value if the key pressed isn't backspace.
	if (e.key !== 'Backspace') {
		//Format the value to include spaces in the correct locations
		var formatNumber = '';
		for (var j = 0; j < cleanNumber.length; j++) {
			if (j === 3 || j === 7 || j === 11) {
				formatNumber = formatNumber + cleanNumber.charAt(j) + ' ';
			}
			else {
				formatNumber += cleanNumber.charAt(j);
			}
		}
		e.target.value = formatNumber;
	}


	$.valHooks.input = {
		get: function(el) {
			if (el === e.target) {
				return cleanNumber;
			}
		}
	};
}

function formatExpDate(e) {
	var code = e.which;
	var allowedKeys = [8];
	if (allowedKeys.indexOf(code) !== -1) {
		return;
	}
	var expDateField = $(e.target);

	var formattedExpDate = expDateField.val()
		.replace(/^([1-9]\/|[2-9])$/g, '0$1/') // 3 > 03/
		.replace(/^(0[1-9]|1[0-2])$/g, '$1/') // 11 > 11/
		.replace(/^([0-1])([3-9])$/g, '0$1/$2') // 13 > 01/3
		.replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2') // 141 > 01/41
		.replace(/^([0]+)\/|[0]+$/g, '0') // 0/ > 0 and 00 > 0
		.replace(/[^\d/]|^[/]*$/g, '') // To allow only digits and `/`
		.replace(/\/\//g, '/'); // Prevent entering more than 1 `/`

	expDateField.val(formattedExpDate);
}

//Check and format expiration date (example format 05/20)
function checkAndFormatExpirationDate() {
	var element = $('[data-js="exp-date-field"]');
	var value = element.val();

	if (element.valid() && value.length === 4) {
		element.val('0' + value);
	}
}

function getCardType(number) {
	// visa
	var re = new RegExp('^4');
	if (number.match(re) != null) return 'Visa';

	// Mastercard
	// Updated for Mastercard 2017 BINs expansion
	re = new RegExp('^(5[1-5]|2[2-7])');
	if (number.match(re) != null) return 'Mastercard';

	// AMEX
	re = new RegExp('^3[47]');
	if (number.match(re) != null) return 'Amex';

	// Discover
	re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
	if (number.match(re) != null) return 'Discover';

	// Diners
	re = new RegExp('^(38|36|30[0-5])');
	if (number.match(re) != null) return 'Diners Club';

	// JCB
	re = new RegExp('^35(2[89]|[3-8][0-9])');
	if (number.match(re) != null) return 'JCB';

	return Resources.DEFAULT_SELECT_OPTION;
}

// adds credit card type logo class to input container containing card number and type
function displayCardTypeLogo() {
	var $creditCard = $('.js-creditcard-fieldset');
	var cardNumber = $creditCard.find('input[name*="_creditCard_number"]');
	var cardType = cardNumber.attr('cctype');
	if (cardType) {
		//add cc logo class to input container only if there is no cc logo class already
		cardNumber.parents('.input-container').addClass(function(index, css) {
			return css.match(/(^|\s)creditcard-\S+/g) ? '' : ('creditcard-' + cardType.toLowerCase());
		});
	}
}
