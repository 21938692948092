var dialog = require('./dialog');

var openPopup = function(e) {
	e.preventDefault();
	var $link = $(e.target).closest('a[data-js="usp-dialog-modal"]');
	dialog.open({
		url: $link.attr('href'),

		callback: function() {
			//the class of wrapper depends on defined template
			var $contentWrapper = $('#dialog-container').find('.content-body').length ? $('#dialog-container').find('.content-body') : $('#dialog-container').find('.content-asset');
			$contentWrapper.scrollTop(0);
			//add header
			var headerBlock = $link.find('.usp-banner__item').clone();
			$contentWrapper.prepend(headerBlock);
			$contentWrapper.addClass('usp-description-content');
			$contentWrapper.closest('.ui-dialog').removeAttr('aria-labelledby');
			// Keyboard accessibility
			$contentWrapper.find('.ui-dialog-titlebar-close').trigger('focus');
			$contentWrapper.closest('.dialog-content').removeAttr('tabindex');
		},
		options: {
			dialogClass: 'service-dialog'
		}
	});
	return false;
};

exports.init = function () {
	$('[data-js="usp-dialog-modal"]').off('click').on('click', openPopup);
};
