/**
 * Code taken from einsteinCarousel functionality of SFRA, and adjusted to render multiple Page Designer Einstein components on the same page
 * https://documentation.b2c.commercecloud.salesforce.com/DOC2/index.jsp?topic=%2Fcom.demandware.dochelp%2Fsfrajsdoc%2Fjs%2Fclient%2FeinsteinCarousel.js.html
 */

var Promise = require('promise');
var _ = require('lodash');
var ajax = require('./ajax');
var util = require('./util');
var swiper = require('./swiper');

/**
 * Validates and Return the cquotient namespace provided by the commerce cloud platform
 * @returns {Object} - einsteinUtils or null
 */
function getEinsteinUtils() {
	var einsteinUtils = window.CQuotient;
	if (einsteinUtils && (typeof einsteinUtils.getCQUserId === 'function') && (typeof einsteinUtils.getCQCookieId === 'function')) {
		return einsteinUtils;
	}
	return null;
}

/**
 * Sends an AJAX request to get back products to be rendered based on the Einstein recommendations recieved from the engine
 * @param {string} einsteinResponse string html for product tiles
 * @param {jQuery} $parentElement parent element where recommendations will show.
 */
function loadEinsteinProducts(einsteinResponse, $parentElement) {
	var recommendedProducts = einsteinResponse[$parentElement.data('recommender')].recs;
	if (!recommendedProducts || recommendedProducts.length == 0) {
		return;
	}
	var components = [];
	components = recommendedProducts.map(function (recommendedProduct) {
		var tiledefinition = {};
		tiledefinition.model = {
			productid: recommendedProduct.id
		};
		return tiledefinition;
	});

	var url = Urls.einsteinCarouselLoad;
	var data = {
		'components': JSON.stringify(components),
		'limit': $parentElement.data('limit'),
		'componentid': $parentElement.data('component-id'),
		'componenttitle': $parentElement.data('component-title'),
		'linktext': $parentElement.data('component-linktext'),
		'linkurl': $parentElement.data('component-linkurl'),
		'showpricing': $parentElement.data('showpricing'),
		'showswatches': $parentElement.data('showswatches'),
		'showgrid': $parentElement.data('showgrid')
	};

	return new Promise(function (resolve, reject) {
		$.ajax({
			dataType: 'html',
			url: util.appendParamToURL(url, 'format', 'ajax'),
			data: data,
			type: 'GET'
		}).done(function (response) {
			if (response) {
				resolve(response);
			}
			else {
				reject();
			}
		}).fail(function() {
			reject();
		});
	});
}

/**
 * Processes a recommendation tile, with an already initialized category specific anchors array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @param {Object} einsteinUtils cquotient object
 * @param {Array} anchorsArray array of objects representing anchors
 */
function processRecommendationsTile($parentElement, einsteinUtils) {
	var recommender = $parentElement.data('recommender');

	var params = {
		userId: einsteinUtils.getCQUserId(),
		cookieId: einsteinUtils.getCQCookieId(),
		ccver: '1.01'
	};

	// query Einstein engine to get back recommendations
	return new Promise(function (resolve, reject) {
		if (einsteinUtils.getRecs) {
			einsteinUtils.getRecs(einsteinUtils.clientId, recommender, params, function(einsteinResponse) {
				if (einsteinResponse) {
					resolve(einsteinResponse);
				}
				else {
					reject();
				}
			});
		}
		else {
			einsteinUtils.widgets = einsteinUtils.widgets || [];
			einsteinUtils.widgets.push({
				recommenderName: recommender,
				parameters: params,
				callback: function(einsteinResponse) {
					if (einsteinResponse) {
						resolve(einsteinResponse);
					}
					else {
						reject();
					}
				}
			});
		}
	});
}

/**
 * Gets all placeholder elements, which hold einstein recommendations, queries the details from the
 * einstein engine and feeds them back to the dom element by retrieving products via AJAX request
 */
function loadRecommendations() {
	var einsteinUtils = getEinsteinUtils();
	if (einsteinUtils) {
		var $recommendationTiles = $('.einstein-products').toArray();

		// first loop all placeholder elements and get the recommendations from Einstein engine
		// with Promise.all() we wait for all recommendation tiles to be processed
		Promise.all(_.map($recommendationTiles, function(currentElement) {
			var $parentElement = $(currentElement);
			return processRecommendationsTile($parentElement, einsteinUtils);
		})).then(function(einsteinResponseArray) {
			// once they are processed we loop through those results of the Einstein engine queries and make AJAX requests to get actual products and data for rendering
			// again we wait for all AJAX requests to be executed and the results to be added to an array
			Promise.all(_.map(einsteinResponseArray, function(einsteinResponse) {
				var $parentElement = $('[data-recommender="' + Object.keys(einsteinResponse)[0] + '"]');
				return loadEinsteinProducts(einsteinResponse, $parentElement);
			})).then(function(htmlResponseArray) {
				// in the last step we loop through the array of AJAX responses, find the according response for each recommendation placeholder
				// and append the response HTML to the placeholder element
				_.map(htmlResponseArray, function(htmlResponse) {
					var componentId;
					var $responseRecommBlock = $(htmlResponse).filter('[data-js="product-tiles-wrapper"]');

					if ($responseRecommBlock && $responseRecommBlock.length > 0) {
						componentId = $responseRecommBlock.data('promoid').split('|')[1];
					}

					if (componentId) {
						$('[data-js="product-recomm-wrapper-' + componentId + '"]').html(htmlResponse);
					}
				});
				swiper.initializeProductRecommendationSwiper();
			});
		});
	}
}

exports.load = loadRecommendations;