'use strict';

var page = require('../page'),
	tls = require('../tls'),
	quickView = require('./quickView'),
	login = require('../login'),
	events = require('./events');

var pages = {
	account: require('../pages/account'),
	cart: require('../pages/cart'),
	checkout: require('../pages/checkout'),
	compare: require('../pages/compare'),
	product: require('../pages/product'),
	registry: require('../pages/registry'),
	search: require('../pages/search'),
	storefront: require('../pages/storefront'),
	wishlist: require('../pages/wishlist'),
	storelocator: require('../pages/storelocator'),
	orderconfirmation: require('../pages/orderconfirmation'),
	service: require('../pages/service'),
	giftcard: require('../pages/giftcard'),
	store: require('../pages/storedetail'),
	splashpage: require('../pages/splashpage')
};

require('focus-visible'); // Keyboard Navigation NPM Library

events.onLoadInit();

var app = {
	init: function() {
		if (document.cookie.length === 0) {
			$('<div/>').addClass('browser-compatibility-alert')
				.append($('<p/>')
					.addClass('browser-error')
					.html(Resources.COOKIES_DISABLED))
				.appendTo('#browser-check');
		}
		events.init();
		events.globalComponentsInit();

		// execute page specific initializations
		$.extend(page, window.pageContext);
		var ns = page.ns;

		//Check if we have vue enabled on the current page
		var isVueEnabled = ns && SitePreferences.PAGES_USING_VUE.indexOf(ns) >= 0;

		if (!isVueEnabled && ns && pages[ns] && pages[ns].init) {
			pages[ns].init();
			// after the page is initialized we check if there was a quickview previously expanded on it
			quickView.init();
		}

		// Check TLS status if indicated by site preference
		if (SitePreferences.CHECK_TLS === true) {
			tls.getUserAgent();
		}

		var userNavigation = $('.js-user-navigation');
		if (userNavigation && userNavigation.length > 0) {
			var passwordResetUrl = userNavigation.attr('data-passwordreseturl');
			if (passwordResetUrl && passwordResetUrl.length > 0) {
				login.initPasswordRenewDialog(passwordResetUrl);
			}
		}
	}
};

// initialize app
$(document).ready(function() {
	app.init();

	// resize event is used for android devices, orientationchange event is used for iOS
	var previousOrientation = window.orientation;
	var checkOrientation = function() {
		if (window.orientation !== previousOrientation) {
			previousOrientation = window.orientation;
			app.init();
		}
	};

	window.addEventListener('resize', checkOrientation, false);
	window.addEventListener('orientationchange', checkOrientation, false);
});
