'use strict';

/**
 * @private
 * @function
 * @description Binds event to the place holder (.blur)
 */
function initializeEvents() {
	$('[name="q"]').on('focus', function () {
		var input = $(this);
		if (input.val() === input.attr('placeholder')) {
			input.val('');
		}
	})
		.on('blur', function () {
			var input = $(this);
			if (input.val() === '' || input.val() === input.attr('placeholder')) {
				input.val(input.attr('placeholder'));
			}
		})
		.trigger('blur');
}

exports.init = initializeEvents;
