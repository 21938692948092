'use strict';

var inputvalidation = require('../pages/checkout/inputvalidation');
var forms = require('../forms');
var util = require('../util');


exports.init = function () {
	$('body').off('click', '.js-search-postal-code').on('click', '.js-search-postal-code', function(e) {
		var zipcodeLayer = document.getElementById('zipcode-layer');
		var button = this;

		e.preventDefault();

		bindClickEvent();

		if (typeof daum != 'undefined' && typeof daum == 'object') {
			//remove the error in case user retry the
			$(button).closest('fieldset').find('.error js-address-postal-code-error').remove();

			daum.postcode.load(function() {
				new daum.Postcode({
					oncomplete: function(data) {
						var parentWrapper = $(button).closest('fieldset');

						var postalcodeField = parentWrapper.find('.js-address-postal-code');

						postalcodeField.val(data.zonecode);
						postalcodeField.closest('.input-container ').addClass('is-filled is-valid required');
						postalcodeField.removeClass('error').next('.error').empty();//remove the error message

						var addressField = parentWrapper.find('.js-address-address1');
						addressField.val(data.roadAddress);
						addressField.valid();
						addressField.closest('.input-container ').addClass('is-filled is-valid required');

						zipcodeLayer.style.display = 'none';
						removeOverlay();
						//Added new address. Show add-to-address-book checkbox for user
						if ($('select[name$="_addressList"]').length > 0) {
							$('select[name$="_addressList"]')[0].selectedIndex = 0;
							$('[data-js="shipping-add-to-address-book"]').removeClass('u-hidden');
							util.eraseCookie('_preferred_shipping_address_id');
						}
					},
					width: '100%',
					height: '100%',
					animation: true
				}).embed(zipcodeLayer);
			});
			displayOverlay();
			zipcodeLayer.style.display = 'block';
		}
		else {
			//add an error when the service is down for the visitor
			var parentWrapper = $(button).closest('fieldset');
			parentWrapper.find('.js-address-postal-code').after("<span class='error js-address-postal-code-error'>" + Resources.ZIPCODE_LOOKUP_DOWN + '</span>');
		}
	});
};

function bindClickEvent() {
	$('#zipcode-layer .js-zipcode-layer-close').on('click', function(e) {
		e.preventDefault();
		var zipcodeLayer = document.getElementById('zipcode-layer');
		zipcodeLayer.style.display = 'none';
		removeOverlay();
	});
}

function displayOverlay() {
	var overlay = $('<div class="ui-overlay"  style="position: absolute; top: 0pt; left: 0pt; display: inline-block; overflow: hidden; z-index: 109;"><div class="ui-widget-overlay" style="top: 0pt; left: 0pt;"></div></div>').hide().appendTo($('body'));
	$(overlay).width('100%');
	$(overlay).height('100%');
	$(overlay).fadeIn();
	$('html').css('overflow-y', 'hidden');
	return false;
}

function removeOverlay() {
	$('.ui-overlay').remove();
	$('html').css('overflow-y', 'auto');
	forms.checkInputFields();
	return false;
}
