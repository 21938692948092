'use strict';

/**
 * Extends JQuery with custom functons
 */
function applyExtensions() {
	// params
	// toggleClass - required
	// triggerSelector - optional. the selector for the element that triggers the event handler. defaults to the child elements of the list.
	// eventName - optional. defaults to 'click'
	$.fn.toggledList = function (options) {
		if (!options.toggleClass) { return this; }
		var list = this;
		return list.on(options.eventName || 'click', options.triggerSelector || list.children(), function (e) {
			e.preventDefault();
			var classTarget = options.triggerSelector ? $(this).parent() : $(this);
			classTarget.toggleClass(options.toggleClass);
			// execute callback if exists
			if (options.callback) {options.callback();}
		});
	};

	$.fn.syncHeight = function () {
		var arr = $.makeArray(this);
		arr.sort(function (a, b) {
			return $(a).height() - $(b).height();
		});
		return this.height($(arr[arr.length - 1]).height());
	};
}

exports.init = function () {
	// Load JQuery if it isn't loaded
	if (!window.jQuery) {
		var s = document.createElement('script');
		s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
		s.setAttribute('type', 'text/javascript');
		document.getElementsByTagName('head')[0].appendChild(s);
	}

	// Apply JQuery custom logic
	applyExtensions();
};
