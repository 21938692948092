'use strict';

var googleMap = require('./googlemap');
var util = require('../util');
var isMobile = !util.isTabletAndAbove();
const constants = require('../constants').storeAvailabilityCheckType;
var previouslySelectedStoreId;
var availabilityCheckType = constants.STOCK; //checking if product is in stock is default check type

function initializeEvents() {
	$('body').off('click', '[data-js="store-more-information"]').on('click', '[data-js="store-more-information"]', function() {
		selectStore($(this).data('storeid'));
	});

	$('body').off('click', '[data-js="js-map-view-button"]').on('click', '[data-js="js-map-view-button"]', function() {
		$(this).addClass('active').siblings().removeClass('active');
		$('[data-js="store-results-list"]').addClass('u-hidden');
		$('.store-map-block').removeClass('u-hidden');
		resetStoreInfo();
	});

	$('body').off('click', '[data-js="js-list-view-button"]').on('click', '[data-js="js-list-view-button"]', function() {
		$(this).addClass('active').siblings().removeClass('active');
		$('.store-map-block').addClass('u-hidden');
		$('[data-js="store-results-list"]').removeClass('u-hidden');
		resetStoreInfo();
	});

	$('body').off('click', '[data-js="close-store-info-map"]').on('click', '[data-js="close-store-info-map"]', function(e) {
		e.preventDefault();
		$(this).parent().remove();
		googleMap.updateStoreIcon(previouslySelectedStoreId, false);
	});
}

/**
 * Method that prepares data for markers
 */
function prepareStoresData() {
	var storeElements = $('[data-js="js-mapstore"]');
	var storeDataArray = [];

	for (var i = 0; i < storeElements.length; i++) {
		var storeDataObj = {};
		var storeElement = $(storeElements[i]);

		storeDataObj.name = storeElement.data('name');
		storeDataObj.storeId = storeElement.data('storeid');
		storeDataObj.url = storeElement.data('url');
		storeDataObj.coordinates = {
			lat: storeElement.data('latitude'),
			lng: storeElement.data('longitude')
		};
		storeDataObj.inStockMsg = storeElement.attr('data-stockmsg');
		storeDataObj.inStockFlag = storeElement.data('stockflag');
		storeDataObj.reservationFlag = storeElement.data('reservationflag');
		storeDataObj.clickAndCollectFlag = storeElement.data('clickandcollectflag');
		storeDataObj.reserveurl = storeElement.data('reserveurl');
		storeDataObj.reservebuttontext = storeElement.data('reservebuttontext');
		storeDataObj.selectstoretext = storeElement.data('selectstoretext');
		storeDataObj.isselected = storeElement.data('isselected');

		storeDataArray.push(storeDataObj);
	}

	return storeDataArray;
}

/**
 * Handles click on store event (update map, markers, store info)
 * @param {String} storeId
 */
function selectStore(storeId) {
	var storeObject = googleMap.getStoreObject(storeId);
	var coordinates = storeObject.coordinates;

	if (isMobile) {
		var isMapView = $('[data-js="js-map-view-button"]').hasClass('active');

		if (isMapView) {
			updateStoreInfoMobileMap(storeObject);
			googleMap.updateMap(coordinates, storeId, previouslySelectedStoreId);
			previouslySelectedStoreId = storeId;
			return;
		}

		updateStoreInfoMobileResults(storeId);
		previouslySelectedStoreId = storeId;
		return;
	}

	updateStoreInfoDesktop(storeId);
	googleMap.updateMap(coordinates, storeId, previouslySelectedStoreId);
	previouslySelectedStoreId = storeId;
}


/**
 * Opens store info on mobile when map is active
 * @param {Object} storeObject
 */
function updateStoreInfoMobileMap(storeObject) {
	var mapActiveInfoContainer = $('#js-googlemapstore').find('[data-js="store-info-container-map"]');
	if (mapActiveInfoContainer != -1) {
		mapActiveInfoContainer.remove();
	}

	if (!storeObject) {
		return;
	}

	var mapInfoContainer = $('[data-js="store-info-container-map"]').clone(true);
	mapInfoContainer.removeClass('u-hidden');
	mapInfoContainer.find('[data-js="map-store-info-name"]').html(storeObject.name);
	if (availabilityCheckType == constants.STOCK || availabilityCheckType == constants.PDP_RESERVATION) {
		mapInfoContainer.find('[data-js="map-store-info-stock"]').html(storeObject.inStockMsg);
	}
	mapInfoContainer.find('[data-js="map-store-info-btn"]').attr('data-storeid', storeObject.storeId);
	mapInfoContainer.find('[data-js="map-store-info-btn"]').attr('data-url', storeObject.url);

	if (storeObject.inStockFlag) {
		mapInfoContainer.find('[data-js="map-store-info-stock"]').addClass('is-available');
	}

	var selectedStore = $('.is-selected');
	if (selectedStore.length) {
		var selectedStoreId = selectedStore.data('storeid');
		if (selectedStoreId == storeObject.storeId) {
			mapInfoContainer.find('.select-store').addClass('is-selected');
			mapInfoContainer.find('.select-store span').text(Resources.STORE_SELECTED);
		}
	}
	if (availabilityCheckType == constants.CLICK_AND_COLLECT) {
		if (storeObject.clickAndCollectFlag) {
			mapInfoContainer.find('[data-js="map-store-not-available"]').remove();
		}
		else {
			mapInfoContainer.find('[data-js="map-store-available"]').remove();
			mapInfoContainer.find('[data-js="reservation-not-available"]').remove();
		}
	}
	else if (availabilityCheckType == constants.CLICK_AND_RESERVE) {
		if (storeObject.reservationFlag) {
			mapInfoContainer.find('[data-js="map-store-not-available"]').remove();
		}
		else {
			mapInfoContainer.find('[data-js="map-store-available"]').remove();
			mapInfoContainer.find('[data-js="pickup-not-available"]').remove();
		}
	}
	else if (availabilityCheckType == constants.PDP_RESERVATION) {
		var notAvailableBlock = mapInfoContainer.find('[data-js="map-reservation-notavailable"]');
		var availableBlock = mapInfoContainer.find('[data-js="map-reservation-available"]');
		var selectStoreButton = mapInfoContainer.find('a.js-select-store-button');
		selectStoreButton.text(storeObject.selectstoretext);
		selectStoreButton.data('storeId', storeObject.storeId);
		selectStoreButton.data('url', storeObject.url);
		if (storeObject.isselected) {
			selectStoreButton.addClass('is-selected');
		} else {
			selectStoreButton.removeClass('is-selected');
		}
		if (storeObject.reservationFlag && storeObject.inStockFlag) {
			notAvailableBlock.remove();
			availableBlock.find('button').data('storeId', storeObject.storeId);
			availableBlock.find('button').data('url', storeObject.reserveurl);
			availableBlock.find('span').text(storeObject.reservebuttontext);
		} else {
			availableBlock.remove();
		}
	}
	else {
		mapInfoContainer.find('[data-js="map-store-not-available"]').remove();
	}

	$('#js-googlemapstore').append(mapInfoContainer);
}

/**
 * Opens store info on mobile when we have just results
 * @param {String} storeId
 */
function updateStoreInfoMobileResults(storeId) {
	var storeInfoSelector = '[data-js="more-store-information-' + storeId + '"]';

	//update store info block
	if (previouslySelectedStoreId) {
		if (previouslySelectedStoreId == storeId) {
			$('[data-js="more-store-information-' + previouslySelectedStoreId + '"]').toggleClass('u-hidden');
			return;
		}

		$('[data-js="more-store-information-' + previouslySelectedStoreId + '"]').addClass('u-hidden');
	}

	if (storeId) {
		$(storeInfoSelector).removeClass('u-hidden');
	}
}

/**
 * Opens store info on desktop
 * @param {String} storeId
 */
function updateStoreInfoDesktop(storeId) {
	var storeInfoContainerSelector = '[data-js="store-info-container"]';
	var mapBlock = '[data-js="map-block"]';

	if (!storeId) {
		$(storeInfoContainerSelector).html('');
		$(storeInfoContainerSelector).removeClass('is-visible');
		$('[data-js="store-availability-item"]').removeClass('active');
		return;
	}

	//update store info block
	var storeInfoSelector = '[data-js="more-store-information-' + storeId + '"]';
	$(storeInfoContainerSelector).html($(storeInfoSelector).html());
	$(storeInfoContainerSelector).addClass('is-visible');
	$(mapBlock).addClass('is-visible');

	//update styling
	var selectedStoreBlock = $('[data-js="store-availability-item"][data-storeid="' + storeId + '"]');
	selectedStoreBlock.addClass('active');
	if (previouslySelectedStoreId && previouslySelectedStoreId != storeId) {
		$('[data-js="store-availability-item"][data-storeid="' + previouslySelectedStoreId + '"]').removeClass('active');
	}

	//scroll to selected result
	var storeList = $('[data-js="store-results-list"]');
	storeList.scrollTop(storeList.scrollTop() - storeList.offset().top + selectedStoreBlock.offset().top - 20);
}

/**
 * Resets all more store details blocks
 */
function resetStoreInfo() {
	if (isMobile) {
		updateStoreInfoMobileResults();
		updateStoreInfoMobileMap();
		return;
	}
	updateStoreInfoDesktop();
}

module.exports = {
	init: function(checkType) {
		previouslySelectedStoreId = null;
		availabilityCheckType = checkType;
		var storesData = prepareStoresData();
		googleMap.init(checkType, storesData, selectStore);
		initializeEvents();
	},
	updateMarkers: function(isFilterOn) {
		previouslySelectedStoreId = null;
		googleMap.updateStoreMarkers(isFilterOn);
		resetStoreInfo();
	}
};