//This file contains polyfills for methods that aren't supported by any browser (mainly IE)

/**
 * Returns Element with closest method (needed for IE)
 */
function closestPolyfill() {
	if (!Element.prototype.matches) {
		Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
	}

	if (!Element.prototype.closest) {
		Element.prototype.closest = function(s) {
			var el = this;

			do {
				if (Element.prototype.matches.call(el, s)) return el;
				el = el.parentElement || el.parentNode;
			} while (el !== null && el.nodeType === 1);
			return null;
		};
	}
}

exports.init = function() {
	closestPolyfill();
};