'use strict';

var progress = require('../progress'),
	quickview = require('../quickview'),
	qtySelector = require('../quantitySelector'),
	yotpoReviews = require('../yotpoReviews'),
	video = require('../video');

/**
 * Checks if there was a quickview/edit product dialog previously expanded on the page and opens it again
 */
function initializeQuickview() {
	var currentUrl = window.location.href;
	if (currentUrl.indexOf('quickviewExpanded=') !== -1) {
		progress.show();
		var quickviewUrl = currentUrl.split('quickviewExpanded=')[1];
		quickview.show({
			url: decodeURIComponent(quickviewUrl),
			source: 'quickview',
			loader: true
		});
	}
	if (currentUrl.indexOf('editProduct=') !== -1) {
		progress.show();
		var editProductUrl = currentUrl.split('editProduct=')[1];
		quickview.show({
			url: decodeURIComponent(editProductUrl),
			source: 'editproduct',
			loader: true,
			callback: function() {
				yotpoReviews.refreshWidgets();
				video.initWistia();
				qtySelector.updateQty();
			}
		});
	}
}

exports.init = initializeQuickview;