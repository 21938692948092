'use strict';

var fbconversion = require('../fbconversion'),
	trackingHelper = require('./trackingHelper'),
	ometriaTracker = require('../tracking/ometriaTracking'),
	util = require('../util');

/**
 * Push data to datalayer when we add product to wishlist
 * @param {*} event
 * @param {String} dataSelector
 */
function addToWishlist(event, dataSelector) {
	var $dataSelector = $(dataSelector);
	var label = $dataSelector.data('variationgroupid').toString();
	var value = parseFloat($dataSelector.data('price'));

	trackingHelper.pushBasicTrackingEvent('Wishlist', 'add-to-wishlist', label, value);
}

/**
 * Push data to datalayer when we remove product to wishlist
 * @param {*} event
 * @param {String} dataSelector
 */
function removeFromWishlist(event, dataSelector) {
	var $dataSelector = $(dataSelector);
	var label = $dataSelector.data('variationgroupid').toString();
	var value = parseFloat($dataSelector.data('price'));

	trackingHelper.pushBasicTrackingEvent('Wishlist', 'remove-from-wishlist', label, value);
}

/**
 * Push data to datalayer when we add product to cart from wishlist page
 * @param {*} event
 * @param {String} dataSelector
 */
function addToCart(event, dataSelector) {
	var $dataSelector = $(dataSelector);
	var label = $dataSelector.data('variantgroup-id').toString();
	var value = parseFloat($dataSelector.data('min-price'));
	var data = {
		eventId: util.generateUUID(),
		event: 'AddToCart',
		productId: $dataSelector.data('variant-id'),
		quantity: 1
	};
	fbconversion.triggerEvent(data);
	$(document).trigger('addtocart', [data.eventId, dataSelector]);

	trackingHelper.pushBasicTrackingEvent('Wishlist', 'add-to-cart', label, value);
	if (typeof ometria !== 'undefined') {
		ometriaTracker.ometriaTrackBasket();
	}
}

/**
 * Push data to datalayer when we add all products to cart from wishlist page
 * @param {*} event
 * @param {String} productIds
 */
function addAllToCart(event, productIds) {
	if (!productIds) {
		return;
	}

	var productIdsArray = JSON.parse(productIds);
	var value = 0;
	var labelArray = [];

	for (var i = 0; i < productIdsArray.length; i++) {
		var productId = productIdsArray[i];
		var productSelector = $('[data-js="wishlist-product"][data-variant-id="' + productId + '"]');
		$(document).trigger('wishlistaddtocart', productSelector);
		value += parseFloat(productSelector.data('min-price'));
		labelArray.push(productSelector.data('variantgroup-id').toString());
	}

	var label = labelArray.join('|');

	trackingHelper.pushBasicTrackingEvent('Wishlist', 'add-all-to-cart', label, value);
}

/**
 * Push data to datalayer when we change color of product on wishlist page
 * @param {*} event
 * @param {String} dataSelector
 */
function selectColor(event, dataSelector) {
	var $dataSelector = $(dataSelector);
	var label = $dataSelector.data('variantgroup-id').toString();
	var value = parseFloat($dataSelector.data('min-price'));

	trackingHelper.pushBasicTrackingEvent('Wishlist', 'select-color', label, value);
}

/**
 * Push data to datalayer when we change color of product on wishlist page
 * @param {*} event
 * @param {String} dataSelector
 */
function selectSize(event, dataSelector) {
	var $dataSelector = $(dataSelector);
	var label = $dataSelector.data('variantgroup-id').toString();
	var value = parseFloat($dataSelector.data('min-price'));

	trackingHelper.pushBasicTrackingEvent('Wishlist', 'select-size', label, value);
}

/**
 * Push wish list contents to datalayer when user interacts with wish list
 */
function trackUpdatedWishList() {
	var wishlistDataElement = $('[data-js="wishlist-summary"]');
	var wishlistValue = 0;
	var wishlistQuantity = 0;
	var wishlistIds = [];

	if (wishlistDataElement.length > 0) {
		wishlistValue = wishlistDataElement.attr('data-value');
		wishlistQuantity = wishlistDataElement.attr('data-quantity');
		wishlistIds = JSON.parse(wishlistDataElement.attr('data-ids'));
	}

	var trackingObj = {
		'event': 'updatedWishlist',
		'wishlistValue': parseFloat(wishlistValue),
		'wishlistQuantity': parseInt(wishlistQuantity, 10),
		'wishlistIds': wishlistIds
	};
	trackingHelper.pushToDataLayer(trackingObj);
}

/**
 * Initialize wishlist page events for GTM tracking
 */
function initalizeWishlistPageEvents() {
	if (pageContext.gtmPageType !== 'MyWishlist') {
		return;
	}

	$(document).on('wishlistaddtocart', addToCart);
	$(document).on('wishlistaddalltocart', addAllToCart);
	$(document).on('wishlistselectcolor', selectColor);
	$(document).on('wishlistselectsize', selectSize);
}

/**
 * Initialize all events for wishlist GTM tracking
 */
module.exports.init = function () {
	initalizeWishlistPageEvents();
	$(document).on('addtowishlist', addToWishlist);
	$(document).on('removefromwishlist', removeFromWishlist);
	$(document).on('trackUpdatedWishList', trackUpdatedWishList);
};
