'use strict';

var util = require('./util');
var keyboard = require('./constants').keyboard;
var accessibility = (util.isMobile()) ? require('./accessibilityForMobile') : require('./accessibility');

var overlay = $('.js-overlay');
var productContent = $('[data-js="product-content"]');
var flyoutType;

/**
 * @function
 * @description Initializes size guide events
 */
function initPdpSizeGuide() {
	const $sizeGuideFlyout = $('.c-product-info .side-flyout-wrapper');
	// we need to move Size guide flyout outside sticky '.c-product-info' block
	// otherwise it will be broken
	if ($sizeGuideFlyout.length) {
		$('.c-product-info').parent().append($sizeGuideFlyout.get(0));
	}

	$('body').off('click', '.js-size-chart-link').on('click', '.js-size-chart-link', function (e) {
		e.preventDefault();
		flyoutType = 'size-guide';
		openFlyout();

		var isKeyboardNav = $(this).hasClass('focus-visible');
		var flyout = util.isSmallScreenSize()
			? $('.js-product-info > #product-top-info .size-guide-flyout-wrapper')
			: $('.js-pdp-product-info .size-guide-flyout-wrapper');

		accessibility.setKeyboardNavigation(isKeyboardNav);
		accessibility.focusTrap(flyout);
	});

	$('body').off('click', '[data-js="close-size-guide"]').on('click', '[data-js="close-size-guide"]', function (e) {
		closeFlyout();
		accessibility.focusToFirstSize();
	});
}

/**
 * @function
 * @description Initializes PDP Pricing Policy
 */
function initPdpPricingPolicy() {
	$('body').off('click', '[data-js="pricing-policy-tooltip"]').on('click', '[data-js="pricing-policy-tooltip"]', function (e) {
		e.preventDefault();
		flyoutType = 'pricing-policy';
		openFlyout();

		var isKeyboardNav = $(this).hasClass('focus-visible');
		var flyout = util.isSmallScreenSize()
			? $('.js-product-info > #product-top-info .only-mobile [data-js="pricing-policy-flyout"]')
			: $('.only-desktop [data-js="pricing-policy-flyout"]');

		accessibility.setKeyboardNavigation(isKeyboardNav);
		accessibility.focusTrap(flyout);
	});

	$('body').off('click', '[data-js="pricing-policy-guide"]').on('click', '[data-js="pricing-policy-guide"]', function (e) {
		closeFlyout();

		if (accessibility.isKeyBoardNavigationActive()) {
			accessibility.simulateTabKeyFocus($('[data-js="pricing-policy-tooltip"]'));
		}
	});
}

function initReturnPolicy() {
	$('body').off('click', '[data-js="return-policy-flyout"]').on('click', '[data-js="return-policy-flyout"]', function (e) {
		e.preventDefault();
		flyoutType = 'return-policy';
		openFlyout();
	});
	$('body').off('click', '[data-js="close-return-policy"]').on('click', '[data-js="close-return-policy"]', function (e) {
		closeFlyout();
	});
}

function initFiltersFlyout() {
	var plpRefineBar = $('[data-js="plp-refine-bar"]');
	var plpRefineFlyout = $('[data-js="plp-refine-flyout"]');
	$('body').off('click', '[data-js="more-filters-flyout"]').on('click', '[data-js="more-filters-flyout"]', function (e) {
		e.preventDefault();
		flyoutType = 'flyout-filters';

		plpRefineBar.find('[data-js="filter-toggler"]');
		plpRefineBar.find('[data-js="filter-block"]')
			.children('[data-js="filter-content"].is-open').stop()
			.slideToggle()
			.removeClass('is-open');

		plpRefineFlyout.find('[data-js="filter-toggler"]')
			.addClass('is-open')
			.attr('aria-expanded', true);

		openFlyout();
		var isKeyboardNav = $(this).hasClass('focus-visible');
		accessibility.setKeyboardNavigation(isKeyboardNav);

		if (accessibility.isKeyBoardNavigationActive()) {
			accessibility.updateFilterFlyoutTabindexes('1', true);
			accessibility.updateDesktopFlyoutAccessibility();
			var closeButton = plpRefineFlyout.find('[data-js="close-flyout-filters"]');
			accessibility.simulateTabKeyFocus(closeButton);
		}
	});

	$('body').off('click.flyoutfilteraction').on('click.flyoutfilteraction', '[data-js="plp-refine-flyout"].desktop-view [data-js="clear-filters"], [data-js="plp-refine-flyout"].desktop-view [data-js="filter-item"]', function () {
		closeFlyout();
		closeFilterFlyout();
	});


	$('body').off('click', '[data-js="close-flyout-filters"]').on('click', '[data-js="close-flyout-filters"]', function () {
		closeFlyout();
		closeFilterFlyout();
	});

	function closeFilterFlyout() {
		plpRefineFlyout.find('[data-js="filter-content"]').removeAttr('style');
		plpRefineFlyout.find('[data-js="filter-block"]').addClass('js-expanded');
		plpRefineFlyout.find('[data-js="filter-toggler"]').addClass('is-open');
		accessibility.updateFilterFlyoutTabindexes('-1', true);

		if (accessibility.isKeyBoardNavigationActive()) {
			var openFlyoutBtn = plpRefineBar.find('[data-js="more-filters-flyout"]');
			accessibility.simulateTabKeyFocus(openFlyoutBtn);
			accessibility.updateDesktopFlyoutAccessibility(true);
		}
	}
}

/**
 * Opens flyout based on current flyout type
 */
function openFlyout() {
	if (!flyoutType) {
		return;
	}

	const scrollYNumber = window.pageYOffset;
	util.rememberScrollPosition();

	$('body').addClass(flyoutType + '-open').addClass('js-flyout-dialog');
	$('.' + flyoutType + '-open').on('webkitTransitionEnd.flyout', function () {
		overlay.fadeIn();
		$('body').off('click.closeflyout').on('click.closeflyout', '.js-overlay', closeFlyout);
		$('body').off('keydown.closeflyout').on('keydown.closeflyout', function (e) {
			if (e.which === keyboard.ESC_KEY && $('body').hasClass('js-flyout-dialog')) {
				closeFlyout();
			}
		});

		// When Quick View is open on PLP user can open additional modal window: pricing-policy or size-guide
		// In that case we need additional overlay which is plp-overlay in this case
		if ($('.pt_product-search-result').length && $('.quickview-is-open').length) {
			var plpOverlay = $('.js-plp-overlay');
			plpOverlay.fadeIn();
			$('body').off('click.closeflyout').on('click.closeflyout', '.js-plp-overlay', closeFlyout);
		}
	});

	// PDP sticky slider
	var sliderContainer = $('[data-js="product-slider"]');
	var scrollTop = 80 + scrollYNumber;
	sliderContainer.css('top', scrollTop);
}

/**
 * Closes flyout based on current flyout type
 */
function closeFlyout() {
	if (!flyoutType) {
		return;
	}

	$('.' + flyoutType + '-open').off('webkitTransitionEnd.flyout');
	$('body').removeClass(flyoutType + '-open');
	if ($('.pt_product-search-result').length && $('.quickview-is-open').length) {
		var plpOverlay = $('.js-plp-overlay');
		plpOverlay.fadeOut();
	}

	overlay.fadeOut();
	util.restoreScrollPosition();

	// PDP sticky slider
	var sliderContainer = $('[data-js="product-slider"]');
	sliderContainer.css('top', 80);

	$('body').removeClass('js-flyout-dialog');
}

/**
 * Closes edit product popup on cart page (redesign)
 */

function initCloseEditProductPopup() {
	var closeBtn = $('[data-js="edit-product"]').find('.ui-dialog-titlebar-close');

	closeBtn.on('click', function() {
		util.restoreScrollPosition('cart-edit-product-popup');
	});
}

exports.init = function () {
	initPdpSizeGuide();
	initPdpPricingPolicy();
	initReturnPolicy();
	initFiltersFlyout();
	initCloseEditProductPopup();
};
